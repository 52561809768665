import { FC, ReactElement, useState } from 'react';

import { Icon, Price } from '../../components';
import { Button, CouponInput } from '../../compositions';
import { CartInterface } from '../../entities/Cart/Cart';
import { useTrans } from '../../hooks';
import { CartProduct } from '../CartSidebar/subcomponents';

import './Cart.scss';

interface CartProps extends CartInterface {
    isLoading: boolean;
    onUpdateCartItem: (cartItemId: string, quantity: number) => void;
    onRemoveItemFromCart: (cartItemId: string) => void;
    onUpdateCartItemIsInWishlist: (productId: string, isInWishlist: boolean) => void;
    onClearCart: () => void;
    className?: string;
}

export const Cart: FC<CartProps> = ({
    items,
    subTotalPrice,
    shippingCosts,
    totalPrice,
    isLoading,
    onUpdateCartItem,
    onRemoveItemFromCart,
    onUpdateCartItemIsInWishlist,
    onClearCart,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    const [couponCode, setCouponCode] = useState<string>('');

    return (
        <div className={`cart ${className}`}>
            <div className="cart__products">
                <div className="cart__top">
                    <h2 className="cart__section-heading">
                        {trans('common.cart')}
                    </h2>

                    {items.length !== 0 && (
                        <Button
                            text={trans('containers.cart.clear')}
                            onClick={onClearCart}
                            className="cart__clear-link"
                        />
                    )}
                </div>

                <div className="cart__product-column-definitions">
                    <div className="cart__product-column-definition">
                        {trans('containers.cart.columnName')}
                    </div>

                    <div className="cart__product-column-definition">
                        {trans('containers.cart.columnCount')}
                    </div>

                    <div className="cart__product-column-definition">
                        {trans('containers.cart.columnTotal')}
                    </div>
                </div>

                <div className="cart__products-wrapper">
                    {items.map(item => (
                        <CartProduct
                            {...item}
                            key={item.id}
                            isLoading={isLoading}
                            onUpdateCartItem={onUpdateCartItem}
                            onRemoveItemFromCart={onRemoveItemFromCart}
                            onUpdateCartItemIsInWishlist={onUpdateCartItemIsInWishlist}
                            className="cart__product"
                        />
                    ))}

                    {!isLoading && items.length === 0 && (
                        <div className="cart__no-products-wrapper">
                            <Icon name="cart" className="cart__no-products-icon" />

                            <p className="cart__no-products-text">
                                {trans('containers.cart.noProductsInCart')}
                            </p>
                        </div>
                    )}
                </div>
            </div>

            <div className="cart__checkout">
                <h2 className="cart__section-heading">
                    {trans('common.checkout')}
                </h2>

                <CouponInput
                    label={trans('containers.cart.couponInputLabel')}
                    tooltip={trans('containers.cart.couponInputTooltip')}
                    placeholder={trans('containers.cart.couponInputPlaceholder')}
                    buttonLabel={trans('common.apply')}
                    value={couponCode}
                    onChange={setCouponCode}
                    onSubmit={console.log}
                    className="cart__coupon-input"
                />

                <div className="cart__costs-wrapper">
                    <div className="cart__costs-item cart__costs-item--subtotal">
                        <p>
                            {trans('containers.cart.subtotal')}
                        </p>

                        <Price
                            amount={subTotalPrice}
                            includeCurrencySymbol
                            className="cart__price"
                        />
                    </div>

                    <div className="cart__costs-item cart__costs-item--shipping">
                        <p>
                            {trans('containers.cart.shippingCosts')}
                        </p>

                        <Price
                            amount={shippingCosts}
                            includeCurrencySymbol
                            className="cart__price"
                        />
                    </div>

                    <div className="cart__costs-item cart__costs-item--total">
                        <p>
                            {trans('containers.cart.total')}
                        </p>

                        <Price
                            amount={totalPrice}
                            includeCurrencySymbol
                            className="cart__price cart__price--total"
                        />
                    </div>
                </div>

                <Button
                    text={trans('common.checkout')}
                    className="cart__checkout-button"
                />
            </div>
        </div>
    );
};
