import { BlockType } from '../../Block/Block';
import { transformToDefaultBlock } from '../../Block/BlockTransformers';
import { transformToImage, transformToVideo } from '../../Media/MediaTransformers';
import {
    ContentColumnInterface,
    ContentColumnResource,
    ContentColumnsInterface,
    ContentColumnsResource,
} from './ContentColumns';

const transformToContentColumn = (resource: ContentColumnResource): ContentColumnInterface => {
    const image = resource.media && resource.media?.mime.startsWith('image')
        ? transformToImage(resource.media)
        : undefined;

    const video = resource.media && resource.media?.mime.startsWith('video')
        ? transformToVideo(resource.media)
        : undefined;

    return {
        id: resource.id,
        content: {
            titleHtml: resource.title || '',
            paragraphHtml: resource.paragraph || '',
            image,
            video,
        },
    };
};

export const transformToContentColumns = (resource: ContentColumnsResource): ContentColumnsInterface => {
    const defaultBlock = transformToDefaultBlock(resource);

    const columns = resource.columns.map(transformToContentColumn);

    return {
        ...defaultBlock,
        type: BlockType.contentColumns,
        title: resource.title || '',
        columns,
    };
};
