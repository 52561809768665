import { FC, ReactElement } from 'react';

import { Select } from '../../../components';
import { FormOption } from '../../../entities/@forms/Form/Form';
import { InputLabelProps, InputLabelWrapper } from '../..';

import './SelectInput.scss';

export interface SelectInputProps extends InputLabelProps {
    isLoading?: boolean;
    isLoadingMessage?: string;
    isSearchable?: boolean;
    name: string;
    value?: FormOption;
    options: FormOption[];
    placeholder?: string;
    required?: boolean;
    disabled?: boolean;
    error?: string;
    onChange: (value: string) => void;
    inputLabelWrapperClassName?: string;
    labelClassName?: string;
    className?: string;
}

export const SelectInput: FC<SelectInputProps> = ({
    isLoading,
    isLoadingMessage,
    isSearchable = false,
    label,
    hideLabel,
    name,
    value,
    options,
    placeholder,
    required,
    disabled,
    tooltip,
    error,
    onChange,
    inputLabelWrapperClassName,
    labelClassName,
    className = '',
}): ReactElement => {
    const handleChange = (selected: FormOption): void => {
        onChange(selected.value);
    };

    return (
        <label aria-label={label} className={`select-input ${className}`}>
            <InputLabelWrapper
                hideLabel={hideLabel}
                label={label}
                required={required}
                tooltip={tooltip}
                error={error}
                className={inputLabelWrapperClassName}
                labelClassName={labelClassName}
            >
                <Select
                    isLoading={!!isLoading}
                    isLoadingMessage={isLoadingMessage}
                    isSearchable={isSearchable}
                    name={name}
                    value={value}
                    options={options}
                    required={required}
                    disabled={disabled}
                    placeholder={placeholder}
                    onChange={handleChange}
                />
            </InputLabelWrapper>
        </label>
    );
};
