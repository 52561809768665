import qs from 'qs';

export const generateServiceSectionQuery = (): string => {
    const populate = {
        whatsApp: {
            populate: {
                openingHours: {
                    populate: '*',
                },
                adjustedOpeningHours: {
                    populate: '*',
                },
            },
        },
        phone: {
            populate: {
                openingHours: {
                    populate: '*',
                },
                adjustedOpeningHours: {
                    populate: '*',
                },
            },
        },
        email: {
            populate: {
                openingHours: {
                    populate: '*',
                },
            },
        },
    };

    return qs.stringify({ populate }, { encode: true });
};
