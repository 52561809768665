import { FC, ReactElement, useEffect } from 'react';

import { useLocation } from 'react-router-dom';

import {
    ConnectedCacheBuster,
    ConnectedFooter,
    ConnectedMenu,
    ConnectedToastList,
    ConnectedTopNavbar,
    ConnectedUrgentBanner,
} from './connectors';
import { scrollToTop } from './helpers/scroll';
import { LocalizedRoutes } from './LocalizedRoutes';

export const App: FC = (): ReactElement => {
    const { pathname, hash } = useLocation();

    useEffect((): void => {
        if (!hash) {
            scrollToTop('auto');
        }
    }, [pathname]);

    return (
        <>
            <ConnectedTopNavbar />
            <ConnectedMenu />
            <ConnectedUrgentBanner />

            <LocalizedRoutes />

            <ConnectedFooter />

            <ConnectedToastList />
            <ConnectedCacheBuster />
        </>
    );
};
