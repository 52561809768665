import {
    FormErrors,
    FormValidation,
    validateEmailAddress,
    validateForm,
    validateRequiredBoolean,
    validateRequiredString,
    validateStringMatches,
} from '../../../../entities/@forms/FormValidation/FormValidationService';
import {
    RegistrationAccountDetailsFormData,
    RegistrationEmailFormData,
    RegistrationFormData,
} from '../../../../entities/@forms/RegistrationForm/RegistrationForm';

export type RegistrationFormErrors = FormErrors<RegistrationFormData>;

export const validateRegistrationEmailFormData = (formData: RegistrationEmailFormData): FormValidation<RegistrationFormErrors> => {
    const errors: RegistrationFormErrors = {
        email: validateEmailAddress(formData.email),
    };

    return validateForm<RegistrationFormErrors>(errors);
};

export const validateRegistrationAccountDetailsFormData = (formData: RegistrationAccountDetailsFormData): FormValidation<RegistrationFormErrors> => {
    const errors: RegistrationFormErrors = {
        firstName: validateRequiredString('firstName', formData.firstName),
        lastName: validateRequiredString('lastName', formData.lastName),
        password: validateRequiredString('password', formData.password),
        confirmPassword: validateStringMatches('confirmPassword', formData.password, formData.confirmPassword),
        acceptTermsAndConditions: validateRequiredBoolean('acceptTermsAndConditions', formData.acceptTermsAndConditions),
    };

    return validateForm<RegistrationFormErrors>(errors);
};
