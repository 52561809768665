import { ChangePasswordFormData } from '../../../../entities/@forms/ChangePasswordForm/ChangePasswordForm';
import {
    FormErrors,
    FormValidation,
    validateForm,
    validateRequiredString,
} from '../../../../entities/@forms/FormValidation/FormValidationService';

export type ChangePasswordFormErrors = FormErrors<ChangePasswordFormData>;

export const validateChangePasswordFormData = (formData: ChangePasswordFormData): FormValidation<ChangePasswordFormErrors> => {
    const errors: ChangePasswordFormErrors = {
        currentPassword: validateRequiredString('currentPassword', formData.currentPassword),
        newPassword: validateRequiredString('newPassword', formData.newPassword),
        confirmNewPassword: validateRequiredString('confirmNewPassword', formData.confirmNewPassword),
    };

    return validateForm<ChangePasswordFormErrors>(errors);
};
