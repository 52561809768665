import { FC, ReactElement } from 'react';

import { Link } from 'react-router-dom';

import { Logo, Wrapper } from '../../../../components';
import { LinkIconButton } from '../../../../compositions';
import { ConnectedCartSideBar, ConnectedSearchPopup } from '../../../../connectors';
import { AppRoute, appRoutes } from '../../../../entities/Routing/Routing';
import { useTrans } from '../../../../hooks';

import './DesktopMenu.scss';

interface DesktopMenuProps {
    className?: string;
}

export const DesktopMenu: FC<DesktopMenuProps> = ({
    className = '',
}): ReactElement => {
    const trans = useTrans();

    return (
        <div className={`desktop-menu ${className}`}>
            <Wrapper className="desktop-menu__wrapper">
                <div className="desktop-menu__navigation-toggle">
                    MENU
                </div>

                <Link to={trans(appRoutes[AppRoute.home].path)}>
                    <Logo className="desktop-menu__logo" />
                </Link>

                <div className="desktop-menu__controls">
                    <ConnectedSearchPopup />

                    <LinkIconButton
                        hideLabel
                        to={trans(appRoutes[AppRoute.accountDashboard].path)}
                        icon="user"
                        text={trans('compositions.menuBar.accountDashboard')}
                        className="desktop-menu__link"
                        iconClassName="desktop-menu__link-icon"
                    />

                    <LinkIconButton
                        hideLabel
                        to={trans(appRoutes[AppRoute.wishlist].path)}
                        icon="heart"
                        text={trans('compositions.menuBar.wishlist')}
                        className="desktop-menu__link"
                        iconClassName="desktop-menu__link-icon"
                    />

                    <ConnectedCartSideBar />
                </div>
            </Wrapper>
        </div>
    );
};
