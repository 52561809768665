import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Navigation } from '../../entities/Navigation/Navigation';
import { mockUnauthorizedNavigation } from '../../mock/mock-data';
import { AsyncReduxState, initialAsyncReduxState } from '../defaults';

export type NavigationState = AsyncReduxState<{
    navigation?: Navigation;
}>;

const initialState: NavigationState = {
    ...initialAsyncReduxState,
    navigation: mockUnauthorizedNavigation,
};

const navigationSlice = createSlice({
    name: 'navigationSlice',
    initialState,
    reducers: {
        setHasFetched(state, action: PayloadAction<boolean>): NavigationState {
            return {
                ...state,
                hasFetched: action.payload,
            };
        },
        setIsLoading(state, action: PayloadAction<boolean>): NavigationState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setIsSuccessful(state, action: PayloadAction<boolean>): NavigationState {
            return {
                ...state,
                isSuccessful: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): NavigationState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setNavigation(state, action: PayloadAction<Navigation | undefined>): NavigationState {
            return {
                ...state,
                navigation: action.payload,
            };
        },
    },
});

export const {
    setHasFetched,
    setIsLoading,
    setIsSuccessful,
    setError,
    setNavigation,
} = navigationSlice.actions;

export default navigationSlice;
