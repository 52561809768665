import {
    FC,
    FormEvent,
    ReactElement,
    useRef,
    useState,
} from 'react';

import classNames from 'classnames';
import { createSearchParams, useNavigate } from 'react-router-dom';

import { Wrapper } from '../../components';
import { IconButton } from '../../compositions';
import { ConnectedInstantSearchGlobalSearch, ConnectedInstantSearchInstance } from '../../connectors';
import { HorizontalAlignment } from '../../entities/Alignment/Alignment';
import { Link } from '../../entities/Link/Link';
import { AppRoute, appRoutes } from '../../entities/Routing/Routing';
import { SearchPopupProductItem } from '../../entities/SearchPopup/SearchPopup';
import { StoreWithWorkingHours } from '../../entities/Store/Store';
import { useDeviceWidth, useHandleClickOutside, useTrans } from '../../hooks';
import {
    LinkSuggestions,
    ProductSuggestions,
    Stores,
    Suggestions,
} from './subcomponents';

import './SearchPopup.scss';

interface SearchPopupProps {
    searchTerm: string;
    pageResults: Link[];
    productResults: SearchPopupProductItem[];
    locations: StoreWithWorkingHours[];
    pageSuggestions: Link[];
    recentSearches?: string[];
    onSetStoreTermInRecentSearches: (term: string) => void;
    onSetSearchTerm: (term: string) => void;
    onSetProductResults: (products: SearchPopupProductItem[]) => void;
    className?: string;
}

export const SearchPopup: FC<SearchPopupProps> = ({
    searchTerm,
    pageResults,
    productResults,
    locations,
    pageSuggestions,
    recentSearches,
    onSetStoreTermInRecentSearches,
    onSetSearchTerm,
    onSetProductResults,
    className = '',
}): ReactElement => {
    const trans = useTrans();
    const navigate = useNavigate();
    const { isMobile } = useDeviceWidth();

    const [popUpIsOpen, setPopUpIsOpen] = useState<boolean>(false);
    const [hasStartedTyping, setHasStartedTyping] = useState<boolean>(false);

    const itemRef = useRef<HTMLDivElement>(null);
    const searchInputRef = useRef<HTMLInputElement>(null);

    const closePopUp = (): void => {
        setPopUpIsOpen(false);
    };

    useHandleClickOutside(itemRef, closePopUp);

    const navigateToSearchResults = (term: string): void => {
        navigate({
            pathname: trans(appRoutes[AppRoute.search].path),
            search: createSearchParams({ q: term }).toString(),
        });
    };

    const handleClickRecentSearch = (event: FormEvent<HTMLButtonElement>): void => {
        const { currentTarget } = event;
        const { innerText } = currentTarget;

        closePopUp();
        onSetSearchTerm(innerText);
        navigateToSearchResults(innerText);
    };

    const handleShowAllResults = (): void => {
        closePopUp();
        navigateToSearchResults(searchTerm);
        onSetStoreTermInRecentSearches(searchTerm);
    };

    const handleSearchButtonClick = (): void => {
        setPopUpIsOpen(!popUpIsOpen);

        if (searchInputRef.current) {
            searchInputRef.current.focus();
        }
    };

    const popupWrapperClassNames = classNames('search-popup__wrapper', {
        'search-popup__wrapper--is-active': popUpIsOpen,
    });

    return (
        <section ref={itemRef} className={`search-popup ${className}`}>
            <IconButton
                hideLabel
                icon={(popUpIsOpen && isMobile) ? 'cross' : 'search'}
                text={trans('common.search')}
                onClick={handleSearchButtonClick}
                className="search-popup__link"
                iconClassName="search-popup__link-icon"
            />

            <div className={popupWrapperClassNames}>
                <Wrapper>
                    <ConnectedInstantSearchInstance>
                        <ConnectedInstantSearchGlobalSearch
                            ref={searchInputRef}
                            onIsTyping={setHasStartedTyping}
                            onSubmit={handleShowAllResults}
                            onNewSearchTerm={onSetSearchTerm}
                            onNewProducts={onSetProductResults}
                        />
                    </ConnectedInstantSearchInstance>

                    <div className="search-popup__search-results-wrapper">
                        {(!hasStartedTyping && !productResults.length) && (
                            <>
                                <LinkSuggestions
                                    label={trans('containers.searchPopup.pageSuggestions')}
                                    links={pageSuggestions}
                                    onClickLink={closePopUp}
                                />

                                {locations.length > 0 && (
                                    <Stores
                                        locations={locations}
                                        onClickSeeAll={closePopUp}
                                    />
                                )}
                            </>
                        )}

                        {hasStartedTyping && (
                            <>
                                {recentSearches && (
                                    <Suggestions
                                        label={trans('containers.searchPopup.recentSearches')}
                                        items={recentSearches}
                                        onClickItem={handleClickRecentSearch}
                                    />
                                )}

                                {productResults.length > 0 && (
                                    <ProductSuggestions
                                        items={productResults}
                                        onClickItem={closePopUp}
                                    />
                                )}

                                {pageResults.length > 0 && (
                                    <LinkSuggestions
                                        label={trans('containers.searchPopup.pages')}
                                        links={pageResults}
                                        onClickLink={closePopUp}
                                    />
                                )}
                            </>
                        )}
                    </div>

                    {hasStartedTyping && (
                        <IconButton
                            isSmall
                            icon="arrow-right"
                            text={trans('containers.searchPopup.showAllResults')}
                            iconPos={HorizontalAlignment.right}
                            onClick={handleShowAllResults}
                            className="search-popup__show-all-results-button"
                        />
                    )}
                </Wrapper>
            </div>
        </section>
    );
};
