import { FC, ReactElement } from 'react';

import { PageHelmet } from '../../../containers';
import { AppRoute } from '../../../entities/Routing/Routing';
import { useTypedSelector } from '../../../redux/store';

interface ConnectedPageHelmetProps {
    appRoute: AppRoute;
}

export const ConnectedPageHelmet: FC<ConnectedPageHelmetProps> = ({
    appRoute,
}): ReactElement | null => {
    const pages = useTypedSelector(state => state.pagesSlice.pages);

    const pageData = pages[appRoute];

    if (!pageData) {
        return null;
    }

    return (
        <PageHelmet page={pageData} />
    );
};
