import { transformToBlock } from '../../Block/BlockTransformers';
import { transformToImage } from '../../Media/MediaTransformers';
import { transformToSeo } from '../../Seo/SeoTransformers';
import { DepartmentDetailInterface, DepartmentDetailResource } from './DepartmentDetail';

export const transformToDepartmentDetailInterface = (resource: DepartmentDetailResource): DepartmentDetailInterface => {
    const image = resource.image
        ? transformToImage(resource.image)
        : undefined;

    const blocks = resource.elements
        ? resource.elements.map(transformToBlock)
        : [];

    const publishedBlocks = blocks.filter(block => block.isPublished);

    const seo = resource.seo
        ? transformToSeo(resource.seo)
        : undefined;

    return {
        id: resource.documentId,
        title: resource.title,
        slug: resource.slug,
        intro: resource.intro,
        image,
        blocks: publishedBlocks,
        seo,
    };
};
