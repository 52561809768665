import { FC, ReactElement } from 'react';

import { CartSidebar } from '../../containers';
import { UspItem } from '../../entities/@blocks/UspSection/UspSection';
import { removeProductVariantFromCart, updateProductVariantInCart } from '../../redux/cart/cartActions';
import { useTypedDispatch, useTypedSelector } from '../../redux/store';

export const ConnectedCartSideBar: FC = (): ReactElement => {
    const dispatch = useTypedDispatch();

    const mockUspItems: UspItem[] = [
        { id: 0, text: 'Online besteld? Gratis verzonden', icon: 'cart-truck' },
        { id: 1, text: 'Keuze uit meer dan 300 meubelmerken', icon: 'lamp' },
        { id: 2, text: 'Niet tevreden? Geld terug!', icon: 'happy' },
    ];

    const cart = useTypedSelector(state => state.cartSlice.cart);
    const isLoading = useTypedSelector(state => state.cartSlice.isLoading);
    const productIsAddedToCart = useTypedSelector(state => state.cartSlice.isSuccessfulAdded);

    const handleUpdateCartItemQuantity = (id: string, quantity: number) => {
        dispatch(updateProductVariantInCart(id, quantity, cart.id));
    };

    const handleRemoveItemFromCart = (id: string) => {
        dispatch(removeProductVariantFromCart(id, cart));
    };

    const handleCartItemIsInWishlist = (productId: string) => {
        console.log('handleCartItemIsInWishlist', productId);

        // TODO: Add query to add to wishlist
    };

    return (
        <CartSidebar
            {...cart}
            isLoading={isLoading}
            uspItems={mockUspItems}
            productIsAddedToCart={productIsAddedToCart}
            onRemoveItemFromCart={handleRemoveItemFromCart}
            onUpdateCartItem={handleUpdateCartItemQuantity}
            onUpdateCartItemIsInWishlist={handleCartItemIsInWishlist}
        />
    );
};
