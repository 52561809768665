import { FC, ReactElement } from 'react';

import { Skeleton } from '../../../../components';
import { generateIdArray } from '../../../../helpers/array';
import { randomInBetweenValue } from '../../../../helpers/number';

import './DepartmentOverviewSkeletons.scss';

interface DepartmentOverviewSkeletonsProps {
    numberOfSkeletons?: number;
    className?: string;
}

export const DepartmentOverviewSkeletons: FC<DepartmentOverviewSkeletonsProps> = ({
    numberOfSkeletons = randomInBetweenValue(4, 6),
    className = '',
}): ReactElement => {
    const departmentSkeletons = generateIdArray(numberOfSkeletons);

    return (
        <ul className={`department-overview-skeletons ${className}`}>
            {departmentSkeletons.map(skeleton => {
                const numberOfIntroLines = randomInBetweenValue(3, 4);
                const introSkeletons = generateIdArray(numberOfIntroLines);

                return (
                    <li key={skeleton} className="department-overview-skeletons__list-item">
                        <div className="department-overview-skeletons__image" />

                        <Skeleton className="department-overview-skeletons__title" />

                        <div className="department-overview-skeletons__intro">
                            {introSkeletons.map(line => (
                                <Skeleton key={line} className="department-overview-skeletons__intro-line" />
                            ))}
                        </div>

                        <div className="department-overview-skeletons__button-wrapper">
                            <Skeleton className="department-overview-skeletons__button" />
                        </div>
                    </li>
                );
            })}
        </ul>
    );
};
