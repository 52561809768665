import {
    FC,
    FormEvent,
    ReactElement,
    useRef,
    useState,
} from 'react';

import { FormProps } from '../../../entities/@forms/Form/Form';
import { RegistrationFormData, RegistrationFormStep } from '../../../entities/@forms/RegistrationForm/RegistrationForm';
import { scrollIntoView } from '../../../helpers/scroll';
import { RegistrationFormStepAccountDetails } from './subcomponents/RegistrationFormStepAccountDetails';
import { RegistrationFormStepEmail } from './subcomponents/RegistrationFormStepEmail';
import { RegistrationFormErrors, validateRegistrationAccountDetailsFormData, validateRegistrationEmailFormData } from './validations';

import './RegistrationForm.scss';

interface RegistrationFormProps extends FormProps<RegistrationFormData> {
    className?: string;
}

export const RegistrationForm: FC<RegistrationFormProps> = ({
    isLoading,
    error,
    onSubmit,
    className = '',
}): ReactElement => {
    const formRef = useRef<HTMLFormElement>(null);

    const [formErrors, setFormErrors] = useState<RegistrationFormErrors>({});

    const [formData, setFormData] = useState<RegistrationFormData>({
        email: '',
        firstName: '',
        lastName: '',
        password: '',
        subscribedToNewsletter: false,
    });

    const [currentStep, setCurrentStep] = useState<RegistrationFormStep>(RegistrationFormStep.email);

    const submitForm = (): void => {
        const formDataToSubmit = { ...formData };

        // Omit unmapped fields
        delete formDataToSubmit.confirmPassword;
        delete formDataToSubmit.acceptTermsAndConditions;

        onSubmit(formDataToSubmit);
    };

    const handleSubmitStep = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
        event.preventDefault();

        if (currentStep === RegistrationFormStep.email) {
            const [errors, hasErrors] = validateRegistrationEmailFormData(formData);

            setFormErrors(errors);

            if (!hasErrors) {
                setCurrentStep(RegistrationFormStep.accountDetails);
            } else {
                scrollIntoView<HTMLFormElement>(formRef);
            }

            return;
        }

        if (currentStep === RegistrationFormStep.accountDetails) {
            const [errors, hasErrors] = validateRegistrationAccountDetailsFormData(formData);

            setFormErrors(errors);

            if (!hasErrors) {
                submitForm();
            } else {
                scrollIntoView<HTMLFormElement>(formRef);
            }
        }
    };

    return (
        <form
            ref={formRef}
            onSubmit={handleSubmitStep}
            enterKeyHint={currentStep === RegistrationFormStep.accountDetails ? 'next' : 'done'}
            className={`registration-form ${className}`}
        >
            {currentStep === RegistrationFormStep.email && (
                <RegistrationFormStepEmail
                    isLoading={isLoading}
                    formErrors={formErrors}
                    formData={formData}
                    setFormData={setFormData}
                />
            )}

            {currentStep === RegistrationFormStep.accountDetails && (
                <RegistrationFormStepAccountDetails
                    isLoading={isLoading}
                    formErrors={formErrors}
                    formData={formData}
                    setFormData={setFormData}
                    error={error}
                />
            )}
        </form>
    );
};
