import { FC, ReactElement } from 'react';

import { Page } from '../../components';
import { ConnectedCustomPageHelmet, ConnectedCustomPageRenderer } from '../../connectors';

import './CustomPage.scss';

export const CustomPage: FC = (): ReactElement => (
    <Page className="custom-page">
        <ConnectedCustomPageHelmet />
        <ConnectedCustomPageRenderer />
    </Page>
);
