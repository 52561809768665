import { FC, ReactElement } from 'react';

import { useNavigate } from 'react-router-dom';

import { ContactOption, Wrapper } from '../../../components';
import {
    AdjustedOpeningHours,
    ServiceContactDetails,
    ServiceSectionInterface,
    WorkingHours,
} from '../../../entities/@blocks/ServiceSection/ServiceSection';
import { serviceFaqSearchParam, ServiceFormData } from '../../../entities/@forms/ServiceForm/ServiceForm';
import { AppRoute, appRoutes } from '../../../entities/Routing/Routing';
import { formatPhoneNumber } from '../../../helpers/phoneNumber';
import { getTodayIsoDate } from '../../../helpers/validation';
import { useTrans } from '../../../hooks';
import { ServiceForm } from '../../@forms/ServiceForm/ServiceForm';

import './ServiceSection.scss';

interface ServiceSectionProps extends ServiceSectionInterface {
    serviceContactDetails?: ServiceContactDetails;
    className?: string;
}

export const ServiceSection: FC<ServiceSectionProps> = ({
    title,
    serviceContactDetails,
    className = '',
}): ReactElement => {
    const trans = useTrans();
    const navigate = useNavigate();

    const handleSearch = (formData: ServiceFormData): void => {
        const { question } = formData;

        const pathname = trans(appRoutes[AppRoute.customerServiceFaq].path);
        const params = new URLSearchParams();

        params.set(serviceFaqSearchParam, question);

        const paramString = params.toString();
        const query = paramString ? `?${params}` : '';

        navigate(pathname + query);
    };

    const determineWorkingHours = (
        workingHours: WorkingHours[],
        adjustedOpeningHours?: AdjustedOpeningHours[],
    ): WorkingHours[] => {
        const todayIso = getTodayIsoDate();
        const adjustedHour = adjustedOpeningHours?.find((item) => item.day === todayIso);

        if (adjustedHour) {
            return [
                {
                    day: undefined,
                    from: !adjustedHour.closed ? adjustedHour.from : undefined,
                    to: !adjustedHour.closed ? adjustedHour.to : undefined,
                    closed: adjustedHour.closed,
                },
            ];
        }

        return workingHours;
    };

    return (
        <section className={`service-section ${className}`}>
            <Wrapper className="service-section__wrapper">
                <div className="service-section__column">
                    <h2 className="service-section__title">
                        {title}
                    </h2>

                    <ServiceForm
                        onSubmit={handleSearch}
                        className="service-section__form"
                    />
                </div>

                {serviceContactDetails?.whatsApp && (
                    <div className="service-section__column">
                        <ContactOption
                            linkText={trans('containers.serviceSection.contactOptionWhatsApp')}
                            linkUrl={`https://wa.me/${formatPhoneNumber(serviceContactDetails.whatsApp.phoneNumber, true)}`}
                            workingHours={determineWorkingHours(
                                serviceContactDetails.whatsApp.workingHours,
                                serviceContactDetails.whatsApp.adjustedOpeningHours,
                            )}
                        />
                    </div>
                )}

                {serviceContactDetails?.phone && (
                    <div className="service-section__column">
                        <ContactOption
                            linkText={trans('containers.serviceSection.contactOptionPhone', {
                                phoneNumber: formatPhoneNumber(serviceContactDetails.phone.phoneNumber, false, true),
                            })}
                            linkUrl={`tel:${formatPhoneNumber(serviceContactDetails.phone.phoneNumber, false)}`}
                            workingHours={determineWorkingHours(
                                serviceContactDetails.phone.workingHours,
                                serviceContactDetails.phone.adjustedOpeningHours,
                            )}
                        />
                    </div>
                )}

                {serviceContactDetails?.email && (
                    <div className="service-section__column">
                        <ContactOption
                            linkText={trans('containers.serviceSection.contactOptionEmail')}
                            linkUrl={`mailto:${serviceContactDetails.email.emailAddress}`}
                            workingHours={determineWorkingHours(serviceContactDetails.email.workingHours)}
                        />
                    </div>
                )}
            </Wrapper>
        </section>
    );
};
