import {
    FC,
    ReactElement,
    useEffect,
    useState,
} from 'react';

import { ConnectedInstantSearchInstance, ConnectedProductList } from '../../connectors';
import { MeilisearchAttributes } from '../../entities/Meilisearch/Meilisearch';
import { useTrans } from '../../hooks';

import './GuestWishlistItems.scss';

interface GuestWishlistItemsProps {
    items: string[];
    className?: string;
}

export const GuestWishlistItems: FC<GuestWishlistItemsProps> = ({
    items,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    const [productsQuery, setProductsQuery] = useState<string>('');

    useEffect(() => {
        if (!items) {
            return;
        }

        const filterQuery = items.map(code => `${MeilisearchAttributes.code} = ${code}`).join(' OR ');
        setProductsQuery(filterQuery);
    }, [items]);

    return (
        <div className={`guest-wishlist-items ${className}`}>
            {items.length > 0 && (
                <div className="guest-wishlist-items__items">
                    <ConnectedInstantSearchInstance>
                        <ConnectedProductList filterQuery={productsQuery} />
                    </ConnectedInstantSearchInstance>
                </div>
            )}

            {items.length < 1 && (
                <p className="guest-wishlist-items__no-items-label">
                    {trans('pages.accountWishlist.noWishlistItem')}
                </p>
            )}
        </div>
    );
};
