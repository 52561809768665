import { FC, ReactElement } from 'react';

import { RangeRenderState } from 'instantsearch.js/es/connectors/range/connectRange';
import { SortByItem } from 'instantsearch.js/es/connectors/sort-by/connectSortBy';

import { ProductsCountForSearchTerm } from '../../compositions/ProductsCountForSearchTerm/ProductsCountForSearchTerm';
import {
    ConnectedInstantSearchPagesList,
    ConnectedInstantSearchPagination,
    ConnectedInstantSearchProductResultsList,
    ConnectedProductCard,
} from '../../connectors';
import { ProductSlider } from '../../entities/SliderSection/SliderSection';
import { InstantSearchProductFilterBar } from '../@instantsearch/InstantSearchProductFilterBar/InstantSearchProductFilterBar';
import { SliderSection } from '../SliderSection/SliderSection';

import './ProductList.scss';

interface ProductListProps {
    title?: string;
    query?: string;
    resultsCount: number;
    sortItems: SortByItem[];
    priceRangeConnector: RangeRenderState;
    noProductsAvailableSliders: ProductSlider[];
    showProductsCountForSearchTerm?: boolean;
    showPagesList?: boolean;
    showFilterBar?: boolean;
    onChangeSorting: (option: string) => void;
    onResetFilters: () => void;
    className?: string;
}

export const ProductList: FC<ProductListProps> = ({
    title,
    query,
    resultsCount,
    sortItems,
    priceRangeConnector,
    noProductsAvailableSliders,
    showProductsCountForSearchTerm,
    showPagesList,
    showFilterBar,
    onChangeSorting,
    onResetFilters,
    className = '',
}): ReactElement => (
    <div className={`product-list ${className}`}>
        {title && (
            <h2 className="product-list__heading">
                {title}
            </h2>
        )}

        {showProductsCountForSearchTerm && (
            <ProductsCountForSearchTerm
                resultsCount={resultsCount}
                query={query}
                className={className}
            />
        )}

        {showPagesList && (
            <ConnectedInstantSearchPagesList />
        )}

        {showFilterBar && resultsCount !== 0 && (
            <InstantSearchProductFilterBar
                resultsCount={resultsCount}
                sortItems={sortItems}
                priceRangeConnector={priceRangeConnector}
                onChangeSorting={onChangeSorting}
                className={className}
            />
        )}

        <ConnectedInstantSearchProductResultsList onResetFilters={onResetFilters} />

        {showProductsCountForSearchTerm && resultsCount === 0 && (
            noProductsAvailableSliders.map(productSlider => (
                <SliderSection
                    {...productSlider}
                    key={productSlider.id}
                    items={productSlider.products.map(product => (
                        <ConnectedProductCard
                            key={product.id}
                            product={product}
                        />
                    ))}
                    className="search-page__slider"
                />
            ))
        )}

        <ConnectedInstantSearchPagination />
    </div>
);
