import { FC, ReactElement } from 'react';

import { SelectInput, SelectInputProps } from '..';

import './SortInput.scss';

interface SortInputProps extends SelectInputProps {
    onChange: (option: string) => void;
    className?: string;
}

export const SortInput: FC<SortInputProps> = ({
    name,
    options,
    label,
    placeholder,
    onChange,
    className = '',
}): ReactElement => (
    <div className={`sort-input ${className}`}>
        <SelectInput
            name={name}
            options={options}
            label={label}
            placeholder={placeholder}
            onChange={onChange}
            inputLabelWrapperClassName="sort-input__input-label-wrapper"
            labelClassName="sort-input__input-label"
            className="sort-input__select"
        />
    </div>
);
