import trans from '../../helpers/trans';
import { MeilisearchAttributes } from '../Meilisearch/Meilisearch';

export enum ProductFilterType {
    refinementList = 'refinementList',
    priceRange = 'priceRange',
}

export interface ProductFilter {
    label: string;
    attribute: MeilisearchAttributes;
    operator?: 'and' | 'or';
    type?: ProductFilterType;
    min?: number;
    max?: number;
}

export const productFilters: ProductFilter[] = [
    {
        attribute: MeilisearchAttributes.categories,
        label: trans('entities.productFilters.category'),
        operator: 'or',
        type: ProductFilterType.refinementList,
    },
    {
        attribute: MeilisearchAttributes.brand,
        label: trans('entities.productFilters.brand'),
        operator: 'or',
        type: ProductFilterType.refinementList,
    },
    {
        attribute: MeilisearchAttributes.price,
        label: trans('entities.productFilters.price'),
        type: ProductFilterType.priceRange,
    },
    // TODO: Add `productType` (L5W-343) and `colors` (when available) `material` and `origin` filters
];
