export interface StrapiPaginationResource {
    total: number;
    page: number;
    pageSize: number;
    pageCount: number;
}

export interface StrapiPaginationMetaResource {
    pagination: StrapiPaginationResource;
}

export interface PaginationInterface {
    total: number;
    page: number;
    pageSize: number;
    pageCount: number;
}

export const paginationPageParameter = 'page';
