export const clamp = (value: number, min: number, max: number): number => Math.min(Math.max(value, min), max);

export const randomInBetweenValue = (min: number, max: number): number => (
    Math.floor(Math.random() * (max - min + 1) + min)
);

export const convertNumberToCurrency = (
    number: number,
    includeCurrencySymbol: boolean,
    locale = 'nl-NL',
): string => number
    .toLocaleString(locale, {
        style: includeCurrencySymbol ? 'currency' : 'decimal',
        currency: 'EUR',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    })
    .replace(',00', ',-');


export const formatNumberWithLeadingZero = (
    number: number,
    locale = 'nl-NL',
): string => number.toLocaleString(locale, {
    minimumIntegerDigits: 2,
    useGrouping: false,
});

export const getClosestNumber = (value: number, options: number[]): number => options.sort((current, comparison): number => {
    const currentOption = Math.abs(current - value);
    const comparisonOption = Math.abs(comparison - value);

    return currentOption - comparisonOption;
})[0];
