import { SortByItem } from 'instantsearch.js/es/connectors/sort-by/connectSortBy';

import trans from '../../helpers/trans';
import { MeilisearchIndex } from '../Meilisearch/MeilisearchService';

export enum MeilisearchSortingProperty {
    New = 'is_new',
    Price = 'price',
    HighestDiscount = 'highestDiscount',
    BestRating = 'bestRating',
}

export enum MeilisearchSortingDirection {
    Ascending = 'asc',
    Descending = 'desc',
}

export const productSortingOptions: SortByItem[] = [
    {
        label: trans('entities.productSorting.recommended'),
        value: MeilisearchIndex.variantsNl,
    },
    {
        label: trans('entities.productSorting.new'),
        value: `${MeilisearchIndex.variantsNl}:${MeilisearchSortingProperty.New}:${MeilisearchSortingDirection.Descending}`,
    },
    {
        label: trans('entities.productSorting.priceAsc'),
        value: `${MeilisearchIndex.variantsNl}:${MeilisearchSortingProperty.Price}:${MeilisearchSortingDirection.Ascending}`,
    },
    {
        label: trans('entities.productSorting.priceDesc'),
        value: `${MeilisearchIndex.variantsNl}:${MeilisearchSortingProperty.Price}:${MeilisearchSortingDirection.Descending}`,
    },
    // TODO: Add `new`, `highestDiscount` and `bestRating` filters (L5W-431)
];
