import { FC, ReactElement } from 'react';

import { Link } from 'react-router-dom';

import { Icon } from '../../../components';
import { PageResult } from '../../../entities/PageResult/PageResult';

import './InstantSearchPageHit.scss';

interface InstantSearchPageHitProps {
    page: PageResult;
    className?: string;
}

export const InstantSearchPageHit: FC<InstantSearchPageHitProps> = ({
    page,
    className = '',
}): ReactElement => (
    <div className={`instant-search-page-hit ${className}`}>
        <div>
            <p className="instant-search-page-hit__label">
                {page.type}
            </p>

            <Link to={`/${page.slug}`} className="instant-search-page-hit__title">
                {page.title}
            </Link>

            <p className="instant-search-page-hit__description">
                {page.shortDescription}
            </p>
        </div>

        <Icon
            name="arrow-short-right"
            className="instant-search-page-hit__icon"
        />
    </div>
);
