import {
    FC,
    ReactElement,
    useEffect,
    useState,
} from 'react';

import { Menu } from '../../containers';
import { Navigation } from '../../entities/Navigation/Navigation';
import { getParentNavigation } from '../../helpers/navigation';
import { useTypedSelector } from '../../redux/store';

interface ConnectedMenuProps {
    className?: string;
}

export const ConnectedMenu: FC<ConnectedMenuProps> = ({
    className = '',
}): ReactElement => {
    const isLoading = useTypedSelector(state => state.navigationSlice.isLoading);
    const navigation = useTypedSelector(state => state.navigationSlice.navigation);

    const [activeNavigation, setActiveNavigation] = useState<Navigation>();
    const [hasActiveSubNavigation, setHasActiveSubNavigation] = useState<boolean>(false);

    useEffect((): void => {
        setActiveNavigation(navigation);
    }, [navigation]);

    useEffect((): void => {
        if (navigation && activeNavigation) {
            setHasActiveSubNavigation(navigation.id !== activeNavigation.id);
        }
    }, [activeNavigation]);

    const handleBackButtonClick = (): void => {
        if (!navigation || !activeNavigation) {
            return;
        }

        const parentNavigation = getParentNavigation(navigation, activeNavigation);

        if (parentNavigation) {
            setActiveNavigation(parentNavigation);
        }
    };

    return (
        <Menu
            isLoading={isLoading}
            hasActiveSubNavigation={hasActiveSubNavigation}
            navigation={navigation}
            activeNavigation={activeNavigation}
            onNavigationChange={setActiveNavigation}
            onBackButtonClick={handleBackButtonClick}
            className={className}
        />
    );
};
