import { SearchableOption } from '../@forms/Form/Form';
import { osmGeometrySplitCharacter } from '../OpenStreetMap/OpenStreetMap';
import { MapLocation } from './Map';

export const transformSearchableOptionToMapLocation = (option: SearchableOption): MapLocation => {
    const [latitude, longitude] = option.value.split(osmGeometrySplitCharacter);

    return {
        label: option.label,
        location: {
            latitude: parseFloat(latitude),
            longitude: parseFloat(longitude),
        },
    };
};
