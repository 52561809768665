import { BlogPostResponse } from '../../../entities/@blog/BlogPost/BlogPost';
import { generateBlogPostQuery } from '../../../entities/@blog/BlogPost/BlogPostRequests';
import { transformToBlogPost } from '../../../entities/@blog/BlogPost/BlogPostTransformers';
import { isFetchResultSuccessful } from '../../../entities/FetchResult/FetchResult';
import { getStrapiApiCall } from '../../../entities/Strapi/StrapiService';
import { ReduxFetchAction } from '../../defaults';
import {
    setBlogPost,
    setError,
    setHasFetched,
    setIsLoading,
    setIsSuccessful,
} from './blogPostSlice';

export const fetchBlogPost: ReduxFetchAction<string> = slug => async dispatch => {
    dispatch(setBlogPost(undefined));
    dispatch(setHasFetched(false));
    dispatch(setIsLoading(true));
    dispatch(setIsSuccessful(false));
    dispatch(setError(''));

    try {
        const query = generateBlogPostQuery(slug);

        const blogPostResponse = await getStrapiApiCall<BlogPostResponse>(`/blogs?${query}`);

        if (!isFetchResultSuccessful(blogPostResponse)) {
            dispatch(setError(blogPostResponse.error));
            return;
        }

        const { data: blogPostData } = blogPostResponse.data;

        const blogPostResource = blogPostData
            ? blogPostData[0]
            : undefined;

        const blogPost = blogPostResource
            ? transformToBlogPost(blogPostResource)
            : undefined;

        dispatch(setBlogPost(blogPost));

        dispatch(setIsSuccessful(true));
    } catch (error) {
        console.error('[fetchBlogPost]', error);
    } finally {
        dispatch(setHasFetched(true));
        dispatch(setIsLoading(false));
    }
};
