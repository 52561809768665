import { Navigation } from '../entities/Navigation/Navigation';

export const getParentNavigation = (navigation: Navigation, childNavigation: Navigation): Navigation | undefined => {
    const { groups } = navigation;

    for (let i = 0; i < groups.length; i += 1) {
        const { items } = groups[i];

        for (let j = 0; j < items.length; j += 1) {
            const item = items[j];

            if (item.subNavigation) {
                if (item.subNavigation.id === childNavigation.id) {
                    return navigation;
                }

                return getParentNavigation(item.subNavigation, childNavigation);
            }
        }
    }

    return undefined;
};
