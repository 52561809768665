import { ChangeEvent, FC, ReactElement } from 'react';

import classNames from 'classnames';

import { Input, InputProps } from '../../../components';
import { HorizontalAlignment } from '../../../entities/Alignment/Alignment';
import { InputLabelWrapper } from '../..';
import { InputLabelProps } from '../InputLabel/InputLabel';

import './NumberInput.scss';

interface NumberInputProps extends InputLabelProps, Omit<InputProps, 'onChange'> {
    unit?: string;
    unitPos?: HorizontalAlignment;
    onChange: (value: number) => void;
}

export const NumberInput: FC<NumberInputProps> = ({
    unit = '',
    unitPos = HorizontalAlignment.left,
    label,
    hideLabel,
    required,
    tabIndex,
    tooltip,
    min,
    max,
    error,
    onChange,
    className = '',
    ...inputProps
}): ReactElement => {
    const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
        onChange(+event.currentTarget.value);
    };

    const inputFieldClassNames = classNames('number-input__field', {
        'number-input__field--has-error': error,
    });

    const fieldWrapperClassNames = classNames('number-input__field-wrapper', {
        'number-input__field-wrapper--reversed': unitPos === HorizontalAlignment.right,
    }, className);

    return (
        <label aria-label={label} className={`number-input ${className}`}>
            <InputLabelWrapper
                hideLabel={hideLabel}
                label={label}
                tooltip={tooltip}
                required={required}
                error={error}
            >
                <div className={fieldWrapperClassNames}>
                    {unit && (
                        <span className="number-input__unit">
                            {unit}
                        </span>
                    )}

                    <Input
                        {...inputProps}
                        type="number"
                        required={required}
                        value={inputProps.value}
                        tabIndex={tabIndex}
                        error={error}
                        onChange={handleChange}
                        className="number-input__input"
                        fieldClassName={inputFieldClassNames}
                        min={min}
                        max={max}
                    />
                </div>
            </InputLabelWrapper>
        </label>
    );
};
