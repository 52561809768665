import { CSSProperties, FC, ReactElement } from 'react';

import classNames from 'classnames';

import { Picture, SplitWrapper } from '../../../components';
import {
    NewsletterCallToActionImageType,
    NewsletterCallToActionInterface,
} from '../../../entities/@blocks/NewsletterCallToAction/NewsletterCallToAction';
import { SearchableOption } from '../../../entities/@forms/Form/Form';
import { NewsletterFormData } from '../../../entities/@forms/NewsletterForm/NewsletterForm';
import { HorizontalAlignment, LogicalAlignment } from '../../../entities/Alignment/Alignment';
import { StoreLocation } from '../../../entities/Map/Map';
import { NewsletterForm, StoreLocator } from '../..';

import './NewsletterCallToAction.scss';

interface NewsletterCallToActionProps extends NewsletterCallToActionInterface {
    isLoadingForm: boolean;
    searchQuery: string;
    storeLocations: StoreLocation[];
    searchResults: SearchableOption[];
    formError: string;
    onSearchChange: (query: string) => void;
    onResultClick: () => void;
    onFormSubmit: (formData: NewsletterFormData) => void;
    className?: string;
}

export const NewsletterCallToAction: FC<NewsletterCallToActionProps> = ({
    isLoadingForm,
    title,
    description,
    textColor,
    backgroundColor,
    image,
    imageAlignment,
    imageType,
    showStoreLocator,
    storeLocatorBackgroundColor,
    searchQuery,
    storeLocations,
    searchResults,
    formError,
    onSearchChange,
    onResultClick,
    onFormSubmit,
    className = '',
}): ReactElement => {
    const isReversed = imageAlignment === HorizontalAlignment.right;
    const hasStandaloneImage = image && imageType === NewsletterCallToActionImageType.standalone;
    const hasStartWrapper = hasStandaloneImage || showStoreLocator;

    const newsletterCallToActionClassNames = classNames('newsletter-call-to-action', {
        [`newsletter-call-to-action--image-is-${imageType}`]: imageType,
        'newsletter-call-to-action--show-store-locator': showStoreLocator,
    }, className);

    const wrapperClassNames = classNames('newsletter-call-to-action__wrapper', {
        'newsletter-call-to-action__wrapper--is-reversed': isReversed,
    });

    const startWrapperClassNames = classNames('newsletter-call-to-action__start-wrapper', {
        'newsletter-call-to-action__start-wrapper--has-store-locator': showStoreLocator,
    });

    const endWrapperClassNames = classNames('newsletter-call-to-action__end-wrapper', {
        'newsletter-call-to-action__end-wrapper--is-only-wrapper': !hasStartWrapper,
    });

    const formTitleClassNames = classNames('newsletter-call-to-action__form-title', {
        'newsletter-call-to-action__form-title--is-large': showStoreLocator,
    });

    const cssVariables = {
        '--newsletter-cta-image': image ? `url(${image.src})` : undefined,
        '--c-newsletter-cta-text': textColor,
        '--c-newsletter-cta-background': backgroundColor,
        '--c-newsletter-cta-store-locator-background': storeLocatorBackgroundColor,
    } as CSSProperties;

    return (
        <section style={cssVariables} className={newsletterCallToActionClassNames}>
            <div className={wrapperClassNames}>
                {hasStartWrapper && (
                    <div className={startWrapperClassNames}>
                        {showStoreLocator && (
                            <SplitWrapper
                                alignment={isReversed ? LogicalAlignment.end : LogicalAlignment.start}
                                className="newsletter-call-to-action__inner-wrapper"
                            >
                                <StoreLocator
                                    searchQuery={searchQuery}
                                    storeLocations={storeLocations}
                                    searchResults={searchResults}
                                    onSearchChange={onSearchChange}
                                    onResultClick={onResultClick}
                                    className="newsletter-call-to-action__store-locator"
                                />
                            </SplitWrapper>
                        )}

                        {!showStoreLocator && image && (
                            <Picture
                                {...image}
                                className="newsletter-call-to-action__picture"
                                imageClassName="newsletter-call-to-action__image"
                            />
                        )}
                    </div>
                )}

                <div className={endWrapperClassNames}>
                    <SplitWrapper
                        alignment={isReversed ? LogicalAlignment.start : LogicalAlignment.end}
                        className="newsletter-call-to-action__inner-wrapper"
                    >
                        <div className="newsletter-call-to-action__form-wrapper">
                            {title && (
                                <h2 className={formTitleClassNames}>
                                    {title}
                                </h2>
                            )}

                            {description && (
                                <p className="newsletter-call-to-action__form-description">
                                    {description}
                                </p>
                            )}

                            <NewsletterForm
                                isLoading={isLoadingForm}
                                error={formError}
                                onSubmit={onFormSubmit}
                            />
                        </div>
                    </SplitWrapper>
                </div>
            </div>
        </section>
    );
};
