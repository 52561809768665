import { DepartmentDetailResponse } from '../../../entities/@departments/DepartmentDetail/DepartmentDetail';
import { generateDepartmentDetailQuery } from '../../../entities/@departments/DepartmentDetail/DepartmentDetailRequests';
import { transformToDepartmentDetailInterface } from '../../../entities/@departments/DepartmentDetail/DepartmentDetailTransformers';
import { isFetchResultSuccessful } from '../../../entities/FetchResult/FetchResult';
import { getStrapiApiCall } from '../../../entities/Strapi/StrapiService';
import { ReduxFetchAction } from '../../defaults';
import {
    setDepartmentDetail,
    setError,
    setHasFetched,
    setIsLoading,
    setIsSuccessful,
} from './departmentDetailSlice';

export const fetchDepartmentDetail: ReduxFetchAction<string> = slug => async (dispatch) => {
    dispatch(setHasFetched(false));
    dispatch(setIsLoading(true));
    dispatch(setIsSuccessful(false));
    dispatch(setError(''));

    try {
        const query = generateDepartmentDetailQuery(slug);

        const departmentDetailResponse = await getStrapiApiCall<DepartmentDetailResponse>(`/departments?${query}`);

        if (!isFetchResultSuccessful(departmentDetailResponse)) {
            dispatch(setError(departmentDetailResponse.error));
            return;
        }

        const { data: departmentDetailData } = departmentDetailResponse.data;

        const departmentDetailResource = departmentDetailData
            ? departmentDetailData[0]
            : undefined;

        const departmentDetail = departmentDetailResource
            ? transformToDepartmentDetailInterface(departmentDetailResource)
            : undefined;

        dispatch(setDepartmentDetail(departmentDetail));
        dispatch(setIsSuccessful(true));
    } catch (error) {
        console.error('[fetchDepartmentDetail]', error);
    } finally {
        dispatch(setHasFetched(true));
        dispatch(setIsLoading(false));
    }
};
