import { FC, ReactElement } from 'react';

import { PageHelmet } from '../../../containers';
import { useTypedSelector } from '../../../redux/store';

export const ConnectedCustomPageHelmet: FC = (): ReactElement | null => {
    const pageData = useTypedSelector(state => state.customPageSlice.pageData);

    if (!pageData) {
        return null;
    }

    return (
        <PageHelmet page={pageData} />
    );
};
