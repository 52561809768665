import { FC, ReactElement } from 'react';

import { Page } from '../../../components';
import { ConnectedBlockRenderer, ConnectedPageHelmet } from '../../../connectors';
import { AppRoute } from '../../../entities/Routing/Routing';

import './CustomerServiceReturnsPage.scss';

export const CustomerServiceReturnsPage: FC = (): ReactElement => (
    <Page className="customer-service-returns-page">
        <ConnectedPageHelmet appRoute={AppRoute.customerServiceReturns} />
        <ConnectedBlockRenderer appRoute={AppRoute.customerServiceReturns} />
    </Page>
);
