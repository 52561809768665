import { FaqCategoryType, FaqItemInterface } from './Faq';

const faqCategoryOrder: FaqCategoryType[] = [
    FaqCategoryType.store,
    FaqCategoryType.onlineStore,
    FaqCategoryType.faq,
    FaqCategoryType.returns,
    FaqCategoryType.transport,
    FaqCategoryType.garage,
    FaqCategoryType.other,
];

export const sortFaqItemsByCategory = (faqItem: FaqItemInterface, comparisonItem: FaqItemInterface): number => {
    const order = faqCategoryOrder.indexOf(faqItem.category);
    const comparisonOrder = faqCategoryOrder.indexOf(comparisonItem.category);

    return order - comparisonOrder;
};
