import { objectContainsValues } from '../../../helpers/object';
import trans from '../../../helpers/trans';
import { stringContainsValue, stringMatchesRegEx } from '../../../helpers/validation';

export type FormError<Value = string> = Value | undefined;
export type FormErrors<FormData, FormSubData = string> = Partial<Record<keyof FormData, FormError<FormSubData>>>;

export type FormValidation<Errors> = [FormErrors<Errors>, boolean];
export type OptionalFormValidation<FormData> = Partial<Record<keyof FormData, boolean>>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const validateForm = <Errors extends Record<string, any>>(errors: Errors): FormValidation<Errors> => {
    const hasErrors = objectContainsValues<Errors>(errors);

    return [
        errors,
        hasErrors,
    ];
};

export const validateRequiredBoolean = (attribute: string, value?: boolean): FormError => {
    if (value !== true) {
        return trans('errors.required', {
            attribute: trans(`errors.attributes.${attribute}`),
        });
    }

    return undefined;
};

export const validateRequiredString = (attribute: string, string?: string): FormError => {
    if (!stringContainsValue(string)) {
        return trans('errors.required', {
            attribute: trans(`errors.attributes.${attribute}`),
        });
    }

    return undefined;
};

export const validateStringMatches = (attribute: string, string?: string, comparisonString?: string): FormError => {
    if (string !== comparisonString) {
        return trans('errors.noMatch', {
            attribute: trans(`errors.attributes.${attribute}`),
        });
    }

    return undefined;
};

export const validateEmailAddress = (email: string): FormError => {
    const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;

    if (!stringContainsValue(email)) {
        return trans('errors.required', {
            attribute: trans('errors.attributes.email'),
        });
    }

    if (!stringMatchesRegEx(email, emailRegex)) {
        return trans('errors.invalidExample', {
            attribute: trans('errors.attributes.email'),
            example: trans('errors.examples.email'),
        });
    }

    return undefined;
};
