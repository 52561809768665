import { FC, ReactElement } from 'react';

import { Faq } from '../../../containers';
import { FaqDataInterface } from '../../../entities/@blocks/Faq/Faq';
import { useClientEffect } from '../../../hooks';
import { fetchFaqCategories } from '../../../redux/faq/faqActions';
import { useTypedDispatch, useTypedSelector } from '../../../redux/store';

interface ConnectedFaqProps extends FaqDataInterface {
    showAllFaq?: boolean;
    className?: string;
}

export const ConnectedFaq: FC<ConnectedFaqProps> = ({
    className = '',
    ...faqProps
}): ReactElement => {
    const dispatch = useTypedDispatch();

    const isLoading = useTypedSelector(state => state.faqSlice.isLoading);
    const categories = useTypedSelector(state => state.faqSlice.categories);

    useClientEffect((): void => {
        dispatch(fetchFaqCategories());
    }, []);

    return (
        <Faq
            {...faqProps}
            isLoading={isLoading}
            faqCategories={categories}
            className={className}
        />
    );
};
