import { FC, ReactElement } from 'react';

import { useRefinementList } from 'react-instantsearch';

import { InstantSearchRefinementList } from '../../../containers';
import { ProductFilter } from '../../../entities/ProductFilters/ProductFilters';

interface ConnectedInstantSearchRefinementListProps extends ProductFilter {
    className?: string;
}

export const ConnectedInstantSearchRefinementList: FC<ConnectedInstantSearchRefinementListProps> = ({
    attribute,
    label,
    operator,
    className = '',
}): ReactElement => {
    const { refine, items } = useRefinementList({
        attribute,
        operator,
        sortBy: ['name:asc'],
    });

    return (
        <InstantSearchRefinementList
            attribute={attribute}
            label={label}
            items={items}
            onToggle={refine}
            className={className}
        />
    );
};
