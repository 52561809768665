import { FC, ReactElement } from 'react';

import classNames from 'classnames';

import { Icon } from '../../../../../components';
import { Button, ButtonProps, WayfindingWrapper } from '../../../../../compositions';
import { Direction } from '../../../../../entities/NavigationControls/NavigationControls';
import { WayfindingType } from '../../../../../entities/Wayfinding/Wayfinding';

import './CarouselNavigationButton.scss';

interface CarouselNavigationButtonProps extends ButtonProps {
    direction: Direction;
    className?: string;
}

export const CarouselNavigationButton: FC<CarouselNavigationButtonProps> = ({
    id,
    text,
    direction,
    className = '',
    ...buttonProps
}): ReactElement => {
    const type = direction === Direction.prev
        ? WayfindingType.left
        : WayfindingType.right;

    const icon = direction === Direction.prev
        ? 'wayfinding-left'
        : 'wayfinding-right';

    const iconClassNames = classNames('carousel-navigation-button__touch-icon', {
        [`carousel-navigation-button__touch-icon--is-${direction}`]: direction,
    });

    return (
        <WayfindingWrapper
            id={`${id}-${direction}`}
            type={type}
            className={`carousel-navigation-button ${className}`}
            iconPathClassName="carousel-navigation-button__icon-path"
        >
            <Button
                {...buttonProps}
                text={text}
                className="carousel-navigation-button__button"
                labelClassName="carousel-navigation-button__button-label"
            >
                <Icon
                    name={icon}
                    className={iconClassNames}
                    pathClassName="carousel-navigation-button__icon-path"
                />
            </Button>
        </WayfindingWrapper>
    );
};
