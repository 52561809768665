import { FC, ReactElement } from 'react';

import { BrandHeader } from '../../compositions/BrandHeader/BrandHeader';
import { Brand } from '../../entities/Brand/Brand';
import { BlockRenderer } from '..';

import './BrandRenderer.scss';

interface BrandRendererProps {
    brand?: Brand;
    isLoading?: boolean;
    className?: string;
}

export const BrandRenderer: FC<BrandRendererProps> = ({
    brand,
    isLoading,
    className = '',
}): ReactElement => (
    <div className={`brand-renderer ${className}`}>
        <BrandHeader
            isLoading={isLoading}
            brand={brand}
            className="brand-renderer__brand-header"
        />

        <BlockRenderer
            isLoading={isLoading}
            blocks={brand?.blocks}
            className="brand-renderer__block-renderer"
        />
    </div>
);
