import { Day } from '../../entities/Date/Date';
import { LinkTarget } from '../../entities/Link/Link';
import { StoreListItem, StoreWithWorkingHours } from '../../entities/Store/Store';
import trans from '../../helpers/trans';

export const MOCK_STORES: StoreListItem[] = [
    {
        title: 'Amersfoort',
        address: 'Euroweg 1, 3825 HA Amersfoort',
        isOpen: true,
        openingHours: 'Donderdag 10:00 - 18:00 uur',
        link: '/shop1',
    },
    {
        title: 'Duiven',
        address: 'Cartograaf 14, 6921 EZ Duiven',
        isOpen: true,
        openingHours: 'Donderdag 10:00 - 18:00 uur',
        link: '/shop2',
    },
    {
        title: 'Maastricht',
        address: 'Sphinxcour 5, 6211 XZ Maastricht',
        isOpen: false,
        openingHours: 'Vrijdag 10:00 - 18:00 uur',
        link: '/shop3',
    },
];

export const MOCK_STORES_WITH_OPENING_HOURS: StoreWithWorkingHours[] = [
    {
        id: 1,
        name: 'Zaandam',
        workingHours: [
            {
                day: Day.sunday, from: '12:00', to: '20:00', closed: false,
            },
            {
                day: Day.monday, from: '08:00', to: '16:00', closed: false,
            },
            {
                day: Day.tuesday, from: '08:00', to: '16:00', closed: false,
            },
            { day: Day.wednesday, closed: true }, // Closed on Wednesday
            {
                day: Day.thursday, from: '08:00', to: '16:00', closed: false,
            },
            {
                day: Day.friday, from: '08:00', to: '16:00', closed: false,
            },
            {
                day: Day.sunday, from: '10:00', to: '20:00', closed: false,
            },
        ],
        link: {
            href: 'https://www.google.com/maps/search/loods+5+zaandam/',
            label: trans('containers.searchPopup.route'),
            target: LinkTarget.blank,
        },
    },
    {
        id: 2,
        name: 'Garage Zaandam',
        workingHours: [
            {
                day: Day.sunday, from: '12:00', to: '22:00', closed: false,
            },
            {
                day: Day.monday, from: '08:00', to: '17:00', closed: false,
            },
            {
                day: Day.tuesday, from: '08:00', to: '17:00', closed: false,
            },
            {
                day: Day.wednesday, from: '08:00', to: '17:00', closed: false,
            },
            {
                day: Day.thursday, from: '08:00', to: '17:00', closed: false,
            },
            {
                day: Day.friday, from: '08:00', to: '17:00', closed: false,
            },
            {
                day: Day.saturday, from: '10:00', to: '22:00', closed: false,
            },
        ],
        link: {
            href: 'https://www.google.com/maps/search/loods+5+garage+zaandam/',
            label: trans('containers.searchPopup.route'),
            target: LinkTarget.blank,
        },
    },
    {
        id: 3,
        name: 'Amersfoort',
        workingHours: [
            {
                day: Day.sunday, from: '12:00', to: '22:00', closed: false,
            },
            {
                day: Day.monday, from: '08:00', to: '17:00', closed: false,
            },
            {
                day: Day.tuesday, from: '08:00', to: '17:00', closed: false,
            },
            {
                day: Day.wednesday, from: '08:00', to: '17:00', closed: false,
            },
            {
                day: Day.thursday, from: '08:00', to: '17:00', closed: false,
            },
            {
                day: Day.friday, from: '08:00', to: '17:00', closed: false,
            },
            {
                day: Day.saturday, from: '10:00', to: '22:00', closed: false,
            },
        ],
        link: {
            href: 'https://www.google.com/maps/search/loods+5+amersfoort/',
            label: trans('containers.searchPopup.route'),
            target: LinkTarget.blank,
        },
    },
];
