import { FC, ReactElement } from 'react';

import { useLocation } from 'react-router-dom';

import { BlockRenderer } from '../../../containers';
import { useClientEffect } from '../../../hooks';
import { NotFoundPage } from '../../../pages';
import { fetchCustomPage } from '../../../redux/customPage/customPageActions';
import { useTypedDispatch, useTypedSelector } from '../../../redux/store';

interface ConnectedCustomPageRendererProps {
    className?: string;
}

export const ConnectedCustomPageRenderer: FC<ConnectedCustomPageRendererProps> = ({
    className = '',
}): ReactElement => {
    const { pathname } = useLocation();

    const dispatch = useTypedDispatch();

    const hasFetched = useTypedSelector(state => state.customPageSlice.hasFetched);
    const isLoading = useTypedSelector(state => state.customPageSlice.isLoading);
    const pageData = useTypedSelector(state => state.customPageSlice.pageData);

    useClientEffect((): void => {
        dispatch(fetchCustomPage(pathname));
    }, [pathname]);

    if (hasFetched && !pageData) {
        return (
            <NotFoundPage />
        );
    }

    return (
        <BlockRenderer
            isLoading={isLoading}
            blocks={pageData?.blocks || []}
            className={className}
        />
    );
};
