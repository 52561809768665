import {
    DateIsoString,
    DateResource,
    Day,
    DayResource,
    defaultDateIsoString,
} from './Date';

export const transformToDateIsoString = (resource: DateResource): DateIsoString => {
    // @ts-ignore
    const isValid = new Date(resource) !== 'Invalid Date' && !Number.isNaN(new Date(resource));

    return isValid
        ? new Date(resource).toISOString()
        : defaultDateIsoString;
};

export const transformToDay = (resource: DayResource): Day => {
    const days: Record<DayResource, Day> = {
        [DayResource.monday]: Day.monday,
        [DayResource.tuesday]: Day.tuesday,
        [DayResource.wednesday]: Day.wednesday,
        [DayResource.thursday]: Day.thursday,
        [DayResource.friday]: Day.friday,
        [DayResource.saturday]: Day.saturday,
        [DayResource.sunday]: Day.sunday,
    };

    return days[resource];
};
