import { FC, ReactElement } from 'react';

import { FaqItemInterface } from '../../../../entities/@blocks/Faq/Faq';
import { Accordion } from '../../..';

import './FaqItem.scss';

interface FaqItemProps extends FaqItemInterface {
    className?: string;
    accordionClassName?: string;
}

export const FaqItem: FC<FaqItemProps> = ({
    category,
    question,
    answer,
    className = '',
    accordionClassName = '',
}): ReactElement => (
    <li className={`faq-item ${className}`}>
        <Accordion
            name={category}
            header={question}
            htmlContent={answer}
            className={`faq-item__accordion ${accordionClassName}`}
            headingClassName="faq-item__summary"
            contentClassName="faq-item__content"
        />
    </li>
);
