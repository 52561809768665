import { FC, ReactElement } from 'react';

import { Link } from 'react-router-dom';

import { Picture } from '../../../../../components';
import { BrandLogo } from '../../../../../entities/@blocks/BrandCarousel/BrandCarousel';
import { LinkTarget } from '../../../../../entities/Link/Link';
import { AppRoute, appRoutes } from '../../../../../entities/Routing/Routing';
import { replaceUrlParamKeysWithValues } from '../../../../../helpers/url';
import { useTrans } from '../../../../../hooks';

import './BrandCarouselItems.scss';

interface BrandCarouselItemsProps {
    logos: BrandLogo[];
    className?: string;
}

export const BrandCarouselItems: FC<BrandCarouselItemsProps> = ({
    logos,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    const brandDetailPath = trans(appRoutes[AppRoute.brandDetail].path);

    return (
        <div className={`brand-carousel-items ${className}`}>
            {logos.map(logo => (
                <Link
                    key={logo.id}
                    to={replaceUrlParamKeysWithValues(brandDetailPath, { slug: logo.slug })}
                    target={LinkTarget.self}
                    title={logo.name}
                    className="brand-carousel-items__link"
                >
                    <Picture
                        src={logo.image.src}
                        alt={trans('containers.brandCarousel.logoAlt', { name: logo.name })}
                        className="brand-carousel-items__picture"
                    />
                </Link>
            ))}
        </div>
    );
};
