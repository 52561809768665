import {
    FC,
    FormEvent,
    ReactElement,
    useState,
} from 'react';

import { ErrorLabel } from '../../../components';
import { IconButton, TextInput } from '../../../compositions';
import { FormProps } from '../../../entities/@forms/Form/Form';
import { ServiceFormData } from '../../../entities/@forms/ServiceForm/ServiceForm';
import { useTrans } from '../../../hooks';

import './ServiceForm.scss';

interface ServiceFormProps extends FormProps<ServiceFormData> {
    className?: string;
}

export const ServiceForm: FC<ServiceFormProps> = ({
    isLoading,
    error,
    onSubmit,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    const [question, setQuestion] = useState<string>('');

    const handleSubmit = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
        event.preventDefault();

        onSubmit({ question });

        setQuestion('');
    };

    return (
        <form onSubmit={handleSubmit} className={`service-form ${className}`}>
            <div className="service-form__input-wrapper">
                <TextInput
                    hasUnderline
                    hideLabel
                    type="search"
                    label={trans('containers.serviceForm.search.label')}
                    placeholder={trans('containers.serviceForm.search.placeholder')}
                    value={question}
                    disabled={isLoading}
                    onChange={setQuestion}
                    className="service-form__input"
                    fieldClassName="service-form__field"
                />

                <IconButton
                    hideLabel
                    type="submit"
                    icon="search"
                    text={trans('containers.serviceForm.submit')}
                    disabled={!question}
                    className="service-form__submit-button"
                />
            </div>

            {error && (
                <ErrorLabel
                    text={error}
                    className="login-form__error-label"
                />
            )}
        </form>
    );
};
