import { FC, ReactElement } from 'react';

import { Page } from '../../../components';
import { ConnectedBlockRenderer, ConnectedPageHelmet } from '../../../connectors';
import { AppRoute } from '../../../entities/Routing/Routing';

import './CustomerServiceAccountPage.scss';

export const CustomerServiceAccountPage: FC = (): ReactElement => (
    <Page className="customer-service-account-page">
        <ConnectedPageHelmet appRoute={AppRoute.customerServiceAccount} />
        <ConnectedBlockRenderer appRoute={AppRoute.customerServiceAccount} />
    </Page>
);
