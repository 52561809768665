import { FC, ReactElement, useMemo } from 'react';

import { Route, Routes } from 'react-router-dom';

import { useLocale } from './context';
import { AppRoute } from './entities/Routing/Routing';
import { useTrans } from './hooks';
import {
    AccountPage,
    BlogOverviewPage,
    BlogPostPage,
    BrandDetailPage,
    CartPage,
    CompanyJoinUsPage,
    CompanyMediaPage,
    CompanyStoryPage,
    CompanySustainabilityPage,
    ContentBlocksPage,
    CustomerServiceAccountPage,
    CustomerServiceDeliveryPage,
    CustomerServiceFaqPage,
    CustomerServiceOrderingPage,
    CustomerServicePage,
    CustomerServiceReturnsPage,
    CustomerServiceTermsPage,
    CustomPage,
    DepartmentDetailPage,
    ForgotPasswordPage,
    GuestWishlistPage,
    HomePage,
    HomeWithInteractiveProductHeaderPage,
    IndexPage,
    LegalCookiesPage,
    LegalDisclaimerPage,
    LegalPrivacyPage,
    LoginPage,
    NotFoundPage,
    ProductCategoryPage,
    ProductDetailPage,
    RegistrationPage,
    SearchPage,
    StoreDetailPage,
    StoresDepartmentsPage,
    StoresOverviewPage,
    StoresRestaurantPage,
    StyleguidePage,
} from './pages';

export interface RouteData<T = string> {
    key: T;
    label: string;
    path: string;
}

export type RouteParams = Record<string, string | undefined>;

const routes = [
    // Account
    {
        key: AppRoute.account,
        element: <AccountPage />,
        children: (
            <Route path=":tab" element={<AccountPage />}>
                <Route path=":id" element={<AccountPage />} />
            </Route>
        ),
    },
    // Blog
    { key: AppRoute.blogOverview, element: <BlogOverviewPage /> },
    { key: AppRoute.blogOverviewCategory, element: <BlogOverviewPage /> },
    { key: AppRoute.blogPost, element: <BlogPostPage /> },
    // Content
    { key: AppRoute.companyJoinUs, element: <CompanyJoinUsPage /> },
    { key: AppRoute.companyMedia, element: <CompanyMediaPage /> },
    { key: AppRoute.companyStory, element: <CompanyStoryPage /> },
    { key: AppRoute.companySustainability, element: <CompanySustainabilityPage /> },
    { key: AppRoute.customerService, element: <CustomerServicePage /> },
    { key: AppRoute.customerServiceAccount, element: <CustomerServiceAccountPage /> },
    { key: AppRoute.customerServiceDelivery, element: <CustomerServiceDeliveryPage /> },
    { key: AppRoute.customerServiceFaq, element: <CustomerServiceFaqPage /> },
    { key: AppRoute.customerServiceOrdering, element: <CustomerServiceOrderingPage /> },
    { key: AppRoute.customerServiceReturns, element: <CustomerServiceReturnsPage /> },
    { key: AppRoute.customerServiceTerms, element: <CustomerServiceTermsPage /> },
    { key: AppRoute.legalCookies, element: <LegalCookiesPage /> },
    { key: AppRoute.legalDisclaimer, element: <LegalDisclaimerPage /> },
    { key: AppRoute.legalPrivacy, element: <LegalPrivacyPage /> },
    { key: AppRoute.storesDepartments, element: <StoresDepartmentsPage /> },
    { key: AppRoute.storesOverview, element: <StoresOverviewPage /> },
    { key: AppRoute.storesRestaurant, element: <StoresRestaurantPage /> },
    // Other
    { key: AppRoute.brandDetail, element: <BrandDetailPage /> },
    { key: AppRoute.cart, element: <CartPage /> },
    { key: AppRoute.contentBlocks, element: <ContentBlocksPage /> },
    { key: AppRoute.custom, element: <CustomPage /> },
    { key: AppRoute.departmentDetail, element: <DepartmentDetailPage /> },
    { key: AppRoute.forgotPassword, element: <ForgotPasswordPage /> },
    { key: AppRoute.home, element: <HomePage /> },
    { key: AppRoute.homeWithInteractiveProductHeader, element: <HomeWithInteractiveProductHeaderPage /> },
    { key: AppRoute.index, element: <IndexPage /> },
    { key: AppRoute.login, element: <LoginPage /> },
    { key: AppRoute.notFound, element: <NotFoundPage /> },
    { key: AppRoute.productCategory, element: <ProductCategoryPage /> },
    { key: AppRoute.productDetail, element: <ProductDetailPage /> },
    { key: AppRoute.registration, element: <RegistrationPage /> },
    { key: AppRoute.search, element: <SearchPage /> },
    { key: AppRoute.storeDetail, element: <StoreDetailPage /> },
    { key: AppRoute.styleguide, element: <StyleguidePage /> },
    { key: AppRoute.wishlist, element: <GuestWishlistPage /> },
];

export const LocalizedRoutes: FC = (): ReactElement => {
    const { language } = useLocale();
    const trans = useTrans();

    // Get route paths and labels from translations, based on active language
    const localizedRoutes = useMemo(() => routes.map(route => {
        const routeData = route.key === AppRoute.custom
            ? { path: '*', label: '' }
            : {
                path: trans(`${route.key}.path`),
                label: trans(`${route.key}.label`),
            };

        return {
            ...route,
            ...routeData,
        };
    }), [language]);

    return (
        <Routes>
            {localizedRoutes.map(route => <Route {...route} key={route.key} />)}
        </Routes>
    );
};
