import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { BlogOverviewInterface } from '../../../entities/@blog/BlogOverview/BlogOverview';
import { AsyncReduxState, initialAsyncReduxState } from '../../defaults';

export type BlogOverviewsState = AsyncReduxState<{
    overviews: Record<string, BlogOverviewInterface>;
}>;

const initialState: BlogOverviewsState = {
    ...initialAsyncReduxState,
    overviews: {},
};

const blogOverviewsSlice = createSlice({
    name: 'blogOverviewsSlice',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): BlogOverviewsState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setIsSuccessful(state, action: PayloadAction<boolean>): BlogOverviewsState {
            return {
                ...state,
                isSuccessful: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): BlogOverviewsState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setOverviews(state, action: PayloadAction<Record<string, BlogOverviewInterface>>): BlogOverviewsState {
            return {
                ...state,
                overviews: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setIsSuccessful,
    setError,
    setOverviews,
} = blogOverviewsSlice.actions;

export default blogOverviewsSlice;
