import {
    FC,
    ReactElement,
    useState,
} from 'react';

import { useLocalStorage } from 'react-use';

import { SearchPopup } from '../../containers';
import { PageHitResource } from '../../entities/Api/Webshop/Resource/PageHit';
import { Link, LinkTarget } from '../../entities/Link/Link';
import { transformPageHitToSearchPopupPageItem } from '../../entities/PageResult/PageResultTransformers';
import { AppRoute, appRoutes } from '../../entities/Routing/Routing';
import { SearchPopupProductItem } from '../../entities/SearchPopup/SearchPopup';
import { StoreWithWorkingHours } from '../../entities/Store/Store';
import { useTrans } from '../../hooks';
import { MOCK_STORES_WITH_OPENING_HOURS } from '../../mock/mock-data';

export const ConnectedSearchPopup: FC = (): ReactElement => {
    const trans = useTrans();

    const mockStoresWithOpeningHours: StoreWithWorkingHours[] = MOCK_STORES_WITH_OPENING_HOURS;
    const pageSuggestions: Link[] = [
        {
            href: trans(appRoutes[AppRoute.storesOverview].path),
            label: trans(appRoutes[AppRoute.storesOverview].label),
            target: LinkTarget.blank,
        },
        {
            href: trans(appRoutes[AppRoute.customerService].path),
            label: trans(appRoutes[AppRoute.customerService].label),
            target: LinkTarget.blank,
        },
        {
            href: trans(appRoutes[AppRoute.customerServiceFaq].path),
            label: trans(appRoutes[AppRoute.customerServiceFaq].label),
            target: LinkTarget.blank,
        },
    ];

    const mockPageResults: PageHitResource[] = [
        {
            id: '1',
            title: 'Bestellen & betalen',
            type: 'Klantenservice',
            slug: 'bestellen-betalen',
            short_description: 'Is je online shopper tot de nok gevuld?',
        },
        {
            id: '2',
            title: 'Winkelvoorwaarden',
            type: 'Klantenservice',
            slug: 'winkelvoorwaarden',
            short_description: 'Wanneer je winkelt bij Loods 5, doe je eigenlijk zaken met een bonte verzameling van winkels.',
        },
        {
            id: '3',
            title: 'Loods 5 Privacyverklaring',
            type: 'Nieuws',
            slug: 'loods-5-privacyverklaring',
            short_description: 'Deze Privacyverklaring is van kracht met ingang van april 2018',
        },
    ];

    const pageResults = mockPageResults.map(transformPageHitToSearchPopupPageItem);

    const [recentSearches, storeRecentSearches] = useLocalStorage<string[]>('recentSearches', []);

    const [searchTerm, setSearchTerm] = useState<string>('');
    const [productResults, setProductResults] = useState<SearchPopupProductItem[]>([]);

    const storeTermInRecentSearches = (term: string): void => {
        if (term.length < 1) {
            return;
        }

        const otherSearches = recentSearches?.filter(item => item !== term) || [];
        const cappedSearches = [term, ...otherSearches].slice(0, 5);

        storeRecentSearches(cappedSearches);
    };

    return (
        <SearchPopup
            searchTerm={searchTerm}
            productResults={productResults}
            pageResults={pageResults}
            locations={mockStoresWithOpeningHours}
            pageSuggestions={pageSuggestions}
            recentSearches={recentSearches}
            onSetStoreTermInRecentSearches={storeTermInRecentSearches}
            onSetSearchTerm={setSearchTerm}
            onSetProductResults={setProductResults}
        />
    );
};
