import { FC, ReactElement } from 'react';

import { Page } from '../../../components';
import { ConnectedBlockRenderer, ConnectedPageHelmet } from '../../../connectors';
import { AppRoute } from '../../../entities/Routing/Routing';

import './CompanyJoinUsPage.scss';

export const CompanyJoinUsPage: FC = (): ReactElement => (
    <Page className="company-join-us-page">
        <ConnectedPageHelmet appRoute={AppRoute.companyJoinUs} />
        <ConnectedBlockRenderer appRoute={AppRoute.companyJoinUs} />
    </Page>
);
