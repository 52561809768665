import { Block, BlockResource } from '../Block/Block';
import { DateResource } from '../Date/Date';
import { AppRoute } from '../Routing/Routing';
import { Seo, SeoResource } from '../Seo/Seo';
import { StrapiEntity, StrapiEntityData } from '../Strapi/Strapi';

export enum PageType {
    companyInspiration = 'Inspiratie',
    companyJoinUs = 'Deelnemen bij Loods 5',
    companyMedia = 'Media & PR',
    companyStory = 'Ons verhaal',
    companySustainability = 'Duurzaamheid',
    customerService = 'Contact',
    customerServiceAccount = 'Account',
    customerServiceDelivery = 'Bezorging levertijden',
    customerServiceFaq = 'Veelgestelde vragen',
    customerServiceOrdering = 'Bestellingen betalen',
    customerServiceReturns = 'Ruilen retouren',
    customerServiceTerms = 'Winkelvoorwaarden',
    kitchens = 'Keukens',
    legalCookies = 'Cookiebeleid',
    legalDisclaimer = 'Disclaimer',
    legalPrivacy = 'Privacy',
    storesOverview = 'Winkels',
    storesDepartments = 'Afdelingen',
    storesRestaurant = 'Restaurant',
}

export const appRoutePageTypes: Partial<Record<AppRoute, PageType>> = {
    [AppRoute.blogOverview]: PageType.companyInspiration,
    [AppRoute.companyJoinUs]: PageType.companyJoinUs,
    [AppRoute.companyMedia]: PageType.companyMedia,
    [AppRoute.companyStory]: PageType.companyStory,
    [AppRoute.companySustainability]: PageType.companySustainability,
    [AppRoute.customerService]: PageType.customerService,
    [AppRoute.customerServiceAccount]: PageType.customerServiceAccount,
    [AppRoute.customerServiceDelivery]: PageType.customerServiceDelivery,
    [AppRoute.customerServiceFaq]: PageType.customerServiceFaq,
    [AppRoute.customerServiceOrdering]: PageType.customerServiceOrdering,
    [AppRoute.customerServiceReturns]: PageType.customerServiceReturns,
    [AppRoute.customerServiceTerms]: PageType.customerServiceTerms,
    [AppRoute.legalCookies]: PageType.legalCookies,
    [AppRoute.legalDisclaimer]: PageType.legalDisclaimer,
    [AppRoute.legalPrivacy]: PageType.legalPrivacy,
    [AppRoute.storesDepartments]: PageType.storesDepartments,
    [AppRoute.storesOverview]: PageType.storesOverview,
    [AppRoute.storesRestaurant]: PageType.storesRestaurant,
};

export interface PageResource extends StrapiEntityData {
    title: string;
    description: string;
    elements: BlockResource[];
    seo?: SeoResource;
    createdAt: DateResource;
    publishedAt: DateResource;
    updatedAt: DateResource;
}

export type PageResponse = StrapiEntity<PageResource[]>;

export interface PageInterface {
    id: string;
    title: string;
    description: string;
    blocks: Block[];
    seo?: Seo;
}
