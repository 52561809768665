import { FC, ReactElement } from 'react';

import { useSearchParams } from 'react-router-dom';

import { Page } from '../../components';
import { ConnectedInstantSearchInstance, ConnectedProductList } from '../../connectors';

import './SearchPage.scss';

export const SearchPage: FC = (): ReactElement => {
    const [queryParams] = useSearchParams();
    const searchTermParam = queryParams.get('q') || '';

    return (
        <Page className="search-page">
            <ConnectedInstantSearchInstance>
                <ConnectedProductList
                    showProductsCount
                    showPagesList
                    showFilterBar
                    query={searchTermParam}
                />
            </ConnectedInstantSearchInstance>
        </Page>
    );
};
