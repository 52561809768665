import {
    FC,
    ReactElement,
    useEffect,
    useState,
} from 'react';

import { RangeRenderState } from 'instantsearch.js/es/connectors/range/connectRange';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { RangeInput } from '../../../compositions';
import { useTrans } from '../../../hooks';
import { MinMaxValue } from '../../../types';

import './InstantSearchPriceRange.scss';

interface InstantSearchPriceRangeProps {
    rangeConnector: RangeRenderState;
    className?: string;
}

export const InstantSearchPriceRange: FC<InstantSearchPriceRangeProps> = ({
    rangeConnector,
    className = '',
}): ReactElement | null => {
    const { refine, range } = rangeConnector;

    const trans = useTrans();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const priceMaxName = 'price[max]';
    const priceMinName = 'price[min]';

    const defaultRange: MinMaxValue = {
        min: range.min ?? 0,
        max: range.max ?? 100000,
    };

    const [currentValue, setCurrentValue] = useState<MinMaxValue>(range as MinMaxValue);
    const [isDirty, setIsDirty] = useState<boolean>(false);

    const getQueryString = (): MinMaxValue => {
        const rangeMin = searchParams.get(priceMinName);
        const rangeMax = searchParams.get(priceMaxName);

        return {
            min: rangeMin ? (Number(rangeMin) * 100) : currentValue.min,
            max: rangeMax ? (Number(rangeMax) * 100) : currentValue.max,
        };
    };

    const updateQueryString = () => {
        const newParams = new URLSearchParams(searchParams);
        newParams.set(priceMinName, String(currentValue.min / 100));
        newParams.set(priceMaxName, String(currentValue.max / 100));
        navigate({ search: newParams.toString() });
    };

    useEffect(() => {
        const rangeNow = getQueryString();

        if (rangeNow.min === rangeNow.max) {
            setCurrentValue(defaultRange);
            updateQueryString();
            return;
        }

        setCurrentValue(rangeNow);
    }, []);

    useEffect(() => {
        refine([currentValue.min, currentValue.max]);

        if (isDirty) {
            updateQueryString();
        }
    }, [currentValue, range]);

    const handleRangeChange = (newRangeValue: MinMaxValue): void => {
        setIsDirty(true);

        if (newRangeValue.min === newRangeValue.max) {
            return;
        }

        if (newRangeValue.min === currentValue.min) {
            return;
        }

        if (newRangeValue.max === currentValue.max) {
            return;
        }

        const min = Math.max(Math.round(newRangeValue.min * 100), range.min as number);
        const max = Math.min(Math.round(newRangeValue.max * 100), range.max as number);

        setCurrentValue({
            min,
            max,
        });
    };

    if (!range.min || !range.max) {
        return null;
    }

    return (
        <div className={`instant-search-price-range ${className}`}>
            <RangeInput
                id="instant-search-price-range-input"
                hideLabel
                label={trans('entities.productFilters.price')}
                minHandleLabel={trans('containers.instantSearchPriceRange.min')}
                maxHandleLabel={trans('containers.instantSearchPriceRange.max')}
                min={range.min / 100}
                max={range.max / 100}
                initialValue={{
                    min: currentValue.min / 100,
                    max: currentValue.max / 100,
                }}
                onChange={handleRangeChange}
            />
        </div>
    );
};
