import { generateUuid } from '../../helpers/string';
import { MOCK_PRODUCT_IMAGE_1 } from '../../mock/mock-data';
import { Order, OrderItem } from '../Api/Webshop';
import { CartInterface, CartItem } from './Cart';

export const transformToCartItems = (resource: OrderItem): CartItem => {
    const id = resource.id
        ? resource.id.toString()
        : generateUuid();

    return {
        id,
        price: resource.originalUnitPrice ?? 0,
        variantId: resource.variant,
        slug: resource.variant,
        name: resource.productName ?? '',
        mainImage: MOCK_PRODUCT_IMAGE_1, // TODO: Replace with actual image
        quantity: resource.quantity,
        totalPrice: resource.total,
    };
};

export const transformToCart = (resource: Order): CartInterface => {
    const items = resource.items
        ? resource.items.map(transformToCartItems)
        : undefined;

    return {
        id: resource.tokenValue || generateUuid(),
        items: items ?? [],
        subTotalPrice: resource.itemsTotal,
        shippingCosts: resource.shippingTotal,
        totalPrice: resource.total,
    };
};
