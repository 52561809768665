import { FC, ReactElement, useEffect } from 'react';

import { ProductCard } from '../../containers';
import { Product } from '../../entities/Product/Product';
import { addProductVariantToCart } from '../../redux/cart/cartActions';
import { useTypedDispatch, useTypedSelector } from '../../redux/store';
import { addProductVariantToWishList, getWishList, removeProductVariantFromWishList } from '../../redux/wishlist/wishlistActions';

interface ConnectedProductCardProps {
    product: Product;
    className?: string;
    pictureClassname?: string;
}

export const ConnectedProductCard: FC<ConnectedProductCardProps> = ({
    product,
    className = '',
    pictureClassname = '',
}): ReactElement => {
    const dispatch = useTypedDispatch();

    const cart = useTypedSelector(state => state.cartSlice.cart);
    const isLoading = useTypedSelector(state => state.cartSlice.isLoading);

    const wishlist = useTypedSelector(state => state.wishlistSlice.wishlist);
    const isInWishlist = wishlist.products.includes(product.variantId);

    useEffect(() => {
        dispatch(getWishList());
    }, []);

    const handleAddToCart = async (): Promise<void> => {
        await dispatch(addProductVariantToCart(product.variantId, cart.id));
    };

    const handleWishlistButtonClick = async (): Promise<void> => {
        if (isInWishlist) {
            await dispatch(removeProductVariantFromWishList(product.variantId));
            return;
        }

        await dispatch(addProductVariantToWishList(product.variantId));
    };

    return (
        <ProductCard
            product={product}
            isLoading={isLoading}
            isInWishlist={isInWishlist}
            onAddToCart={handleAddToCart}
            onClickWishlistButton={handleWishlistButtonClick}
            className={className}
            pictureClassname={pictureClassname}
        />
    );
};
