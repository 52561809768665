import { FC, ReactElement } from 'react';

import { Wrapper } from '../../components';
import { DepartmentCard } from '../../compositions';
import { DepartmentOverviewInterface } from '../../entities/@departments/DepartmentOverview/DepartmentOverview';
import { useTrans } from '../../hooks';
import { DepartmentOverviewSkeletons } from './skeletons';

import './DepartmentOverview.scss';

interface DepartmentOverviewProps extends DepartmentOverviewInterface {
    isLoading: boolean;
    className?: string;
}

export const DepartmentOverview: FC<DepartmentOverviewProps> = ({
    isLoading,
    departments,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    return (
        <section className={`department-overview ${className}`}>
            <Wrapper className="department-overview__wrapper">
                <h1 className="department-overview__title">
                    {trans('containers.departmentOverview.title')}
                </h1>

                {isLoading && (
                    <DepartmentOverviewSkeletons
                        numberOfSkeletons={6}
                        className="department-overview__skeletons"
                    />
                )}

                {!isLoading && (
                    <ul className="department-overview__list">
                        {departments.map(department => (
                            <li key={department.id} className="department-overview__list-item">
                                <DepartmentCard department={department} />
                            </li>
                        ))}
                    </ul>
                )}
            </Wrapper>
        </section>
    );
};
