import { FC, useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';

import { Breadcrumbs, Page, Wrapper } from '../../components';
import { ConnectedInstantSearchInstance, ConnectedProductList } from '../../connectors';
import { CategoryHeader } from '../../containers';
import { MeilisearchAttributes } from '../../entities/Meilisearch/Meilisearch';
import { AppRoute, appRoutes } from '../../entities/Routing/Routing';
import { getFilterQuery } from '../../helpers/meilisearch';
import { replaceUrlParamKeysWithValues } from '../../helpers/url';
import { useTrans } from '../../hooks';
import { MOCK_IMAGE_7 } from '../../mock/mock-data';

import './ProductCategoryPage.scss';

export const ProductCategoryPage: FC = () => {
    const trans = useTrans();
    const { slug = '' } = useParams();

    const [productsQuery, setProductsQuery] = useState<string>('');

    useEffect(() => {
        const query = getFilterQuery(MeilisearchAttributes.categories, slug);
        setProductsQuery(query);
    }, [slug]);

    const productCategoryPath = trans(appRoutes[AppRoute.productCategory].path);

    return (
        <Page className="product-category-page">
            <Wrapper>
                <Breadcrumbs
                    breadcrumbs={[
                        { title: 'Home', url: '/' },
                        { title: 'Verlichting', url: replaceUrlParamKeysWithValues(productCategoryPath, { slug: 'verlichting' }) },
                        { title: 'Tafel- & Bureaulampen', url: replaceUrlParamKeysWithValues(productCategoryPath, { slug }) },
                    ]}
                />
            </Wrapper>

            <CategoryHeader
                title="Tafel- & bureaulampen"
                image={MOCK_IMAGE_7}
                className="category-page__image-text-block"
            />

            <Wrapper>
                <ConnectedInstantSearchInstance>
                    <ConnectedProductList
                        showFilterBar
                        title={trans('pages.brands.shopProducts')}
                        filterQuery={productsQuery}
                    />
                </ConnectedInstantSearchInstance>
            </Wrapper>
        </Page>
    );
};
