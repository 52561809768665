import { transformToBlogPost } from '../../@blog/BlogPost/BlogPostTransformers';
import { HorizontalAlignment } from '../../Alignment/Alignment';
import { BlockType } from '../../Block/Block';
import { transformToDefaultBlock } from '../../Block/BlockTransformers';
import { HighlightedBlogPostInterface, HighlightedBlogPostLayoutResource, HighlightedBlogPostResource } from './HighlightedBlogPost';

const transformToImagePosition = (resource?: HighlightedBlogPostLayoutResource): HorizontalAlignment => {
    if (resource === HighlightedBlogPostLayoutResource.right) {
        return HorizontalAlignment.right;
    }

    return HorizontalAlignment.left;
};

export const transformToHighlightedBlogPost = (resource: HighlightedBlogPostResource): HighlightedBlogPostInterface => {
    const defaultBlock = transformToDefaultBlock(resource);

    const blogPost = resource.blog
        ? transformToBlogPost(resource.blog)
        : undefined;

    const imagePosition = transformToImagePosition(resource.layout);

    return {
        ...defaultBlock,
        type: BlockType.highlightedBlogPost,
        blogPost,
        imagePosition,
    };
};
