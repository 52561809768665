import qs from 'qs';

import { DepartmentOverviewRequestConfig } from './DepartmentOverview';

export const generateDepartmentOverviewQuery = (config: DepartmentOverviewRequestConfig): string => {
    const populate = ['image'];

    const { pagination } = config;

    return qs.stringify({
        populate,
        pagination,
    }, {
        encodeValuesOnly: true,
    });
};
