import { FC, ReactElement, useEffect } from 'react';

import {
    useClearRefinements,
    useConfigure,
    useHits,
    useRange,
    useSortBy,
} from 'react-instantsearch';
import { useSearchParams } from 'react-router-dom';

import { ProductList } from '../../containers';
import { MeilisearchAttributes } from '../../entities/Meilisearch/Meilisearch';
import { productSortingOptions } from '../../entities/ProductSorting/ProductSorting';
import { ProductSlider } from '../../entities/SliderSection/SliderSection';
import { useTrans } from '../../hooks';
import { MOCK_PRODUCTS } from '../../mock/mock-data';

interface ConnectedProductListProps {
    title?: string;
    filterQuery?: string;
    query?: string;
    showProductsCount?: boolean;
    showPagesList?: boolean;
    showFilterBar?: boolean;
    className?: string;
}

export const ConnectedProductList: FC<ConnectedProductListProps> = ({
    title,
    filterQuery,
    query,
    showProductsCount,
    showPagesList,
    showFilterBar,
    className = '',
}): ReactElement => {
    const trans = useTrans();
    const { results } = useHits();
    const productsCount = results?.nbHits || 0;
    const [searchParams, setSearchParams] = useSearchParams();

    const priceRangeConnector = useRange({
        attribute: MeilisearchAttributes.price,
        precision: 0,
    });

    const { refine: sortRefine } = useSortBy({
        items: productSortingOptions,
    });

    const { refine: configureRefine } = useConfigure({
        filters: filterQuery,
        query,
    });

    const { refine: clearRefine } = useClearRefinements();

    const mockProductSliders: ProductSlider[] = [ // TODO: Get products data from API for this blocks (L5W-98)
        {
            id: 'others-also-viewed-products',
            title: trans('pages.search.othersAlsoViewedProducts'),
            products: [...MOCK_PRODUCTS, ...MOCK_PRODUCTS, ...MOCK_PRODUCTS],
            link: {
                label: 'Bekijk meer',
                href: '/producten', // TODO: determine what this link should be (L5W-98)
            },
        },
        {
            id: 'popular-products-at-this-moment',
            title: trans('pages.search.popularAtThisMoment'),
            products: [...MOCK_PRODUCTS, ...MOCK_PRODUCTS, ...MOCK_PRODUCTS],
            link: {
                label: 'Bekijk meer',
                href: '/producten', // TODO: determine what this link should be (L5W-98)
            },
        },
    ];

    useEffect(() => {
        configureRefine({ filters: filterQuery, query });
    }, []);

    const handleResetFilters = () => {
        clearRefine();

        if (searchParams.has('q') && query) {
            setSearchParams({ q: query });
        }
    };

    return (
        <ProductList
            resultsCount={productsCount}
            title={title}
            query={query}
            priceRangeConnector={priceRangeConnector}
            sortItems={productSortingOptions}
            showFilterBar={showFilterBar}
            noProductsAvailableSliders={mockProductSliders}
            showProductsCountForSearchTerm={showProductsCount}
            showPagesList={showPagesList}
            onChangeSorting={sortRefine}
            onResetFilters={handleResetFilters}
            className={className}
        />
    );
};
