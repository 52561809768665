import { FC, ReactElement } from 'react';

import { Picture, Wrapper } from '../../components';
import { BrandHeaderInterface } from '../../entities/@blocks/BrandHeader/BrandHeader';
import { useTrans } from '../../hooks';
import { BrandHeaderSkeletons } from './skeletons';

import './BrandHeader.scss';

export interface BrandHeaderProps extends BrandHeaderInterface {
    className?: string;
    isLoading?: boolean;
}

export const BrandHeader: FC<BrandHeaderProps> = ({
    brand,
    className = '',
    isLoading,
}): ReactElement => {
    const trans = useTrans();

    return (
        <section className={`brand-header ${className}`}>
            {isLoading && (
                <BrandHeaderSkeletons />
            )}

            {!isLoading && brand && (
                <Wrapper className="brand-header__wrapper">
                    <h1 className="brand-header__heading">
                        {trans('pages.brands.exploreCollectionOf', {
                            brand: brand.name,
                        })}
                    </h1>

                    <div className="brand-header__content-wrapper">
                        <Picture
                            src={brand.image.src}
                            className="brand-header__logo"
                        />

                        <p className="brand-header__description">
                            {brand.description}
                        </p>
                    </div>
                </Wrapper>
            )}
        </section>
    );
};
