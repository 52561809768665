import {
    CSSProperties,
    FC,
    ReactElement,
    TransitionEvent,
    useEffect,
    useMemo,
    useState,
} from 'react';

import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { SwipeDetector, Wrapper } from '../../../../components';
import { IconButton, LinkIconButton } from '../../../../compositions';
import { HorizontalAlignment } from '../../../../entities/Alignment/Alignment';
import { Navigation, NavigationTransitionState } from '../../../../entities/Navigation/Navigation';
import { resetElementScroll } from '../../../../helpers/scroll';
import { useTimeout, useTrans } from '../../../../hooks';
import { usePreferReducedMotion } from '../../../../hooks/usePrefersReducedMotion';

import './MobileNavigation.scss';

interface MobileNavigationProps {
    isLoading: boolean;
    isOpen: boolean;
    shouldNavigateBack: boolean;
    navigation?: Navigation;
    activeNavigation?: Navigation;
    onNavigationToggle: () => void;
    onNavigationChange: (navigation?: Navigation) => void;
    onBackTransitionMidpoint: () => void;
    onTransitionEnd: () => void;
    className?: string;
}

export const MobileNavigation: FC<MobileNavigationProps> = ({
    isLoading,
    isOpen,
    shouldNavigateBack,
    navigation,
    activeNavigation,
    onNavigationToggle,
    onNavigationChange,
    onBackTransitionMidpoint,
    onTransitionEnd,
    className = '',
}): ReactElement => {
    const trans = useTrans();
    const userPrefersReducedMotion = usePreferReducedMotion();

    const [navigationToBe, setNavigationToBe] = useState<Navigation>();
    const [transitionState, setTransitionState] = useState<NavigationTransitionState>(NavigationTransitionState.idle);

    const transitionDuration = userPrefersReducedMotion ? 0 : 300;

    const navigationLabel = activeNavigation?.parent?.label || trans('containers.navigation.main');

    const isDeepestNavigation = useMemo((): boolean => {
        if (!activeNavigation) {
            return false;
        }

        // Check if every item of every group has no sub navigation
        return activeNavigation.groups.every(group => (
            group.items.every(item => !item.subNavigation)
        ));
    }, [activeNavigation]);

    const handleGoBackwards = (): void => {
        if (navigation && navigation.id !== activeNavigation?.id) {
            setTransitionState(NavigationTransitionState.goingBackwards);
        }
    };

    const handleDrawerTransitionEnd = (event: TransitionEvent<HTMLDivElement>): void => {
        if (!isOpen) {
            setTransitionState(NavigationTransitionState.idle);
            onNavigationChange(navigation);

            resetElementScroll<HTMLDivElement>(event.currentTarget);
        }
    };

    useEffect((): void => {
        if (shouldNavigateBack) {
            handleGoBackwards();
        }
    }, [shouldNavigateBack]);

    // Code to run halfway through navigation transition, when everything is invisible
    useTimeout((): void => {
        if (transitionState === NavigationTransitionState.idle) {
            return;
        }

        if (transitionState === NavigationTransitionState.goingBackwards) {
            onBackTransitionMidpoint();
            return;
        }

        if (transitionState === NavigationTransitionState.goingForwards && navigationToBe) {
            onNavigationChange(navigationToBe);
            setNavigationToBe(undefined);
        }
    }, transitionDuration / 2, [transitionState]);

    // Code to run if animation has completed
    useTimeout((): void => {
        setTransitionState(NavigationTransitionState.idle);
        onTransitionEnd();
    }, transitionDuration, [transitionState]);

    const drawerClassNames = classNames('mobile-navigation__drawer', {
        'mobile-navigation__drawer--is-open': isOpen,
    });

    const navigationClassNames = classNames('mobile-navigation__navigation', [
        `mobile-navigation__navigation--is-${transitionState}`,
    ]);

    const cssVariables = {
        '--navigation-transition-duration': `${transitionDuration}ms`,
    } as CSSProperties;

    return (
        <div style={cssVariables} className={`mobile-navigation ${className}`}>
            <IconButton
                hideLabel
                icon={isOpen ? 'cross' : 'hamburger'}
                text={trans(`containers.navigation.toggleButton.${isOpen ? 'close' : 'open'}`)}
                disabled={isLoading}
                onClick={onNavigationToggle}
                className="mobile-navigation__toggle-button"
            />

            <div
                // @ts-ignore
                inert={!isOpen ? 'true' : undefined}
                onTransitionEnd={handleDrawerTransitionEnd}
                className={drawerClassNames}
            >
                <SwipeDetector threshold={100} onSwipeRight={handleGoBackwards}>
                    <nav aria-label={navigationLabel} className={navigationClassNames}>
                        {activeNavigation && (
                            <Wrapper className="mobile-navigation__contents">
                                {activeNavigation.groups.map(group => (
                                    <ul key={group.id} className="mobile-navigation__group">
                                        {group.items.map(item => {
                                            const handleNavigationClick = (): void => {
                                                if (item.subNavigation) {
                                                    setNavigationToBe(item.subNavigation);
                                                    setTransitionState(NavigationTransitionState.goingForwards);
                                                }
                                            };

                                            if (isDeepestNavigation) {
                                                return (
                                                    <li key={item.id} className="mobile-navigation__item">
                                                        <Link
                                                            to={item.href}
                                                            onClick={onNavigationToggle}
                                                            className="mobile-navigation__deepest-link"
                                                        >
                                                            {item.label}
                                                        </Link>
                                                    </li>
                                                );
                                            }

                                            return (
                                                <li key={item.id} className="mobile-navigation__item">
                                                    {item.subNavigation ? (
                                                        <IconButton
                                                            icon="chevron-right"
                                                            iconPos={HorizontalAlignment.right}
                                                            text={item.label}
                                                            onClick={handleNavigationClick}
                                                            className="mobile-navigation__item-button"
                                                            labelClassName="mobile-navigation__item-button-label"
                                                        />
                                                    ) : (
                                                        <LinkIconButton
                                                            to={item.href}
                                                            icon="chevron-right"
                                                            iconPos={HorizontalAlignment.right}
                                                            text={item.label}
                                                            onClick={onNavigationToggle}
                                                            className="mobile-navigation__item-button"
                                                            labelClassName="mobile-navigation__item-button-label"
                                                        />
                                                    )}
                                                </li>
                                            );
                                        })}
                                    </ul>
                                ))}

                                {activeNavigation.parent && (
                                    <LinkIconButton
                                        to={activeNavigation.parent.href}
                                        icon="arrow-short-right"
                                        iconPos={HorizontalAlignment.right}
                                        text={trans('containers.navigation.visitParent')}
                                        onClick={onNavigationToggle}
                                        className="mobile-navigation__parent-link-button"
                                        iconClassName="mobile-navigation__parent-link-icon"
                                    />
                                )}
                            </Wrapper>
                        )}
                    </nav>
                </SwipeDetector>
            </div>
        </div>
    );
};
