import { FC, ReactElement } from 'react';

import classNames from 'classnames';
import { RangeRenderState } from 'instantsearch.js/es/connectors/range/connectRange';
import { SortByItem } from 'instantsearch.js/es/connectors/sort-by/connectSortBy';
import { useToggle } from 'react-use';

import { Wrapper } from '../../../components';
import {
    Accordion,
    Button,
    IconButton,
    SortInput,
} from '../../../compositions';
import { ConnectedInstantSearchRefinementList } from '../../../connectors';
import { HorizontalAlignment } from '../../../entities/Alignment/Alignment';
import { productFilters, ProductFilterType } from '../../../entities/ProductFilters/ProductFilters';
import { useDeviceWidth, useTrans } from '../../../hooks';
import { InstantSearchPriceRange } from '../InstantSearchPriceRange/InstantSearchPriceRange';

import './InstantSearchProductFilterBar.scss';

interface InstantSearchProductFilterBarProps {
    priceRangeConnector: RangeRenderState;
    resultsCount: number;
    sortItems: SortByItem[];
    onChangeSorting: (option: string) => void;
    className?: string;
}

export const InstantSearchProductFilterBar: FC<InstantSearchProductFilterBarProps> = ({
    priceRangeConnector,
    resultsCount,
    sortItems,
    onChangeSorting,
    className = '',
}): ReactElement => {
    const trans = useTrans();
    const { isMobile } = useDeviceWidth();

    const [filterSidebarIsActive, toggleFilterSidebarIsActive] = useToggle(false);

    const sidebarClassNames = classNames('instant-search-product-filter-bar__sidebar', {
        'instant-search-product-filter-bar__sidebar--is-active': filterSidebarIsActive,
    });

    return (
        <Wrapper className={`instant-search-product-filter-bar ${className}`}>
            <div className="instant-search-product-filter-bar__wrapper">
                <div className="instant-search-product-filter-bar__filter-button-wrapper">
                    <IconButton
                        isSmall={!isMobile}
                        icon="filter"
                        text={trans('containers.instantSearchProductFilterBar.allFilters')}
                        iconPos={HorizontalAlignment.left}
                        onClick={toggleFilterSidebarIsActive}
                        className="instant-search-product-filter-bar__filter-button"
                    />
                </div>

                <p className="instant-search-product-filter-bar__products-count">
                    {trans('containers.instantSearchProductFilterBar.countProducts', { count: resultsCount })}
                </p>

                <SortInput
                    name="productSortingSelect"
                    options={sortItems}
                    label={trans('containers.instantSearchProductFilterBar.sortingLabel')}
                    placeholder={sortItems[0].label}
                    onChange={onChangeSorting}
                />
            </div>

            <aside className={sidebarClassNames}>
                <h6 className="instant-search-product-filter-bar__heading">
                    {trans('containers.instantSearchProductFilterBar.allFilters')}
                </h6>

                {productFilters.map(filterItem => (
                    <Accordion
                        hasBorder
                        key={filterItem.attribute}
                        header={filterItem.label}
                        className="instant-search-product-filter-bar__accordion"
                        headingClassName="instant-search-product-filter-bar__accordion-heading"
                    >
                        {filterItem.type === ProductFilterType.refinementList && (
                            <ConnectedInstantSearchRefinementList
                                attribute={filterItem.attribute}
                                label={filterItem.label}
                                operator={filterItem.operator}
                            />
                        )}

                        {priceRangeConnector.canRefine && filterItem.type === ProductFilterType.priceRange && (
                            <InstantSearchPriceRange rangeConnector={priceRangeConnector} />
                        )}
                    </Accordion>
                ))}

                <Button
                    isSmall
                    text={trans('containers.instantSearchProductFilterBar.results', { count: resultsCount })}
                    onClick={toggleFilterSidebarIsActive}
                    className="instant-search-product-filter-bar__results-button"
                />
            </aside>
        </Wrapper>
    );
};
