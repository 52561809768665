import { FC, ReactElement } from 'react';

import { useTrans } from '../../hooks';

import './ProductsCountForSearchTerm.scss';

interface ProductsCountForSearchTermProps {
    resultsCount: number;
    query?: string;
    className?: string;
}

export const ProductsCountForSearchTerm: FC<ProductsCountForSearchTermProps> = ({
    resultsCount,
    query,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    return (
        <div className={`products-count-for-search-term ${className}`}>
            <p className="products-count-for-search-term__text">
                {query && query.length && (
                    trans('containers.instantSearchProductsCount.searchResults', { count: resultsCount, query })
                )}
            </p>
        </div>
    );
};
