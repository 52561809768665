import { FC, ReactElement } from 'react';

import { Page, Wrapper } from '../../components';
import { LinkButton } from '../../compositions';
import { ConnectedGuestWishlistItems } from '../../connectors';
import { useTrans } from '../../hooks';

import './GuestWishlistPage.scss';

export const GuestWishlistPage: FC = (): ReactElement => {
    const trans = useTrans();

    return (
        <Page className="guest-wishlist-page">
            <Wrapper>
                <div className="guest-wishlist-page__header">
                    <h1>{trans('pages.guestWishlist.title')}</h1>
                    <p>{trans('pages.guestWishlist.description')}</p>

                    <LinkButton
                        isSmall
                        text={trans('routes.login.label')}
                        to={trans('routes.login.path')}
                        className="guest-wishlist-page__login-button"
                    />
                    <div className="guest-wishlist-page__create-account">
                        <p className="guest-wishlist-page__no-account-label">
                            {trans('pages.guestWishlist.noAccount')}
                        </p>
                        <LinkButton
                            text={trans('pages.guestWishlist.createAccount')}
                            to={trans('routes.registration.path')}
                            className="guest-wishlist-page__no-account-button"
                        />
                    </div>
                </div>

                <ConnectedGuestWishlistItems />
            </Wrapper>
        </Page>
    );
};
