import {
    FC,
    ReactElement,
    useEffect,
    useRef,
    useState,
} from 'react';

import classNames from 'classnames';

import { Icon, Price } from '../../components';
import {
    Button,
    Dialog,
    DialogContent,
    LinkButton,
    ShoppingCartCount,
} from '../../compositions';
import { CartSidebarInterface } from '../../entities/Cart/Cart';
import { AppRoute, appRoutes } from '../../entities/Routing/Routing';
import { useDeviceWidth, useTrans } from '../../hooks';
import { useTypedDispatch } from '../../redux/store';
import { addPositiveToast } from '../../redux/toast/toastActions';
import { CartProduct } from './subcomponents';

import './CartSidebar.scss';

interface CartSidebarProps extends CartSidebarInterface {
    isLoading: boolean;
    onUpdateCartItem: (productId: string, quantity: number) => void;
    onUpdateCartItemIsInWishlist: (productId: string, isInWishlist: boolean) => void;
    onRemoveItemFromCart: (productId: string) => void;
    productIsAddedToCart: boolean;
    className?: string;
}

export const CartSidebar: FC<CartSidebarProps> = ({
    isLoading,
    items,
    subTotalPrice,
    uspItems,
    onUpdateCartItem,
    onUpdateCartItemIsInWishlist,
    onRemoveItemFromCart,
    productIsAddedToCart,
    className = '',
}): ReactElement => {
    const trans = useTrans();
    const dispatch = useTypedDispatch();
    const { isMobile } = useDeviceWidth();

    const dialogRef = useRef<HTMLDialogElement>(null);

    const [layoutIsMini, setLayoutIsMini] = useState(false);
    const totalItemsInCart = items.reduce((acc, item) => acc + item.quantity, 0);

    const handleOpenDialog = async (): Promise<void> => {
        if (!dialogRef.current) {
            return;
        }

        dialogRef.current.showModal();
    };

    const handleOpenMiniSideBar = async (): Promise<void> => {
        setLayoutIsMini(true);
        await handleOpenDialog();
    };

    useEffect(() => {
        if (productIsAddedToCart && isMobile) {
            dispatch(addPositiveToast({
                title: trans('containers.cartSidebar.productIsAddedToCartTitle'),
                description: trans('containers.cartSidebar.productIsAddedToCartDescription'),
            }));

            return;
        }

        if (productIsAddedToCart) {
            handleOpenMiniSideBar();
        }
    }, [productIsAddedToCart]);

    const handleCloseDialog = (): void => {
        if (!dialogRef.current) {
            return;
        }

        dialogRef.current.close();

        if (layoutIsMini) {
            setLayoutIsMini(false);
        }
    };

    const dialogClassNames = classNames('cart-sidebar__dialog', {
        'cart-sidebar__dialog--layout-mini': layoutIsMini,
    });

    return (
        <div className={`cart-sidebar ${className}`}>
            <ShoppingCartCount
                count={totalItemsInCart}
                onClick={handleOpenDialog}
            />

            <Dialog
                enableBackdropClose
                ref={dialogRef}
                hasDarkBackdrop={!layoutIsMini}
                onClose={handleCloseDialog}
                className={dialogClassNames}
            >
                <DialogContent>
                    <h6 className="cart-sidebar__heading">
                        {trans('common.cart')}
                    </h6>

                    <div className="cart-sidebar__items-wrapper">
                        {items.map(item => (
                            <CartProduct
                                {...item}
                                isLoading={isLoading}
                                key={item.id}
                                onUpdateCartItem={onUpdateCartItem}
                                onUpdateCartItemIsInWishlist={onUpdateCartItemIsInWishlist}
                                onRemoveItemFromCart={onRemoveItemFromCart}
                                contentWrapperClassName="cart-sidebar__cart-product-content-wrapper"
                            />
                        ))}

                        {items.length === 0 && (
                            <div className="cart-sidebar__no-products-wrapper">
                                <Icon name="cart" className="cart-sidebar__no-products-icon" />

                                <p className="cart-sidebar__no-products-text">
                                    {trans('containers.cart.noProductsInCart')}
                                </p>
                            </div>
                        )}
                    </div>

                    <div className="cart-sidebar__total-price-wrapper">
                        <p className="cart-sidebar__total">
                            {trans('containers.cart.total')}
                        </p>

                        <Price
                            includeCurrencySymbol
                            amount={subTotalPrice}
                            className="cart-sidebar__total-price"
                        />
                    </div>

                    <div className="cart-sidebar__buttons-wrapper">
                        <LinkButton
                            isSmall
                            text={trans('common.toCart')}
                            to={trans(appRoutes[AppRoute.cart].path)}
                            onClick={handleCloseDialog}
                            className="cart-sidebar__button"
                        />

                        {!layoutIsMini && (
                            <Button
                                isSmall
                                text={trans('common.continueShopping')}
                                onClick={handleCloseDialog} // TODO: This does not work well when after this trying to open the dialog
                                className="cart-sidebar__button cart-sidebar__button--is-outline"
                            />
                        )}
                    </div>

                    {!layoutIsMini && (
                        <ul className="cart-sidebar__usp-list">
                            {uspItems.map((item) => (
                                <li
                                    key={item.id}
                                    className="cart-sidebar__usp-item"
                                >
                                    <Icon
                                        name={item.icon}
                                        className="cart-sidebar__usp-item-icon"
                                    />

                                    {item.text}
                                </li>
                            ))}
                        </ul>
                    )}
                </DialogContent>
            </Dialog>
        </div>
    );
};
