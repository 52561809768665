import { FC, ReactElement } from 'react';

import { Skeleton, Wrapper } from '../../../../components';
import { generateIdArray } from '../../../../helpers/array';

import './BrandHeaderSkeletons.scss';

interface BrandHeaderSkeletonsProps {
    numberOfSkeletons?: number;
}

export const BrandHeaderSkeletons: FC<BrandHeaderSkeletonsProps> = ({
    numberOfSkeletons = 6,
}): ReactElement => {
    const brandHeaderSkeletons = generateIdArray(numberOfSkeletons);

    return (
        <div>
            <Wrapper>
                <Skeleton className="brand-header-skeletons__title" />

                <div className="brand-header-skeletons__content">
                    <Skeleton className="brand-header-skeletons__image" />

                    <ul className="brand-header-skeletons__description">
                        {brandHeaderSkeletons.map(skeleton => (
                            <li key={skeleton} className="brand-header-skeletons__item">
                                <Skeleton className="brand-header-skeletons__description-text" />
                            </li>
                        ))}
                    </ul>
                </div>
            </Wrapper>
        </div>
    );
};
