import { FC, ReactElement } from 'react';

import { Navigation } from '../../entities/Navigation/Navigation';
import { useDeviceWidth } from '../../hooks';
import { DesktopMenu, MobileMenu } from './subcomponents';

import './Menu.scss';

interface MenuProps {
    isLoading: boolean;
    hasActiveSubNavigation: boolean;
    navigation?: Navigation;
    activeNavigation?: Navigation;
    onNavigationChange: (navigation?: Navigation) => void;
    onBackButtonClick: () => void;
    className?: string;
}

export const Menu: FC<MenuProps> = ({
    isLoading,
    hasActiveSubNavigation,
    navigation,
    activeNavigation,
    onNavigationChange,
    onBackButtonClick,
    className = '',
}): ReactElement => {
    const { isMobile } = useDeviceWidth();

    return (
        <section className={`menu ${className}`}>
            {isMobile && (
                <MobileMenu
                    isLoading={isLoading}
                    hasActiveSubNavigation={hasActiveSubNavigation}
                    navigation={navigation}
                    activeNavigation={activeNavigation}
                    onNavigationChange={onNavigationChange}
                    onBackButtonClick={onBackButtonClick}
                />
            )}

            {!isMobile && (
                <DesktopMenu />
            )}
        </section>
    );
};
