import { FC, ReactElement } from 'react';

import { Pagination } from '../../../compositions';

import './InstantSearchPagination.scss';

interface InstantSearchPaginationProps {
    totalPages: number;
    currentPage: number;
    onChange: (page: number) => void;
    className?: string;
}

export const InstantSearchPagination: FC<InstantSearchPaginationProps> = ({
    totalPages,
    currentPage,
    onChange,
    className = '',
}): ReactElement => {
    const handleOnChange = (page: number): void => {
        onChange(page - 1);
    };

    return (
        <Pagination
            numberOfPages={totalPages}
            currentPage={currentPage}
            onChange={handleOnChange}
            className={`instant-search-pagination ${className}`}
        />
    );
};
