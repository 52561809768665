import { dateIsInRange } from '../../helpers/date';
import { SchedulingResource } from './Scheduling';

export const transformSchedulingToPublishState = (resource: SchedulingResource): boolean => {
    const publishDate = resource.PublicationDate && new Date(resource.PublicationDate);
    const expirationDate = resource.expirationDate && new Date(resource.expirationDate);

    return dateIsInRange({
        start: publishDate,
        end: expirationDate,
    });
};
