import { transformToBlock } from '../Block/BlockTransformers';
import { transformToSeo } from '../Seo/SeoTransformers';
import { PageInterface, PageResource } from './Page';

export const transformToPage = (resource: PageResource): PageInterface => {
    const blocks = resource.elements
        ? resource.elements.map(transformToBlock)
        : [];

    const publishedBlocks = blocks.filter(block => block.isPublished);

    const seo = resource.seo
        ? transformToSeo(resource.seo)
        : undefined;

    return {
        id: resource.documentId,
        title: resource.title || '',
        description: resource.description || '',
        blocks: publishedBlocks,
        seo,
    };
};
