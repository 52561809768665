import { FC, ReactElement } from 'react';

import { PageResult } from '../../../entities/PageResult/PageResult';
import { InstantSearchPageHit } from '../InstantSearchPageHit/InstantSearchPageHit';

import './InstantSearchPagesList.scss';

interface InstantSearchPagesListProps {
    pages: PageResult[];
    className?: string;
}

export const InstantSearchPagesList: FC<InstantSearchPagesListProps> = ({
    pages,
    className = '',
}): ReactElement => (
    <ul className={`instant-search-pages-list ${className}`}>
        {pages.map(page => (
            <li key={page.id}>
                <InstantSearchPageHit page={page} />
            </li>
        ))}
    </ul>
);
