import qs from 'qs';

import { BlockTypeResource } from '../Block/Block';

export const generateBrandQuery = (slug: string): string => {
    const filters = {
        slug: {
            $eq: slug,
        },
    };

    const populate = {
        logo: {
            fields: ['*'],
        },
        elements: {
            on: {
                [BlockTypeResource.contentColumns]: {
                    populate: ['columns', 'columns.media'],
                },
                [BlockTypeResource.carousel]: {
                    populate: ['images'],
                },
                [BlockTypeResource.header]: {
                    populate: ['autoplayMedia', 'autoplayMedia.media'],
                },
                [BlockTypeResource.featuredProducts]: {
                    populate: [
                        'product_selection',
                        'button',
                        'button.link',
                        'button.icon',
                    ],
                },
                [BlockTypeResource.mediaGrid]: {
                    populate: ['mediaItems', 'mediaItems.media'],
                },
            },
        },
        seo: {
            populate: ['metaImage'],
        },
    };

    return qs.stringify({
        filters,
        populate,
    }, {
        encodeValuesOnly: true,
    });
};
