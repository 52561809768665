import { FC, ReactElement, useMemo } from 'react';

import { useHits, useInstantSearch } from 'react-instantsearch';

import { InstantSearchProductResultsList } from '../../../containers';
import { ProductHitResource } from '../../../entities/Api/Webshop/Resource/ProductHit';
import { InstantSearchStatus } from '../../../entities/Meilisearch/Meilisearch';
import { Product } from '../../../entities/Product/Product';
import { transformProductHitToProduct } from '../../../entities/Product/ProductTransformers';

interface ConnectedInstantSearchProductResultsListProps {
    onResetFilters: () => void;
    className?: string;
}

export const ConnectedInstantSearchProductResultsList: FC<ConnectedInstantSearchProductResultsListProps> = ({
    onResetFilters,
    className = '',
}): ReactElement => {
    const { items } = useHits<ProductHitResource>();
    const { status } = useInstantSearch();

    const isLoading = status === InstantSearchStatus.loading;

    const products = useMemo((): Product[] => (
        items.map(transformProductHitToProduct)
    ), [items]);

    return (
        <InstantSearchProductResultsList
            products={products}
            isLoading={isLoading}
            onResetFilters={onResetFilters}
            className={className}
        />
    );
};
