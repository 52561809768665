import { FC, ReactElement } from 'react';

import classNames from 'classnames';

import { Wrapper } from '../../../components';
import { ContentColumnsInterface } from '../../../entities/@blocks/ContentColumns/ContentColumns';
import { ContentColumn } from './subcomponents';

import './ContentColumns.scss';

interface ContentColumnsProps extends ContentColumnsInterface {
    className?: string;
}

export const ContentColumns: FC<ContentColumnsProps> = ({
    title,
    columns,
    className = '',
}): ReactElement => (
    <section className={`content-columns ${className}`}>
        <Wrapper className="content-columns__wrapper">
            {title && (
                <h2 className="content-columns__title">
                    {title}
                </h2>
            )}

            <div className="content-columns__column-wrapper">
                {columns.map(column => {
                    const { paragraphHtml, image, video } = column.content;

                    const hasQuote = paragraphHtml.includes('<q') || paragraphHtml.includes('<blockquote');
                    const hasMedia = !!image || !!video;

                    const columnClassNames = classNames('content-columns__column', {
                        'content-columns__column--has-border': !hasQuote && !hasMedia,
                    });

                    return (
                        <ContentColumn
                            {...column.content}
                            key={column.id}
                            className={columnClassNames}
                        />
                    );
                })}
            </div>
        </Wrapper>
    </section>
);
