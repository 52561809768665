import { FooterNavigation } from '../../entities/Footer/Footer';
import { LinkTarget } from '../../entities/Link/Link';
import { AppRoute, appRoutes } from '../../entities/Routing/Routing';
import trans from '../../helpers/trans';
import { replaceUrlParamKeysWithValues } from '../../helpers/url';

const blogOverviewPath = trans(appRoutes[AppRoute.blogOverviewCategory].path);
const blogOverviewUrl = replaceUrlParamKeysWithValues(blogOverviewPath, {
    category: trans('entities.blogCategory.all.value'),
});

export const MOCK_FOOTER_NAVIGATION: FooterNavigation = {
    id: '1',
    list: [
        {
            id: '1',
            order: 1,
            label: 'Klantenservice',
            href: '',
            target: LinkTarget.blank,
            subNavigation: [
                {
                    id: '1',
                    order: 1,
                    label: trans(appRoutes[AppRoute.customerService].label),
                    href: trans(appRoutes[AppRoute.customerService].path),
                    subNavigation: [],
                },
                {
                    id: '2',
                    order: 2,
                    label: trans(appRoutes[AppRoute.customerServiceOrdering].label),
                    href: trans(appRoutes[AppRoute.customerServiceOrdering].path),
                    subNavigation: [],
                },
                {
                    id: '3',
                    order: 3,
                    label: trans(appRoutes[AppRoute.customerServiceDelivery].label),
                    href: trans(appRoutes[AppRoute.customerServiceDelivery].path),
                    subNavigation: [],
                },
                {
                    id: '4',
                    order: 4,
                    label: trans(appRoutes[AppRoute.customerServiceReturns].label),
                    href: trans(appRoutes[AppRoute.customerServiceReturns].path),
                    subNavigation: [],
                },
                {
                    id: '5',
                    order: 5,
                    label: trans(appRoutes[AppRoute.customerServiceTerms].label),
                    href: trans(appRoutes[AppRoute.customerServiceTerms].path),
                    subNavigation: [],
                },
                {
                    id: '6',
                    order: 6,
                    label: trans(appRoutes[AppRoute.customerServiceFaq].label),
                    href: trans(appRoutes[AppRoute.customerServiceFaq].path),
                    subNavigation: [],
                },
                {
                    id: '7',
                    order: 7,
                    label: trans(appRoutes[AppRoute.customerServiceAccount].label),
                    href: trans(appRoutes[AppRoute.customerServiceAccount].path),
                    subNavigation: [],
                },
            ],
        },
        {
            id: '2',
            order: 2,
            label: 'Over Loods 5',
            href: '',
            subNavigation: [
                {
                    id: '1',
                    order: 1,
                    label: trans(appRoutes[AppRoute.companyStory].label),
                    href: trans(appRoutes[AppRoute.companyStory].path),
                    subNavigation: [],
                },
                {
                    id: '2',
                    order: 2,
                    label: trans(appRoutes[AppRoute.companyJoinUs].label),
                    href: trans(appRoutes[AppRoute.companyJoinUs].path),
                    subNavigation: [],
                },
                {
                    id: '3',
                    order: 3,
                    label: trans(appRoutes[AppRoute.companyWorkAt].label),
                    href: trans(appRoutes[AppRoute.companyWorkAt].path),
                    target: LinkTarget.blank,
                    subNavigation: [],
                },
                {
                    id: '4',
                    order: 4,
                    label: trans(appRoutes[AppRoute.companyMedia].label),
                    href: trans(appRoutes[AppRoute.companyMedia].path),
                    subNavigation: [],
                },
                {
                    id: '5',
                    order: 5,
                    label: trans(appRoutes[AppRoute.companySustainability].label),
                    href: trans(appRoutes[AppRoute.companySustainability].path),
                    subNavigation: [],
                },
                {
                    id: '6',
                    order: 6,
                    label: trans(appRoutes[AppRoute.blogOverview].label),
                    href: blogOverviewUrl,
                    subNavigation: [],
                },
            ],
        },
        {
            id: '3',
            order: 3,
            label: 'Shoppen bij Loods 5',
            href: '',
            target: LinkTarget.blank,
            subNavigation: [
                {
                    id: '1',
                    order: 1,
                    label: trans(appRoutes[AppRoute.storesOverview].label),
                    href: trans(appRoutes[AppRoute.storesOverview].path),
                    subNavigation: [],
                },
                {
                    id: '2',
                    order: 2,
                    label: trans(appRoutes[AppRoute.storesOpeningHours].label),
                    href: trans(appRoutes[AppRoute.storesOpeningHours].path),
                    subNavigation: [],
                },
                {
                    id: '3',
                    order: 3,
                    label: trans(appRoutes[AppRoute.customerService].label),
                    href: trans(appRoutes[AppRoute.customerService].path),
                    subNavigation: [],
                },
                {
                    id: '4',
                    order: 4,
                    label: trans(appRoutes[AppRoute.storesDepartments].label),
                    href: trans(appRoutes[AppRoute.storesDepartments].path),
                    subNavigation: [],
                },
                {
                    id: '5',
                    order: 5,
                    label: trans(appRoutes[AppRoute.storesRestaurant].label),
                    href: trans(appRoutes[AppRoute.storesRestaurant].path),
                    subNavigation: [],
                },
                {
                    id: '6',
                    order: 6,
                    label: trans(appRoutes[AppRoute.storesGiftcard].label),
                    href: trans(appRoutes[AppRoute.storesGiftcard].path),
                    subNavigation: [],
                },
            ],
        },
        {
            id: '4',
            order: 4,
            label: 'Social media',
            href: '',
            target: LinkTarget.blank,
            subNavigation: [
                {
                    id: '1',
                    order: 1,
                    label: 'instagram',
                    href: '',
                    target: LinkTarget.blank,
                    subNavigation: [],
                    icon: 'instagram',
                },
                {
                    id: '2',
                    order: 2,
                    label: 'facebook',
                    href: '',
                    target: LinkTarget.blank,
                    subNavigation: [],
                    icon: 'facebook',
                },
                {
                    id: '3',
                    order: 3,
                    label: 'pinterest',
                    href: '',
                    target: LinkTarget.blank,
                    subNavigation: [],
                    icon: 'pinterest',
                },
                {
                    id: '4',
                    order: 4,
                    label: 'youtube',
                    href: '',
                    target: LinkTarget.blank,
                    subNavigation: [],
                    icon: 'youtube',
                },
                {
                    id: '5',
                    order: 5,
                    label: 'tiktok',
                    href: '',
                    target: LinkTarget.blank,
                    subNavigation: [],
                    icon: 'tiktok',
                },
                {
                    id: '6',
                    order: 6,
                    label: 'linkedin',
                    href: '',
                    target: LinkTarget.blank,
                    subNavigation: [],
                    icon: 'linkedin',
                },
            ],
        },
    ],
};
