import {
    ChangeEvent,
    FC,
    ReactElement,
    useEffect,
    useRef,
    useState,
} from 'react';

import classNames from 'classnames';

import { Input, InputProps } from '../../../components';
import { useTrans } from '../../../hooks';
import { IconButton, InputLabelProps, InputLabelWrapper } from '../..';

import './NumberInputWithControls.scss';

interface NumberInputWithControlsProps extends InputLabelProps, Omit<InputProps, 'onChange'> {
    hideControls?: boolean;
    onChange: (value: number) => void;
    buttonClassName?: string;
}

export const NumberInputWithControls: FC<NumberInputWithControlsProps> = ({
    hideControls = false,
    label,
    hideLabel,
    required,
    tabIndex,
    disabled,
    tooltip,
    value,
    min,
    max,
    error,
    onChange,
    className = '',
    buttonClassName = '',
    ...inputProps
}): ReactElement => {
    const trans = useTrans();

    const inputRef = useRef<HTMLInputElement>(null);

    const [formattedValue, setFormattedValue] = useState<string>(value?.toString() || min?.toString() || '0');

    useEffect((): void => {
        const setValue = (newValue: number): void => {
            setFormattedValue(newValue.toString());
            onChange(newValue);
        };

        if (inputRef.current) {
            setValue(+inputRef.current.value);
        }
    }, [value]);

    const setNewValue = (newValue: number): void => {
        setFormattedValue(newValue.toString());
        onChange(newValue);
    };

    const handleMinusClick = (): void => {
        if (inputRef.current) {
            inputRef.current.stepDown();

            const newValue = Number(inputRef.current.value);
            setNewValue(newValue);
        }
    };

    const handlePlusClick = (): void => {
        if (inputRef.current) {
            inputRef.current.stepUp();

            const newValue = Number(inputRef.current.value);
            setNewValue(newValue);
        }
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
        const newValue = Number(event.currentTarget.value);
        setNewValue(newValue);
    };

    const handleFocus = (event: ChangeEvent<HTMLInputElement>): void => event.currentTarget.select();

    const inputFieldClassNames = classNames('number-input-with-controls__field', {
        'number-input-with-controls__field--has-error': error,
    });

    return (
        <label aria-label={label} className={`number-input-with-controls ${className}`}>
            <InputLabelWrapper
                hideLabel={hideLabel}
                label={label}
                tooltip={tooltip}
                required={required}
                error={error}
            >
                <div className="number-input-with-controls__field-wrapper">
                    <Input
                        {...inputProps}
                        ref={inputRef}
                        hasControls
                        type="number"
                        required={required}
                        tabIndex={tabIndex}
                        disabled={disabled}
                        error={error}
                        onChange={handleChange}
                        onFocus={handleFocus}
                        value={formattedValue}
                        fieldClassName={inputFieldClassNames}
                        min={min}
                        max={max}
                    />

                    {!hideControls && (
                        <>
                            <IconButton
                                icon="minus"
                                hideLabel
                                text={trans('compositions.numberInput.stepDown')}
                                tabIndex={tabIndex}
                                disabled={disabled || value === min}
                                onClick={handleMinusClick}
                                className={`number-input-with-controls__control-button number-input-with-controls__control-button--minus ${buttonClassName}`}
                                iconClassName="number-input-with-controls__control-button-icon"
                            />

                            <IconButton
                                icon="plus"
                                hideLabel
                                text={trans('compositions.numberInput.stepUp')}
                                tabIndex={tabIndex}
                                disabled={disabled || value === max}
                                onClick={handlePlusClick}
                                className={`number-input-with-controls__control-button number-input-with-controls__control-button--plus ${buttonClassName}`}
                                iconClassName="number-input-with-controls__control-button-icon"
                            />
                        </>
                    )}
                </div>
            </InputLabelWrapper>
        </label>
    );
};
