import {
    FC,
    MouseEvent,
    ReactElement,
    useMemo,
} from 'react';

import classNames from 'classnames';
import { useSearchParams } from 'react-router-dom';

import { paginationPageParameter } from '../../../../../entities/Pagination/Pagination';
import { useTrans } from '../../../../../hooks';
import { LinkButton } from '../../../..';

import './PaginationLink.scss';

interface PaginationLinkProps {
    isActive: boolean;
    page: number;
    onClick: (page: number) => void;
    className?: string;
}

export const PaginationLink: FC<PaginationLinkProps> = ({
    isActive,
    page,
    onClick,
    className,
}): ReactElement => {
    const [searchParams] = useSearchParams();
    const trans = useTrans();

    const pageLink = useMemo((): string => {
        if (!searchParams) {
            return '';
        }

        const params = new URLSearchParams(Array.from(searchParams.entries()));

        params.set(paginationPageParameter, page.toString());

        return `?${params.toString()}`;
    }, [page]);

    const handleClick = (event: MouseEvent<HTMLAnchorElement>): void => {
        event.preventDefault();

        onClick(page);
    };

    const paginationLinkClassName = classNames('pagination-link', {
        'pagination-link--is-active': isActive,
    }, className);

    return (
        <LinkButton
            aria-label={trans('compositions.pagination.pageLink', { page })}
            text={page.toString()}
            to={pageLink}
            onClick={handleClick}
            className={paginationLinkClassName}
        />
    );
};
