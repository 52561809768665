import { FC, ReactElement } from 'react';

import { InstantSearchPagesList } from '../../../containers';
import { PageHitResource } from '../../../entities/Api/Webshop/Resource/PageHit';
import { transformPageHitToPageResult } from '../../../entities/PageResult/PageResultTransformers';

interface ConnectedInstantSearchPagesListProps {
    className?: string;
}

export const ConnectedInstantSearchPagesList: FC<ConnectedInstantSearchPagesListProps> = ({
    className = '',
}): ReactElement => {
    // TODO: Connect with Meilisearch
    const mockPageResults: PageHitResource[] = [
        {
            id: '1',
            title: 'Bestellen & betalen',
            type: 'Klantenservice',
            slug: 'bestellen-betalen',
            short_description: 'Is je online shopper tot de nok gevuld?',
        },
        {
            id: '2',
            title: 'Winkelvoorwaarden',
            type: 'Klantenservice',
            slug: 'winkelvoorwaarden',
            short_description: 'Wanneer je winkelt bij Loods 5, doe je eigenlijk zaken met een bonte verzameling van winkels.',
        },
        {
            id: '3',
            title: 'Loods 5 Privacyverklaring',
            type: 'Nieuws',
            slug: 'loods-5-privacyverklaring',
            short_description: 'Deze Privacyverklaring is van kracht met ingang van april 2018',
        },
    ];

    const pages = mockPageResults.map(transformPageHitToPageResult);

    return (
        <InstantSearchPagesList
            pages={pages}
            className={className}
        />
    );
};
