import qs from 'qs';

import trans from '../../../helpers/trans';

export const generateBlogCategoriesQuery = (): string => {
    const filters = {
        $not: {
            title: {
                $eq: trans('entities.blogCategory.all.value'),
            },
        },
    };

    return qs.stringify({
        filters,
    }, {
        encodeValuesOnly: true,
    });
};
