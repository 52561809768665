import { FC, ReactElement } from 'react';

import { Page } from '../../../components';
import { ConnectedBlockRenderer, ConnectedDepartmentOverview, ConnectedPageHelmet } from '../../../connectors';
import { AppRoute } from '../../../entities/Routing/Routing';

import './StoresDepartmentsPage.scss';

export const StoresDepartmentsPage: FC = (): ReactElement => (
    <Page className="stores-departments-page">
        <ConnectedPageHelmet appRoute={AppRoute.storesDepartments} />

        <ConnectedDepartmentOverview />

        <ConnectedBlockRenderer appRoute={AppRoute.storesDepartments} />
    </Page>
);
