import { FC, ReactElement, useState } from 'react';

import { AddressForm, AddressList, IconButton } from '../../../../compositions';
import { FormProps } from '../../../../entities/@forms/Form/Form';
import { UserAddress } from '../../../../entities/User/User';
import { useTrans } from '../../../../hooks';

import './MyAddresses.scss';

interface MyAddressesProps extends FormProps<UserAddress[]> {
    addresses: UserAddress[];
    className?: string;
}

export const MyAddresses: FC<MyAddressesProps> = ({
    addresses,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    const [isEditMode, setIsEditMode] = useState(false);

    const handleEditButton = (): void => {
        setIsEditMode(true);
    };

    const handleSavedAddresses = (isSaved: boolean): void => {
        setIsEditMode(!isSaved);
    };

    return (
        <div className={`my-addresses ${className}`}>
            <div className="my-addresses__header">
                <h6 className="my-addresses__heading">
                    {trans('pages.accountDashboard.myAddresses')}
                </h6>

                {!isEditMode && (
                    <IconButton
                        icon="edit"
                        text={trans('common.edit')}
                        type="submit"
                        onClick={handleEditButton}
                        className="my-addresses__edit-button"
                    />
                )}
            </div>

            {isEditMode && (
                <AddressForm
                    addresses={addresses}
                    onSave={handleSavedAddresses}
                />
            )}

            {!isEditMode && (
                <AddressList addresses={addresses} />
            )}
        </div>
    );
};
