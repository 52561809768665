import { FC, ReactElement } from 'react';

import { Skeleton } from '../../../../components';
import { generateIdArray } from '../../../../helpers/array';
import { randomInBetweenValue } from '../../../../helpers/number';

import './ProductCardListSkeletons.scss';

interface ProductCardListSkeletonsProps {
    numberOfSkeletons?: number;
    className?: string;
}

export const ProductCardListSkeletons: FC<ProductCardListSkeletonsProps> = ({
    numberOfSkeletons = randomInBetweenValue(4, 6),
    className = '',
}): ReactElement => {
    const productCardSkeletons = generateIdArray(numberOfSkeletons);

    return (
        <ul className={`product-card-list-skeletons ${className}`}>
            {productCardSkeletons.map(skeleton => (
                <li key={skeleton} className="product-card-list-skeletons__list-item">
                    <div className="product-card-list-skeletons__image" />

                    <div className="product-card-list-skeletons__meta-wrapper">
                        <Skeleton className="product-card-list-skeletons__meta" />
                    </div>

                    <Skeleton className="product-card-list-skeletons__title" />

                    <div className="product-card-list-skeletons__button-wrapper">
                        <Skeleton className="product-card-list-skeletons__button" />
                    </div>
                </li>
            ))}
        </ul>
    );
};
