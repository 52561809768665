/* eslint-disable max-len */
import { combineSlices } from '@reduxjs/toolkit';

import serviceSectionSlice, { ServiceSectionState } from './@blocks/serviceSection/serviceSectionSlice';
// Blog
import blogCategoriesSlice, { BlogCategoriesState } from './@blog/blogCategories/blogCategoriesSlice';
import blogOverviewsSlice, { BlogOverviewsState } from './@blog/blogOverviews/blogOverviewsSlice';
import blogPostSlice, { BlogPostState } from './@blog/blogPost/blogPostSlice';
// Departments
import departmentDetailSlice, { DepartmentDetailState } from './@departments/departmentDetail/departmentDetailSlice';
import departmentOverviewSlice, { DepartmentOverviewState } from './@departments/departmentOverview/departmentOverviewSlice';
// Forms
import newsletterFormSlice, { NewsletterFormState } from './@forms/newsletterForm/newsletterFormSlice';
// Other
import appSlice, { AppState } from './app/appSlice';
import authenticationSlice, { AuthenticationState } from './authentication/authenticationSlice';
import brandSlice, { BrandState } from './brand/brandSlice';
import cartSlice, { CartState } from './cart/cartSlice';
import changePasswordSlice, { ChangePasswordState } from './changePassword/changePasswordSlice';
import customerSlice, { CustomerState } from './customer/customerSlice';
import customPageSlice, { CustomPageState } from './customPage/customPageSlice';
import faqSlice, { FaqState } from './faq/faqSlice';
import navigationSlice, { NavigationState } from './navigation/navigationSlice';
import pagesSlice, { PagesState } from './pages/pagesSlice';
import registrationSlice, { RegistrationState } from './registration/registrationSlice';
import resetPasswordSlice, { ResetPasswordState } from './resetPassword/resetPasswordSlice';
import storeLocatorSlice, { StoreLocatorState } from './storeLocator/storeLocatorSlice';
import toastSlice, { ToastState } from './toast/toastSlice';
import wishlistSlice, { WishlistState } from './wishlist/wishlistSlice';

export interface Slices {
    // Blocks
    serviceSectionSlice: ServiceSectionState;
    // Blog
    blogCategoriesSlice: BlogCategoriesState;
    blogOverviewsSlice: BlogOverviewsState;
    blogPostSlice: BlogPostState;
    // Departments
    departmentDetailSlice: DepartmentDetailState;
    departmentOverviewSlice: DepartmentOverviewState;
    // Forms
    newsletterFormSlice: NewsletterFormState;
    // Account/customer
    authenticationSlice: AuthenticationState;
    changePasswordSlice: ChangePasswordState;
    customerSlice: CustomerState;
    // Other
    appSlice: AppState;
    cartSlice: CartState;
    customPageSlice: CustomPageState;
    brandSlice: BrandState;
    faqSlice: FaqState;
    navigationSlice: NavigationState;
    pagesSlice: PagesState;
    registrationSlice: RegistrationState;
    resetPasswordSlice: ResetPasswordState;
    storeLocatorSlice: StoreLocatorState;
    toastSlice: ToastState;
    wishlistSlice: WishlistState;
}

export default combineSlices(
    // Blocks
    serviceSectionSlice,
    // Blog
    blogCategoriesSlice,
    blogOverviewsSlice,
    blogPostSlice,
    // Departments
    departmentDetailSlice,
    departmentOverviewSlice,
    // Forms
    newsletterFormSlice,
    // Account/customer
    authenticationSlice,
    changePasswordSlice,
    customerSlice,
    registrationSlice,
    resetPasswordSlice,
    // Other
    appSlice,
    cartSlice,
    customPageSlice,
    brandSlice,
    faqSlice,
    navigationSlice,
    pagesSlice,
    storeLocatorSlice,
    toastSlice,
    wishlistSlice,
);
