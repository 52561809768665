import { ProductHitResource } from '../Api/Webshop/Resource/ProductHit';
import { transformBrandNameToBrand } from '../Brand/BrandTransformers';
import { transformInstantSearchImagesToMediaItems, transformInstantSearchImageToImage } from '../Media/MediaTransformers';
import { SearchPopupProductItem } from '../SearchPopup/SearchPopup';
import { Product } from './Product';

export const transformProductHitToProduct = (resource: ProductHitResource): Product => {
    const brand = resource.brand
        ? transformBrandNameToBrand(resource.brand)
        : undefined;

    const mainImage = resource.main_image
        ? transformInstantSearchImageToImage(resource.main_image)
        : undefined;

    const mediaItems = resource.main_image && resource.image
        ? transformInstantSearchImagesToMediaItems(resource)
        : [];

    return ({
        id: resource.id.toString(),
        slug: resource.article_slug,
        name: resource.name || '',
        description: resource.description || '',
        variantId: resource.code ?? undefined,
        brand,
        mainImage,
        mediaItems,
        colors: [],
        price: resource.price.LOODS_5.price / 100,
        variants: resource.variants || 0,
        stock: resource.stock,
        categories: resource.categories,
    });
};

export const transformProductHitToSearchPopupProductItem = (resource: ProductHitResource): SearchPopupProductItem => ({
    id: resource.id,
    name: resource.name || '',
    slug: resource.slug,
    brand: resource.brand.name,
    price: resource.price.LOODS_5.price / 100,
});
