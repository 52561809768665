import { Navigation } from '../../entities/Navigation/Navigation';

export const mockUnauthorizedNavigation: Navigation = {
    id: '1',
    groups: [
        {
            id: 'a',
            items: [
                {
                    id: '1',
                    order: 1,
                    label: 'Producten',
                    href: '/producten',
                    subNavigation: {
                        id: '2',
                        parent: {
                            label: 'Producten',
                            href: '/producten',
                        },
                        groups: [
                            {
                                id: 'b',
                                items: [
                                    {
                                        id: '2',
                                        order: 1,
                                        label: 'Meubels',
                                        href: '/producten/meubels',
                                        subNavigation: {
                                            id: '3',
                                            parent: {
                                                label: 'Meubels',
                                                href: '/producten/meubels',
                                            },
                                            groups: [
                                                {
                                                    id: 'c',
                                                    items: [
                                                        {
                                                            id: '3',
                                                            order: 1,
                                                            label: 'Banken',
                                                            href: '/producten/meubels/banken',
                                                            subNavigation: {
                                                                id: '4',
                                                                parent: {
                                                                    label: 'Banken',
                                                                    href: '/producten/meubels/banken',
                                                                },
                                                                groups: [
                                                                    {
                                                                        id: 'd',
                                                                        items: [
                                                                            {
                                                                                id: '4',
                                                                                order: 1,
                                                                                label: 'Hoekbanken',
                                                                                href: '/producten/meubels/banken/hoekbanken',
                                                                            },
                                                                            {
                                                                                id: '5',
                                                                                order: 2,
                                                                                label: '2-zitsbanken',
                                                                                href: '/producten/meubels/banken/2-zitsbanken',
                                                                            },
                                                                            {
                                                                                id: '6',
                                                                                order: 3,
                                                                                label: '3-zitsbanken',
                                                                                href: '/producten/meubels/banken/3-zitsbanken',
                                                                            },
                                                                            {
                                                                                id: '7',
                                                                                order: 4,
                                                                                label: '4-zitsbanken',
                                                                                href: '/producten/meubels/banken/4-zitsbanken',
                                                                            },
                                                                            {
                                                                                id: '8',
                                                                                order: 5,
                                                                                label: 'Modulaire banken',
                                                                                href: '/producten/meubels/banken/modulaire-banken',
                                                                            },
                                                                            {
                                                                                id: '9',
                                                                                order: 6,
                                                                                label: 'U-banken',
                                                                                href: '/producten/meubels/banken/u-banken',
                                                                            },
                                                                            {
                                                                                id: '10',
                                                                                order: 7,
                                                                                label: 'Hockers',
                                                                                href: '/producten/meubels/banken/hockers',
                                                                            },
                                                                            {
                                                                                id: '11',
                                                                                order: 8,
                                                                                label: 'Hal- & eetkamerbanken',
                                                                                href: '/producten/meubels/banken/hal-eetkamerbanken',
                                                                            },
                                                                        ],
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            id: '12',
                                                            order: 2,
                                                            label: 'Stoelen',
                                                            href: '/producten/meubels/stoelen',
                                                        },
                                                        {
                                                            id: '13',
                                                            order: 3,
                                                            label: 'Tafels',
                                                            href: '/producten/meubels/tafels',
                                                        },
                                                        {
                                                            id: '14',
                                                            order: 4,
                                                            label: 'Kasten',
                                                            href: '/producten/meubels/kasten',
                                                        },
                                                        {
                                                            id: '15',
                                                            order: 5,
                                                            label: 'Bedden',
                                                            href: '/producten/meubels/bedden',
                                                        },
                                                    ],
                                                },
                                                {
                                                    id: 'e',
                                                    items: [
                                                        {
                                                            id: '16',
                                                            order: 1,
                                                            label: 'Eigen collectie',
                                                            href: '/producten/meubels/eigen-collectie',
                                                        },
                                                    ],
                                                },
                                            ],
                                        },
                                    },
                                    {
                                        id: '17',
                                        order: 2,
                                        label: 'Verlichting',
                                        href: '/producten/verlichting',
                                    },
                                    {
                                        id: '18',
                                        order: 3,
                                        label: 'Accessoires',
                                        href: '/producten/accessoires',
                                    },
                                    {
                                        id: '19',
                                        order: 4,
                                        label: 'Wanddecoratie',
                                        href: '/producten/wanddecoratie',
                                    },
                                    {
                                        id: '20',
                                        order: 5,
                                        label: 'Koken & tafelen',
                                        href: '/producten/koken-tafelen',
                                    },
                                    {
                                        id: '21',
                                        order: 6,
                                        label: 'Slapen',
                                        href: '/producten/slapen',
                                    },
                                    {
                                        id: '22',
                                        order: 7,
                                        label: 'Huishouden',
                                        href: '/producten/huishouden',
                                    },
                                    {
                                        id: '23',
                                        order: 8,
                                        label: 'Tuin & balkom',
                                        href: '/producten/tuin-balkon',
                                    },
                                    {
                                        id: '24',
                                        order: 9,
                                        label: 'Kids',
                                        href: '/producten/kids',
                                    },
                                    {
                                        id: '25',
                                        order: 10,
                                        label: 'Cadeaukaarten',
                                        href: '/producten/cadeaukaarten',
                                    },
                                    {
                                        id: '26',
                                        order: 11,
                                        label: 'Cadeautips',
                                        href: '/producten/cadeautips',
                                    },
                                    {
                                        id: '27',
                                        order: 12,
                                        label: 'Outlet',
                                        href: '/producten/outlet',
                                    },
                                ],
                            },
                        ],
                    },
                },
                {
                    id: '28',
                    order: 2,
                    label: 'Ruimtes',
                    href: '/ruimtes',
                },
                {
                    id: '29',
                    order: 3,
                    label: 'Keukens',
                    href: '/winkels/afdelingen/keukens',
                },
                {
                    id: '30',
                    order: 4,
                    label: 'Eigen collectie',
                    href: '/producten/eigen-collectie',
                },
            ],
        },
        {
            id: 'f',
            items: [
                {
                    id: '31',
                    order: 1,
                    label: 'Inspiratie',
                    href: '/ontdek/nieuws',
                },
                {
                    id: '32',
                    order: 2,
                    label: 'Design',
                    href: '/winkels/afdelingen/design',
                },
                {
                    id: '33',
                    order: 3,
                    label: 'Merken',
                    href: '/merken',
                },
                {
                    id: '34',
                    order: 4,
                    label: 'Kunst',
                    href: '/winkels/afdelingen/kunst',
                },
            ],
        },
        {
            id: 'g',
            items: [
                {
                    id: '35',
                    order: 1,
                    label: 'Winkels',
                    href: '/winkels',
                },
                {
                    id: '36',
                    order: 2,
                    label: 'Restaurant',
                    href: '/winkels/restaurant',
                },
                {
                    id: '37',
                    order: 3,
                    label: 'Klantenservice',
                    href: '/klantenservice',
                },
            ],
        },
        {
            id: 'h',
            items: [
                {
                    id: '38',
                    order: 1,
                    label: 'Vacatures',
                    href: 'https://www.werkenbijloods5.nl',
                },
                {
                    id: '39',
                    order: 2,
                    label: 'Login',
                    href: '/account/login',
                },
            ],
        },
    ],
};


export const mockAuthorizedNavigation: Navigation = {
    id: '1',
    groups: [
        {
            id: 'a',
            items: [
                {
                    id: '1',
                    order: 1,
                    label: 'Producten',
                    href: '/producten',
                    subNavigation: {
                        id: '2',
                        parent: {
                            label: 'Producten',
                            href: '/producten',
                        },
                        groups: [
                            {
                                id: 'b',
                                items: [
                                    {
                                        id: '2',
                                        order: 1,
                                        label: 'Meubels',
                                        href: '/producten/meubels',
                                        subNavigation: {
                                            id: '3',
                                            parent: {
                                                label: 'Meubels',
                                                href: '/producten/meubels',
                                            },
                                            groups: [
                                                {
                                                    id: 'c',
                                                    items: [
                                                        {
                                                            id: '3',
                                                            order: 1,
                                                            label: 'Banken',
                                                            href: '/producten/meubels/banken',
                                                            subNavigation: {
                                                                id: '4',
                                                                parent: {
                                                                    label: 'Banken',
                                                                    href: '/producten/meubels/banken',
                                                                },
                                                                groups: [
                                                                    {
                                                                        id: 'd',
                                                                        items: [
                                                                            {
                                                                                id: '4',
                                                                                order: 1,
                                                                                label: 'Hoekbanken',
                                                                                href: '/producten/meubels/banken/hoekbanken',
                                                                            },
                                                                            {
                                                                                id: '5',
                                                                                order: 2,
                                                                                label: '2-zitsbanken',
                                                                                href: '/producten/meubels/banken/2-zitsbanken',
                                                                            },
                                                                            {
                                                                                id: '6',
                                                                                order: 3,
                                                                                label: '3-zitsbanken',
                                                                                href: '/producten/meubels/banken/3-zitsbanken',
                                                                            },
                                                                            {
                                                                                id: '7',
                                                                                order: 4,
                                                                                label: '4-zitsbanken',
                                                                                href: '/producten/meubels/banken/4-zitsbanken',
                                                                            },
                                                                            {
                                                                                id: '8',
                                                                                order: 5,
                                                                                label: 'Modulaire banken',
                                                                                href: '/producten/meubels/banken/modulaire-banken',
                                                                            },
                                                                            {
                                                                                id: '9',
                                                                                order: 6,
                                                                                label: 'U-banken',
                                                                                href: '/producten/meubels/banken/u-banken',
                                                                            },
                                                                            {
                                                                                id: '10',
                                                                                order: 7,
                                                                                label: 'Hockers',
                                                                                href: '/producten/meubels/banken/hockers',
                                                                            },
                                                                            {
                                                                                id: '11',
                                                                                order: 8,
                                                                                label: 'Hal- & eetkamerbanken',
                                                                                href: '/producten/meubels/banken/hal-eetkamerbanken',
                                                                            },
                                                                        ],
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            id: '12',
                                                            order: 2,
                                                            label: 'Stoelen',
                                                            href: '/producten/meubels/stoelen',
                                                        },
                                                        {
                                                            id: '13',
                                                            order: 3,
                                                            label: 'Tafels',
                                                            href: '/producten/meubels/tafels',
                                                        },
                                                        {
                                                            id: '14',
                                                            order: 4,
                                                            label: 'Kasten',
                                                            href: '/producten/meubels/kasten',
                                                        },
                                                        {
                                                            id: '15',
                                                            order: 5,
                                                            label: 'Bedden',
                                                            href: '/producten/meubels/bedden',
                                                        },
                                                    ],
                                                },
                                                {
                                                    id: 'e',
                                                    items: [
                                                        {
                                                            id: '16',
                                                            order: 1,
                                                            label: 'Eigen collectie',
                                                            href: '/producten/meubels/eigen-collectie',
                                                        },
                                                    ],
                                                },
                                            ],
                                        },
                                    },
                                    {
                                        id: '17',
                                        order: 2,
                                        label: 'Verlichting',
                                        href: '/producten/verlichting',
                                    },
                                    {
                                        id: '18',
                                        order: 3,
                                        label: 'Accessoires',
                                        href: '/producten/accessoires',
                                    },
                                    {
                                        id: '19',
                                        order: 4,
                                        label: 'Wanddecoratie',
                                        href: '/producten/wanddecoratie',
                                    },
                                    {
                                        id: '20',
                                        order: 5,
                                        label: 'Koken & tafelen',
                                        href: '/producten/koken-tafelen',
                                    },
                                    {
                                        id: '21',
                                        order: 6,
                                        label: 'Slapen',
                                        href: '/producten/slapen',
                                    },
                                    {
                                        id: '22',
                                        order: 7,
                                        label: 'Huishouden',
                                        href: '/producten/huishouden',
                                    },
                                    {
                                        id: '23',
                                        order: 8,
                                        label: 'Tuin & balkom',
                                        href: '/producten/tuin-balkon',
                                    },
                                    {
                                        id: '24',
                                        order: 9,
                                        label: 'Kids',
                                        href: '/producten/kids',
                                    },
                                    {
                                        id: '25',
                                        order: 10,
                                        label: 'Cadeaukaarten',
                                        href: '/producten/cadeaukaarten',
                                    },
                                    {
                                        id: '26',
                                        order: 11,
                                        label: 'Cadeautips',
                                        href: '/producten/cadeautips',
                                    },
                                    {
                                        id: '27',
                                        order: 12,
                                        label: 'Outlet',
                                        href: '/producten/outlet',
                                    },
                                ],
                            },
                        ],
                    },
                },
                {
                    id: '28',
                    order: 2,
                    label: 'Sale',
                    href: '/producten/sale',
                },
                {
                    id: '29',
                    order: 3,
                    label: 'Eigen collectie',
                    href: '/producten/eigen-collectie',
                },
                {
                    id: '30',
                    order: 4,
                    label: 'Trends',
                    href: '/producten/trends',
                },
            ],
        },
        {
            id: 'f',
            items: [
                {
                    id: '31',
                    order: 1,
                    label: 'Ruimtes',
                    href: '/ontdek/nieuws',
                },
                {
                    id: '32',
                    order: 2,
                    label: 'Merken & designers',
                    href: '/winkels/afdelingen/design',
                },
                {
                    id: '33',
                    order: 3,
                    label: 'Cadeaukaarten',
                    href: '/producten/cadeaukaarten',
                },
            ],
        },
        {
            id: 'g',
            items: [
                {
                    id: '34',
                    order: 1,
                    label: 'Winkels',
                    href: '/winkels',
                },
                {
                    id: '35',
                    order: 2,
                    label: 'Bezoek & openingstijden',
                    href: '/winkels',
                },
                {
                    id: '36',
                    order: 3,
                    label: 'Over ons',
                    href: '/over-ons',
                },
                {
                    id: '37',
                    order: 4,
                    label: 'Vacatures',
                    href: 'https://www.werkenbijloods5.nl',
                },
            ],
        },
        {
            id: 'h',
            items: [
                {
                    id: '38',
                    order: 1,
                    label: 'Klantenservice',
                    href: '/klantenservice',
                },
                {
                    id: '39',
                    order: 2,
                    label: 'Mijn account',
                    href: '/account/dashboard',
                },
            ],
        },
    ],
};
