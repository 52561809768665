import { FC, ReactElement, useEffect } from 'react';

import { GuestWishlistItems } from '../../containers';
import { useTypedDispatch, useTypedSelector } from '../../redux/store';
import { getWishList } from '../../redux/wishlist/wishlistActions';

export const ConnectedGuestWishlistItems: FC = (): ReactElement => {
    const dispatch = useTypedDispatch();

    const wishlist = useTypedSelector(state => state.wishlistSlice.wishlist);

    useEffect(() => {
        dispatch(getWishList());
    }, []);

    return (
        <GuestWishlistItems items={wishlist.products} />
    );
};
