import { RouteData } from '../../LocalizedRoutes';

export enum AppRoute {
    // Account
    account = 'routes.account',
    accountChangePassword = 'routes.changePassword',
    accountDashboard = 'routes.dashboard',
    accountData = 'routes.data',
    accountOrders = 'routes.orders',
    accountWishlist = 'routes.accountWishlist',
    accountOrderDetail = 'routes.orderDetail',
    // Blog
    blogOverview = 'routes.blogOverview',
    blogOverviewCategory = 'routes.blogOverviewCategory',
    blogPost = 'routes.blogPost',
    // Content
    companyJoinUs = 'routes.companyJoinUs',
    companyMedia = 'routes.companyMedia',
    companyStory = 'routes.companyStory',
    companySustainability = 'routes.companySustainability',
    companyWorkAt = 'routes.companyWorkAt',
    customerService = 'routes.customerService',
    customerServiceAccount = 'routes.customerServiceAccount',
    customerServiceDelivery = 'routes.customerServiceDelivery',
    customerServiceFaq = 'routes.customerServiceFaq',
    customerServiceOrdering = 'routes.customerServiceOrdering',
    customerServiceReturns = 'routes.customerServiceReturns',
    customerServiceTerms = 'routes.customerServiceTerms',
    legalCookies = 'routes.legalCookies',
    legalDisclaimer = 'routes.legalDisclaimer',
    legalPrivacy = 'routes.legalPrivacy',
    storesDepartments = 'routes.storesDepartments',
    storesGiftcard = 'routes.storesGiftcard',
    storesOpeningHours = 'routes.storesOpeningHours',
    storesOverview = 'routes.storesOverview',
    storesRestaurant = 'routes.storesRestaurant',
    // Other
    brandDetail = 'routes.brandDetail',
    cart = 'routes.cart',
    contentBlocks = 'routes.contentBlocks',
    custom = 'routes.custom',
    departmentDetail = 'routes.departmentDetail',
    forgotPassword = 'routes.forgotPassword',
    home = 'routes.home',
    homeWithInteractiveProductHeader = 'routes.homeWithInteractiveProductHeader',
    index = 'routes.index',
    login = 'routes.login',
    notFound = 'routes.notFound',
    productCategory = 'routes.productCategory',
    productDetail = 'routes.productDetail',
    registration = 'routes.registration',
    search = 'routes.search',
    storeDetail = 'routes.storeDetail',
    styleguide = 'routes.styleguide',
    wishlist = 'routes.wishlist',
}

export const appRoutes = Object.fromEntries<RouteData<AppRoute>>(
    Object.values(AppRoute).map(value => ([value, {
        key: value as AppRoute,
        label: `${value}.label`,
        path: `${value}.path`,
    }])),
);
