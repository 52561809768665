import { FC, PropsWithChildren, ReactElement } from 'react';

import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';

import { PageInterface } from '../../../entities/Page/Page';
import { useTrans } from '../../../hooks';

interface PageHelmetProps {
    page: PageInterface;
}

export const PageHelmet: FC<PropsWithChildren<PageHelmetProps>> = ({
    page,
    children,
}): ReactElement => {
    const { pathname } = useLocation();
    const trans = useTrans();

    const twitterHandle = trans('company.twitterHandle');
    const siteUrl = trans('company.url');

    const fallbackImage = {
        src: `${siteUrl}/images/metadata-preview.jpg`,
        alt: trans('company.name'),
    };

    const metaTitle = page.seo
        ? page.seo.title
        : page.title;

    const metaDescription = page.seo
        ? page.seo.description
        : page.description;

    const metaKeywords = page.seo
        ? page.seo.keywords
        : undefined;

    const metaImage = page.seo
        ? page.seo.image
        : undefined;

    // TODO: Add support for Breadcrumbs structured data

    return (
        <Helmet>
            <title>{page.title}</title>
            <meta name="description" content={metaDescription} />
            <meta name="keywords" content={metaKeywords} />

            <meta property="og:title" content={metaTitle} />
            <meta property="og:description" content={metaDescription} />

            <meta property="og:image" content={metaImage?.src || fallbackImage.src} />
            <meta property="og:image:alt" content={metaImage?.alt || fallbackImage.alt} />

            {twitterHandle.startsWith('@') && (
                <>
                    <meta property="twitter:card" content="summary" />
                    <meta property="twitter:site" content={twitterHandle} />

                    <meta property="twitter:title" content={metaTitle} />
                    <meta property="twitter:description" content={metaDescription} />

                    <meta property="twitter:image" content={metaImage?.src || fallbackImage.src} />
                    <meta property="twitter:image:alt" content={metaImage?.alt || fallbackImage.alt} />
                </>
            )}

            <link rel="canonical" href={page.seo?.canonicalURL || siteUrl + pathname} />

            {children}
        </Helmet>
    );
};
