import { FC, ReactElement } from 'react';

import { TopNavbar } from '../../containers';
import { TopNabvarUspType } from '../../entities/@blocks/TopNavbar/TopNavbar';
import { AppRoute, appRoutes } from '../../entities/Routing/Routing';
import { useTrans } from '../../hooks';

export const ConnectedTopNavbar: FC = (): ReactElement => {
    const trans = useTrans();

    const usps = [
        {
            id: '1',
            type: TopNabvarUspType.text,
            data: 'Niet tevreden? Geld terug!',
        },
        {
            id: '2',
            type: TopNabvarUspType.text,
            data: '6 winkels',
        },
        {
            id: '3',
            type: TopNabvarUspType.rating,
            data: {
                url: 'https://nl.trustpilot.com',
                stars: 4,
            },
        },
        {
            id: '4',
            type: TopNabvarUspType.text,
            data: '24/7 klantenservice',
        },
    ];

    const links = [
        {
            label: 'Nu geopend: Donderdag 10:00 - 18:00 uur',
            href: '/',
        },
        {
            label: trans(appRoutes[AppRoute.storesOverview].label),
            href: trans(appRoutes[AppRoute.storesOverview].path),
        },
        {
            label: trans(appRoutes[AppRoute.customerService].label),
            href: trans(appRoutes[AppRoute.customerService].path),
        },
    ];

    return (
        <TopNavbar
            usps={usps}
            links={links}
        />
    );
};
