import {
    FC,
    ReactElement,
    useEffect,
    useState,
} from 'react';

import { usePagination } from 'react-instantsearch';

import { InstantSearchPagination } from '../../../containers';
import { scrollToTop } from '../../../helpers/scroll';

interface ConnectedInstantSearchPaginationProps {
    className?: string;
}

export const ConnectedInstantSearchPagination: FC<ConnectedInstantSearchPaginationProps> = ({
    className = '',
}): ReactElement | null => {
    const { nbHits, currentRefinement, refine } = usePagination();

    const [totalPages, setTotalPages] = useState<number>(0);

    useEffect(() => {
        const hitsPerPage = 21;
        const pages = Math.ceil(nbHits / hitsPerPage);
        setTotalPages(pages);
    }, [nbHits]);

    if (totalPages <= 1) {
        return null;
    }

    const handleOnChange = (page: number): void => {
        scrollToTop();
        refine(page);
    };

    return (
        <InstantSearchPagination
            totalPages={totalPages}
            currentPage={currentRefinement + 1}
            onChange={handleOnChange}
            className={className}
        />
    );
};
