import { FC, ReactElement } from 'react';

import { Page } from '../../../components';
import { ConnectedBlockRenderer, ConnectedPageHelmet } from '../../../connectors';
import { AppRoute } from '../../../entities/Routing/Routing';

import './CompanyMediaPage.scss';

export const CompanyMediaPage: FC = (): ReactElement => (
    <Page className="company-media-page">
        <ConnectedPageHelmet appRoute={AppRoute.companyMedia} />
        <ConnectedBlockRenderer appRoute={AppRoute.companyMedia} />
    </Page>
);
