import {
    FC,
    MouseEvent,
    ReactElement,
    useEffect,
    useMemo,
} from 'react';

import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { paginationPageParameter } from '../../../entities/Pagination/Pagination';
import { generateIdArray } from '../../../helpers/array';
import { stringIsNumber } from '../../../helpers/string';
import { useTrans } from '../../../hooks';
import { LinkIconButton } from '../..';
import { LongPagination, PaginationLink } from './subcomponents';

import './Pagination.scss';

interface PaginationProps {
    numberOfPages: number;
    currentPage: number;
    marginInitial?: number;
    marginCenter?: number;
    onChange: (page: number) => void;
    className?: string;
}

export const Pagination: FC<PaginationProps> = ({
    numberOfPages = 1,
    currentPage,
    marginInitial = 4,
    marginCenter = 2,
    onChange,
    className = '',
}): ReactElement => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const [searchParams] = useSearchParams();
    const trans = useTrans();

    useEffect((): void => {
        if (!searchParams) {
            return;
        }

        const searchParamPage = searchParams.get(paginationPageParameter);

        if (searchParamPage && stringIsNumber(searchParamPage)) {
            onChange(Number(searchParamPage));
        }
    }, []);

    const pageNumbers = generateIdArray(numberOfPages).map(page => page + 1);
    const centerListWithMarginLength = (marginInitial + 1 + marginInitial);
    const isLongPagination = numberOfPages > centerListWithMarginLength;

    const prevPageLink = useMemo((): string => {
        if (!searchParams) {
            return '';
        }

        const params = new URLSearchParams(Array.from(searchParams.entries()));

        const prevPage = Math.max(1, currentPage - 1);

        params.set(paginationPageParameter, prevPage.toString());

        return `?${params.toString()}`;
    }, [currentPage, searchParams]);

    const nextPageLink = useMemo((): string => {
        if (!searchParams) {
            return '';
        }

        const params = new URLSearchParams(Array.from(searchParams.entries()));

        const nextPage = Math.min(currentPage + 1, numberOfPages);

        params.set(paginationPageParameter, nextPage.toString());

        return `?${params.toString()}`;
    }, [currentPage, searchParams]);

    const handleChange = (page: number): void => {
        if (!searchParams) {
            return;
        }

        const currentSearchParams = new URLSearchParams(Array.from(searchParams.entries()));

        currentSearchParams.set(paginationPageParameter, page.toString());

        const params = currentSearchParams.toString();
        const query = params ? `?${params}` : '';

        onChange(page);

        // TODO: Scroll: false
        navigate(pathname + query);
    };

    const handlePrevClick = (event: MouseEvent<HTMLAnchorElement>): void => {
        event.preventDefault();

        handleChange(currentPage - 1);
    };

    const handleNextClick = (event: MouseEvent<HTMLAnchorElement>): void => {
        event.preventDefault();

        handleChange(currentPage + 1);
    };

    return (
        <nav
            aria-label={trans('compositions.pagination.label')}
            className={`pagination ${className}`}
        >
            <LinkIconButton
                hideLabel
                to={prevPageLink}
                icon="chevron-left"
                text={trans('common.prev')}
                disabled={currentPage <= 1}
                onClick={handlePrevClick}
                className="pagination__direction-button"
                iconClassName="pagination__direction-icon"
            />

            {isLongPagination && (
                <LongPagination
                    numberOfPages={numberOfPages}
                    currentPage={currentPage}
                    marginInitial={marginInitial}
                    marginCenter={marginCenter}
                    onChange={handleChange}
                    className="pagination__list"
                />
            )}

            {!isLongPagination && (
                <ul className="pagination__list">
                    {pageNumbers.map(page => (
                        <li key={page} className="pagination__list-item">
                            <PaginationLink
                                key={page}
                                isActive={currentPage === page}
                                page={page}
                                onClick={handleChange}
                                className="pagination__link"
                            />
                        </li>
                    ))}
                </ul>
            )}

            <LinkIconButton
                hideLabel
                to={nextPageLink}
                icon="chevron-right"
                text={trans('common.next')}
                disabled={currentPage >= numberOfPages}
                onClick={handleNextClick}
                className="pagination__direction-button"
                iconClassName="pagination__direction-icon"
            />
        </nav>
    );
};
