import {
    FC,
    FormEvent,
    ReactElement,
    useState,
} from 'react';

import classNames from 'classnames';

import { IconButton, PasswordInput, TextInput } from '../../../../compositions';
import { FormProps } from '../../../../entities/@forms/Form/Form';
import { AddressType } from '../../../../entities/Address/Address';
import { User } from '../../../../entities/User/User';
import { useTrans } from '../../../../hooks';

import './MyData.scss';

interface MyDataProps extends FormProps<User> {
    user: User;
    className?: string;
}

export const MyData: FC<MyDataProps> = ({
    user,
    onSubmit,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    const [isEditModeOn, setIsEditModeOn] = useState(false);
    const [firstName, setFirstName] = useState(user.firstName);
    const [lastName, setLastName] = useState(user.lastName);
    const [email, setEmail] = useState(user.email);
    const [dateOfBirth, setDateOfBirth] = useState(user.dateOfBirth);
    const [phoneNumber, setPhoneNumber] = useState(user.phoneNumber);
    const [password, setPassword] = useState(user.password);

    const [companyName, setCompanyName] = useState(user.company.name);
    const [companyVatNumber, setCompanyVatNumber] = useState(user.company.vatNumber);

    const shippingAddress = user.addresses.find(address => address.type === AddressType.shipping) || user.addresses[0];
    const [street, setStreet] = useState(shippingAddress.street);
    const [postalCode, setPostalCode] = useState(shippingAddress.postalCode);
    const [houseNumber, setHouseNumber] = useState(shippingAddress.houseNumber);
    const [city, setCity] = useState(shippingAddress.city);
    const [country, setCountry] = useState(shippingAddress.country);

    const handleSubmit = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
        event.preventDefault();

        if (!isEditModeOn) {
            setIsEditModeOn(true);
            return;
        }

        const company = {
            name: companyName,
            vatNumber: companyVatNumber,
        };

        const addresses = user.addresses.map(address => {
            if (address.type === AddressType.shipping) {
                return {
                    ...address,
                    street,
                    postalCode,
                    houseNumber,
                    city,
                    country,
                };
            }

            return address;
        });

        const formData: User = {
            id: user.id,
            firstName,
            lastName,
            email,
            dateOfBirth,
            phoneNumber,
            addresses,
            password, // TODO: hash password
            company,
        };

        const [errors, hasErrors] = 'TODO: validate form data';

        if (hasErrors) {
            console.error(errors);
            return;
        }

        onSubmit(formData);
        setIsEditModeOn(false);
    };

    const passwordIconClassNames = classNames('my-data__password-input', {
        'my-data__password-input--show-icon': isEditModeOn,
    });

    return (
        <form className={`my-data ${className}`} onSubmit={handleSubmit}>
            <div className="my-data__header">
                <h6 className="my-data__heading">
                    {trans('pages.accountDashboard.myData')}
                </h6>

                <IconButton
                    icon={isEditModeOn ? 'check' : 'edit'}
                    text={isEditModeOn ? trans('common.save') : trans('common.edit')}
                    type="submit"
                    className="my-data__edit-button"
                />
            </div>

            <div className="my-data__form">
                <section className="my-data__form-section">
                    <fieldset className="my-data__fieldset">
                        <legend className="my-data__legend">
                            {trans('containers.accountDashboardConfig.myData.personalData')}
                        </legend>

                        <TextInput
                            label={trans('form.firstName')}
                            value={firstName}
                            disabled={!isEditModeOn}
                            onChange={setFirstName}
                            className="my-data__input"
                            labelClassName="my-data__input-label"
                            fieldClassName="my-data__input-field"
                        />

                        <TextInput
                            label={trans('form.lastName')}
                            value={lastName}
                            disabled={!isEditModeOn}
                            onChange={setLastName}
                            className="my-data__input"
                            labelClassName="my-data__input-label"
                            fieldClassName="my-data__input-field"
                        />

                        <TextInput
                            label={trans('form.email')}
                            value={email}
                            disabled={!isEditModeOn}
                            onChange={setEmail}
                            className="my-data__input"
                            labelClassName="my-data__input-label"
                            fieldClassName="my-data__input-field"
                        />

                        <TextInput
                            label={trans('form.dateOfBirth')}
                            value={dateOfBirth}
                            disabled={!isEditModeOn}
                            onChange={setDateOfBirth}
                            className="my-data__input"
                            labelClassName="my-data__input-label"
                            fieldClassName="my-data__input-field"
                        />

                        <TextInput
                            label={trans('form.phoneNumber')}
                            value={phoneNumber}
                            disabled={!isEditModeOn}
                            onChange={setPhoneNumber}
                            className="my-data__input"
                            labelClassName="my-data__input-label"
                            fieldClassName="my-data__input-field"
                        />
                    </fieldset>

                    <fieldset className="my-data__fieldset">
                        <legend className="my-data__legend">
                            {trans('containers.accountDashboardConfig.myData.addressData')}
                        </legend>

                        {/* TODO: Add feature to get address by zipcode and housenumber */}
                        <div className="my-data__address">
                            <div className="my-data__address-input-wrapper">
                                <TextInput
                                    label={trans('form.street')}
                                    hideLabel={!isEditModeOn}
                                    value={street}
                                    disabled={!isEditModeOn}
                                    onChange={setStreet}
                                    labelClassName="my-data__input-label"
                                    fieldClassName="my-data__input-field"
                                />

                                <TextInput
                                    label={trans('form.houseNumber')}
                                    hideLabel={!isEditModeOn}
                                    value={houseNumber}
                                    disabled={!isEditModeOn}
                                    onChange={setHouseNumber}
                                    labelClassName="my-data__input-label"
                                    fieldClassName="my-data__input-field"
                                />
                            </div>

                            <div className="my-data__address-input-wrapper">
                                <TextInput
                                    autoCapitalize="characters"
                                    label={trans('form.postalCode')}
                                    hideLabel={!isEditModeOn}
                                    value={postalCode}
                                    disabled={!isEditModeOn}
                                    onChange={setPostalCode}
                                    labelClassName="my-data__input-label"
                                    fieldClassName="my-data__input-field"
                                />

                                <TextInput
                                    label={trans('form.city')}
                                    hideLabel={!isEditModeOn}
                                    value={city}
                                    disabled={!isEditModeOn}
                                    onChange={setCity}
                                    labelClassName="my-data__input-label"
                                    fieldClassName="my-data__input-field"
                                />
                            </div>

                            <TextInput
                                label={trans('form.country')}
                                hideLabel={!isEditModeOn}
                                value={country}
                                disabled={!isEditModeOn}
                                onChange={setCountry}
                                labelClassName="my-data__input-label"
                                fieldClassName="my-data__input-field"
                            />
                        </div>
                    </fieldset>
                </section>

                <section className="my-data__form-section">
                    <fieldset className="my-data__fieldset">
                        <legend className="my-data__legend">
                            {trans('containers.accountDashboardConfig.myData.account')}
                        </legend>

                        <PasswordInput
                            label={trans('form.password')}
                            value={password}
                            disabled={!isEditModeOn}
                            onChange={setPassword}
                            className="my-data__input"
                            labelClassName="my-data__input-label"
                            fieldClassName="my-data__input-field"
                            showPasswordIconClassName={passwordIconClassNames}
                        />
                    </fieldset>

                    <fieldset className="my-data__fieldset">
                        <legend className="my-data__legend">
                            {trans('form.companyName')}
                        </legend>

                        <TextInput
                            label={trans('form.companyName')}
                            value={companyName}
                            disabled={!isEditModeOn}
                            onChange={setCompanyName}
                            className="my-data__input"
                            labelClassName="my-data__input-label"
                            fieldClassName="my-data__input-field"
                        />

                        <TextInput
                            label={trans('form.vatNumber')}
                            value={companyVatNumber}
                            disabled={!isEditModeOn}
                            onChange={setCompanyVatNumber}
                            className="my-data__input"
                            labelClassName="my-data__input-label"
                            fieldClassName="my-data__input-field"
                        />
                    </fieldset>
                </section>
            </div>
        </form>
    );
};
