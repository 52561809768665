import {
    CreateOrderItemResponse,
    CreateOrderResponse,
    DeleteOrderItemResponse,
    SetOrderItemResponse,
} from '../../entities/Api/Webshop';
import { CartInterface } from '../../entities/Cart/Cart';
import { transformToCart } from '../../entities/Cart/CartTransformers';
import { isFetchResultSuccessful } from '../../entities/FetchResult/FetchResult';
import { authorizedSyliusFetch } from '../../entities/Sylius/SyliusService';
import { TypedDispatch } from '../store';
import {
    setCart,
    setError,
    setIsLoading,
    setIsSuccessfullyAdded,
    setIsSuccessfullyRemoved,
} from './cartSlice';

export const getCart = () => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const body = {
            localeCode: 'nl',
        };

        const getCartResponse = await authorizedSyliusFetch<CreateOrderResponse>('/shop/orders', {
            method: 'POST',
            body: JSON.stringify(body),
        });

        if (!isFetchResultSuccessful(getCartResponse)) {
            dispatch(setError(getCartResponse.error));
            return;
        }

        const cart = transformToCart(getCartResponse.data);

        dispatch(setCart(cart));
    } catch (error) {
        console.error('[getCart]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const addProductVariantToCart = (productVariantId: string, cartId: string) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsSuccessfullyAdded(false));
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const body = {
            productVariant: productVariantId,
            quantity: 1,
        };

        const addProductVariantToCartResponse = await authorizedSyliusFetch<CreateOrderItemResponse>(`/shop/orders/${cartId}/items`, {
            method: 'POST',
            body: JSON.stringify(body),
        });

        if (!isFetchResultSuccessful(addProductVariantToCartResponse)) {
            dispatch(setError(addProductVariantToCartResponse.error));
            return;
        }

        const cart = transformToCart(addProductVariantToCartResponse.data);

        dispatch(setCart(cart));
        dispatch(setIsSuccessfullyAdded(true));
    } catch (error) {
        console.error('[addProductVariantToCart]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const updateProductVariantInCart = (
    cartItemId: string,
    newQuantity: number,
    cartId: string,
) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const body = {
            quantity: newQuantity,
        };

        const updateProductVariantInCartResponse = await authorizedSyliusFetch<SetOrderItemResponse>(`/shop/orders/${cartId}/items/${cartItemId}`, {
            method: 'PATCH',
            headers: { 'Content-Type': 'application/merge-patch+json' },
            body: JSON.stringify(body),
        });

        if (!isFetchResultSuccessful(updateProductVariantInCartResponse)) {
            dispatch(setError(updateProductVariantInCartResponse.error));
            return;
        }

        const cart = transformToCart(updateProductVariantInCartResponse.data);

        dispatch(setCart(cart));
    } catch (error) {
        console.error('[updateProductVariantInCart]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const removeProductVariantFromCart = (cartItemId: string, cart: CartInterface) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const removeProductVariantFromCartResponse = await authorizedSyliusFetch<DeleteOrderItemResponse>(`/shop/orders/${cart.id}/items/${cartItemId}`, {
            method: 'DELETE',
        });

        if (!isFetchResultSuccessful(removeProductVariantFromCartResponse)) {
            dispatch(setError(removeProductVariantFromCartResponse.error));
            return;
        }

        const updatedCart = {
            ...cart,
            items: cart.items.filter(item => item.id !== cartItemId),
        };

        dispatch(setCart(updatedCart));
        dispatch(setIsSuccessfullyRemoved(true));
    } catch (error) {
        console.error('[removeProductVariantFromCart]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const clearCart = (cartId: string) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const clearCartResponse = await authorizedSyliusFetch<DeleteOrderItemResponse>(`/shop/orders/${cartId}`, {
            method: 'DELETE',
        });

        if (!isFetchResultSuccessful(clearCartResponse)) {
            dispatch(setError(clearCartResponse.error));
        }
    } catch (error) {
        console.error('[clearCart]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};
