import {
    FC,
    ReactElement,
    useEffect,
    useState,
} from 'react';

import { useParams } from 'react-router-dom';

import { Page, Wrapper } from '../../components';
import {
    ConnectedBrandRenderer,
    ConnectedInstantSearchInstance,
    ConnectedNewsletterCallToAction,
    ConnectedProductList,
} from '../../connectors';
import { MeilisearchAttributes } from '../../entities/Meilisearch/Meilisearch';
import { getFilterQuery } from '../../helpers/meilisearch';
import { useTrans } from '../../hooks';
import { NotFoundPage } from '../NotFoundPage/NotFoundPage';

import './BrandDetailPage.scss';

interface BrandDetailProps {
    className?: string;
}

export const BrandDetailPage: FC<BrandDetailProps> = ({
    className = '',
}): ReactElement => {
    const { slug = '' } = useParams();

    const trans = useTrans();

    const [productsQuery, setProductsQuery] = useState<string>('');

    useEffect(() => {
        if (!slug) {
            return;
        }

        const query = getFilterQuery(MeilisearchAttributes.brand, slug);
        setProductsQuery(query);
    }, [slug]);

    if (!slug) {
        return (
            <NotFoundPage />
        );
    }

    return (
        <Page className={`brand-detail-page ${className}`}>
            <ConnectedBrandRenderer slug={slug} />

            <Wrapper className="brand-detail-page__block">
                <ConnectedInstantSearchInstance>
                    <ConnectedProductList
                        showFilterBar
                        title={trans('pages.brands.shopProducts')}
                        filterQuery={productsQuery}
                    />
                </ConnectedInstantSearchInstance>
            </Wrapper>

            <ConnectedNewsletterCallToAction
                title="Wekelijks inspiratie in je inbox?"
                description="Meld je aan voor de Loods 5 nieuwsbrief en ontvang inspiratie, persoonlijke acties en korting!"
                textColor="#000000"
                className="brand-detail-page__block"
            />
        </Page>
    );
};
