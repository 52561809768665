import { FC, ReactElement } from 'react';

import { BlogPost } from '../../entities/@blog/BlogPost/BlogPost';
import { BlogPostCard } from '../BlogPostCard/BlogPostCard';
import { BlogPostCardListSkeletons } from './skeletons';

import './BlogPostCardList.scss';

interface BlogPostCardListProps {
    isLoading?: boolean;
    numberOfSkeletons?: number;
    blogPosts?: BlogPost[];
    cardClassName?: string;
    className?: string;
}

export const BlogPostCardList: FC<BlogPostCardListProps> = ({
    isLoading,
    numberOfSkeletons,
    blogPosts = [],
    cardClassName = '',
    className = '',
}): ReactElement => {
    if (isLoading) {
        return (
            <BlogPostCardListSkeletons
                numberOfSkeletons={numberOfSkeletons}
                className={className}
            />
        );
    }

    return (
        <ul className={`blog-post-card-list ${className}`}>
            {blogPosts.map(blogPost => (
                <li
                    key={blogPost.id}
                    className="blog-post-card-list__list-item"
                >
                    <BlogPostCard
                        blogPost={blogPost}
                        className={`blog-post-card-list__card ${cardClassName}`}
                    />
                </li>
            ))}
        </ul>
    );
};
