import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { PageInterface } from '../../entities/Page/Page';
import { AppRoute } from '../../entities/Routing/Routing';
import { AsyncReduxState, initialAsyncReduxState } from '../defaults';

export type PagesState = AsyncReduxState<{
    pages: Partial<Record<AppRoute, PageInterface>>;
}>;

const initialState: PagesState = {
    ...initialAsyncReduxState,
    pages: {},
};

const pagesSlice = createSlice({
    name: 'pagesSlice',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): PagesState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setHasFetched(state, action: PayloadAction<boolean>): PagesState {
            return {
                ...state,
                hasFetched: action.payload,
            };
        },
        setIsSuccessful(state, action: PayloadAction<boolean>): PagesState {
            return {
                ...state,
                isSuccessful: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): PagesState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setPages(state, action: PayloadAction<Partial<Record<AppRoute, PageInterface>>>): PagesState {
            return {
                ...state,
                pages: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setHasFetched,
    setIsSuccessful,
    setError,
    setPages,
} = pagesSlice.actions;

export default pagesSlice;
