import { FC, ReactElement } from 'react';

import { generateIdArray } from '../../../../../helpers/array';

import './RangeInputMarks.scss';

interface RangeInputMarksProps {
    amount: number;
    min: number;
    max: number;
    className?: string;
}

export const RangeInputMarks: FC<RangeInputMarksProps> = ({
    amount,
    min,
    max,
    className = '',
}): ReactElement => {
    const minMaxDiff = max - min;
    const markSize = minMaxDiff / (amount - 1);
    const markAmount = Math.max(2, amount);
    const markList = generateIdArray(markAmount - 2)
        .map(n => min + (n + 1) * markSize);

    return (
        <ul className={`range-input-marks ${className}`}>
            {[min, ...markList, max].map(mark => (
                <li key={mark} className="range-input-marks__mark">
                    {Math.round(mark)}
                </li>
            ))}
        </ul>
    );
};
