import qs from 'qs';

import { strapiBlockPopulates } from '../Strapi/StrapiRequests';
import { PageType } from './Page';

export const generatePageQuery = (type: PageType): string => {
    const filters = {
        fixedType: {
            $eq: type,
        },
    };

    const populate = {
        elements: strapiBlockPopulates,
        seo: { populate: '*' },
    };

    return qs.stringify({
        filters,
        populate,
    }, {
        encodeValuesOnly: true,
    });
};


export const generateCustomPageQuery = (slug: string): string => {
    const filters = {
        slug: {
            $eq: slug.slice(1),
        },
    };

    const populate = {
        elements: strapiBlockPopulates,
        seo: { populate: '*' },
    };

    return qs.stringify({
        filters,
        populate,
    }, {
        encodeValuesOnly: true,
    });
};
