import {
    FormErrors,
    FormValidation,
    validateEmailAddress,
    validateForm,
    validateRequiredString,
} from '../../../../entities/@forms/FormValidation/FormValidationService';
import { LoginFormData } from '../../../../entities/@forms/LoginForm/LoginForm';

export type LoginFormErrors = FormErrors<LoginFormData>;

export const validateLoginFormData = (formData: LoginFormData): FormValidation<LoginFormErrors> => {
    const errors: LoginFormErrors = {
        username: validateEmailAddress(formData.username),
        password: validateRequiredString('password', formData.password),
    };

    return validateForm<LoginFormErrors>(errors);
};
