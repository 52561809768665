import { FC, PointerEvent, ReactElement } from 'react';

import classNames from 'classnames';

import { clamp } from '../../../../../helpers/number';
import { MinMaxValue } from '../../../../../types';

import './RangeInputBar.scss';

interface RangeInputBarProps {
    isRtl?: boolean;
    labelledby: string;
    isInverted?: boolean;
    min: number;
    max: number;
    step: number;
    value: MinMaxValue;
    disabled?: boolean;
    onClick: (value: number) => void;
    className?: string;
}

export const RangeInputBar: FC<RangeInputBarProps> = ({
    isRtl,
    labelledby,
    isInverted,
    min,
    max,
    value,
    step,
    disabled,
    onClick,
    className = '',
}): ReactElement => {
    const minMaxDiff = max - min;

    const handleBarClick = (event: PointerEvent<HTMLDivElement>): void => {
        const bar = event.currentTarget;
        const clickedPos = event.clientX - bar.getBoundingClientRect().left;
        const clickedPercentage = clickedPos / bar.offsetWidth;

        const newValue = isRtl
            ? max - Math.round(min + (minMaxDiff * clickedPercentage))
            : Math.round(min + (minMaxDiff * clickedPercentage));

        const steppedValue = Math.round(newValue / step) * step;
        const clampedValue = clamp(steppedValue, min, max);

        onClick(clampedValue);
    };

    const rangeInputBarClassNames = classNames('range-input-bar', {
        'range-input-bar--is-disabled': !!disabled,
    }, className);

    const rangeInputBarTrackClassNames = classNames('range-input-bar__track', {
        'range-input-bar__track--is-inverted': !!isInverted,
    });

    return (
        // Accessibility, where linter fails on, is included within component
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/interactive-supports-focus
        <div
            role="slider"
            aria-labelledby={labelledby}
            aria-valuemin={min}
            aria-valuemax={max}
            aria-valuenow={value.min}
            onClick={handleBarClick}
            className={rangeInputBarClassNames}
        >
            <div className={rangeInputBarTrackClassNames} />
        </div>
    );
};
