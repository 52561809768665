export enum MeilisearchAttributes {
    categories = 'categories',
    productType = 'productType',
    colors = 'colors.color',
    brand = 'brand',
    price = 'price.LOODS_5.price',
    material = 'material',
    origin = 'origin',
    extraLightning = 'extraLightning',
    lampFitting = 'lampFitting',
    lightDirection = 'lightDirection',
    powerSupply = 'powerSupply',
    suitableForOutdoorUse = 'suitableForOutdoorUse',
    size = 'size',
    code = 'code',
}

export enum InstantSearchStatus {
    idle = 'idle',
    loading = 'loading',
    stalled = 'stalled',
    error = 'error',
}
