import { FormOption } from '../@forms/Form/Form';

export enum Locale {
    dutch = 'Nederlands',
}

export enum Direction {
    ltr = 'ltr',
    rtl = 'rtl',
}

export enum Language {
    nl = 'nl',
}

export interface LocaleData {
    name: Locale;
    direction: Direction;
    language: Language;
}

export const locales: Record<Locale, LocaleData> = {
    [Locale.dutch]: {
        name: Locale.dutch,
        language: Language.nl,
        direction: Direction.ltr,
    },
};

export const defaultLocale = locales[Locale.dutch];

export const languageOptions: FormOption[] = Object.values(locales).map(locale => ({
    label: locale.name,
    value: locale.language,
}));
