import { FC, ReactElement } from 'react';

import { Link } from 'react-router-dom';

import { Picture } from '../../components';
import { DepartmentDetailInterface } from '../../entities/@departments/DepartmentDetail/DepartmentDetail';
import { HorizontalAlignment } from '../../entities/Alignment/Alignment';
import { AppRoute, appRoutes } from '../../entities/Routing/Routing';
import { WayfindingType } from '../../entities/Wayfinding/Wayfinding';
import { replaceUrlParamKeysWithValues } from '../../helpers/url';
import { useTrans } from '../../hooks';
import { LinkIconButton, WayfindingWrapper } from '..';

import './DepartmentCard.scss';

export interface DepartmentCardProps {
    department: DepartmentDetailInterface;
    className?: string;
}

export const DepartmentCard: FC<DepartmentCardProps> = ({
    department,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    const departmentPath = trans(appRoutes[AppRoute.departmentDetail].path);
    const departmentUrl = replaceUrlParamKeysWithValues(departmentPath, {
        slug: department.slug,
    });

    return (
        <div className={`department-card ${className}`}>
            <WayfindingWrapper
                id={`${department.id}-card`}
                type={WayfindingType.enter}
                className="department-card__wayfinding-wrapper"
                iconPathClassName="department-card__wayfinding-icon-path"
            >
                <Link to={departmentUrl} tabIndex={-1}>
                    <div className="department-card__image-wrapper">
                        <Picture {...department.image} className="department-card__image" />
                    </div>
                </Link>
            </WayfindingWrapper>

            <div className="department-card__text-wrapper">
                <h3 className="department-card__title">
                    {department.title}
                </h3>

                <p className="department-card__intro">
                    {department.intro}
                </p>

                <LinkIconButton
                    to={departmentUrl}
                    iconPos={HorizontalAlignment.right}
                    icon="arrow-short-right"
                    text={trans('compositions.departmentCard.readMore')}
                    className="department-card__link-button"
                    labelClassName="department-card__link-label"
                    iconClassName="department-card__link-icon"
                />
            </div>
        </div>
    );
};
