import { FC, ReactElement } from 'react';

import { ServiceSection } from '../../../containers';
import { ServiceSectionInterface } from '../../../entities/@blocks/ServiceSection/ServiceSection';
import { useClientEffect } from '../../../hooks';
import { fetchServiceSection } from '../../../redux/@blocks/serviceSection/serviceSectionActions';
import { useTypedDispatch, useTypedSelector } from '../../../redux/store';

export interface ConnectedServiceSectionProps extends ServiceSectionInterface {
    className?: string;
}

export const ConnectedServiceSection: FC<ConnectedServiceSectionProps> = ({
    className = '',
    ...serviceSectionProps
}): ReactElement => {
    const dispatch = useTypedDispatch();

    const { serviceSectionItems } = useTypedSelector((state) => state.serviceSectionSlice);

    useClientEffect((): void => {
        dispatch(fetchServiceSection());
    }, []);

    return (
        <ServiceSection
            {...serviceSectionProps}
            serviceContactDetails={serviceSectionItems}
            className={className}
        />
    );
};
