import { FC, ReactElement } from 'react';

import { Page } from '../../../components';
import { ConnectedBlockRenderer, ConnectedPageHelmet } from '../../../connectors';
import { AppRoute } from '../../../entities/Routing/Routing';

import './CustomerServiceTermsPage.scss';

export const CustomerServiceTermsPage: FC = (): ReactElement => (
    <Page className="customer-service-terms-page">
        <ConnectedPageHelmet appRoute={AppRoute.customerServiceTerms} />
        <ConnectedBlockRenderer appRoute={AppRoute.customerServiceTerms} />
    </Page>
);
