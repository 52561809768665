import { FC, PropsWithChildren, ReactElement } from 'react';

import { InstantSearch } from 'react-instantsearch';

import { initializeMeilisearchClient, MeilisearchIndex } from '../../../entities/Meilisearch/MeilisearchService';

const { searchClient } = initializeMeilisearchClient();

const indexName = MeilisearchIndex.variantsNl;

export const ConnectedInstantSearchInstance: FC<PropsWithChildren> = ({ children }): ReactElement => (
    <InstantSearch indexName={indexName} searchClient={searchClient}>
        {children}
    </InstantSearch>
);
