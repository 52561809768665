import { BlockType } from '../../Block/Block';
import { transformToDefaultBlock } from '../../Block/BlockTransformers';
import {
    FaqCategories,
    FaqCategoryType,
    FaqCategoryTypeResource,
    FaqDataInterface,
    FaqDataResource,
    FaqItemInterface,
    FaqItemResource,
} from './Faq';

const transformToFaqCategoryType = (resource?: FaqCategoryTypeResource): FaqCategoryType => {
    const faqCategories: Record<FaqCategoryTypeResource, FaqCategoryType> = {
        [FaqCategoryTypeResource.store]: FaqCategoryType.store,
        [FaqCategoryTypeResource.onlineStore]: FaqCategoryType.onlineStore,
        [FaqCategoryTypeResource.faq]: FaqCategoryType.faq,
        [FaqCategoryTypeResource.returns]: FaqCategoryType.returns,
        [FaqCategoryTypeResource.transport]: FaqCategoryType.transport,
        [FaqCategoryTypeResource.garage]: FaqCategoryType.garage,
    };

    return resource
        ? faqCategories[resource]
        : FaqCategoryType.other;
};

export const transformToFaqItem = (resource: FaqItemResource): FaqItemInterface => {
    const category = transformToFaqCategoryType(resource.category);

    return {
        id: resource.id,
        category,
        question: resource.question || '',
        answer: resource.response || '',
    };
};

export const transformToFaqCategories = (resource: FaqItemInterface[]): FaqCategories => (
    resource.reduce((accumulator: FaqCategories, faqItem) => {
        (accumulator[faqItem.category] = accumulator[faqItem.category] || []).push(faqItem);

        return accumulator;
    }, {})
);

export const transformToFaqItems = (resource: FaqDataResource): FaqDataInterface => {
    const defaultBlock = transformToDefaultBlock(resource);

    const faqItems = resource.faqs.map(transformToFaqItem);

    return {
        ...defaultBlock,
        type: BlockType.faqs,
        title: resource.title || '',
        faqItems,
    };
};
