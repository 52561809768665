import { WishlistGuest } from '../../entities/Wishlist/Wishlist';
import { TypedDispatch } from '../store';
import {
    setError,
    setIsLoading,
    setIsSuccessfullyAdded,
    setIsSuccessfullyRemoved,
    setWishlist,
} from './wishlistSlice';

const wishlistKey = 'wishlist';

export const addProductVariantToWishList = (productVariantId: string) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsSuccessfullyAdded(false));
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const wishlist = JSON.parse(localStorage.getItem(wishlistKey) || '');

        wishlist.products.push(productVariantId);

        localStorage.setItem(wishlistKey, JSON.stringify(wishlist));

        dispatch(setWishlist(wishlist));
        dispatch(setIsSuccessfullyAdded(true));
    } catch (error) {
        console.error('[addProductVariantToWishList]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const removeProductVariantFromWishList = (productVariantId: string) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsSuccessfullyRemoved(false));
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const wishlist = JSON.parse(localStorage.getItem(wishlistKey) || '');

        wishlist.products = wishlist.products.filter((product: string) => product !== productVariantId);

        localStorage.setItem(wishlistKey, JSON.stringify(wishlist));

        dispatch(setWishlist(wishlist));
        dispatch(setIsSuccessfullyRemoved(true));
    } catch (error) {
        console.error('[removeProductVariantFromWishList]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const getWishList = () => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsSuccessfullyAdded(false));
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const wishlist = localStorage.getItem(wishlistKey);

        if (!wishlist) {
            const newWishlist: WishlistGuest = {
                id: '1',
                name: '',
                products: [],
            };

            localStorage.setItem(wishlistKey, JSON.stringify(newWishlist));

            dispatch(setWishlist(newWishlist));
            return;
        }

        dispatch(setWishlist(JSON.parse(wishlist)));
    } catch (error) {
        console.error('[getWishList]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};
