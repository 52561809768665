import { FC, ReactElement } from 'react';

import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { Picture, Price } from '../../components';
import { Button, WishlistButton } from '../../compositions';
import { Product } from '../../entities/Product/Product';
import { useTrans } from '../../hooks';

import './ProductCard.scss';

interface ProductCardProps {
    isLoading: boolean;
    product: Product;
    isInWishlist: boolean;
    onAddToCart: () => void;
    onClickWishlistButton: () => void;
    className?: string;
    imageClassname?: string;
    pictureClassname?: string;
}

export const ProductCard: FC<ProductCardProps> = ({
    isLoading,
    product,
    isInWishlist,
    onAddToCart,
    onClickWishlistButton,
    className = '',
    imageClassname = '',
    pictureClassname = '',
}): ReactElement => {
    const trans = useTrans();

    const visibleColors = product.colors.slice(0, 5);

    const productImages = product.mediaItems.filter(mediaItem => mediaItem.image);

    const pictureClassNames = classNames('product-card__image-default', {
        'product-card__image-default--is-sold-out': productImages.length === 1 && product.stock === 0,
        'product-card__image-switch-primary': productImages.length > 1,
        'product-card__image-switch-primary--is-sold-out': productImages.length > 1 && product.stock === 0,
    }, pictureClassname);

    const secondaryPictureClassNames = classNames('product-card__image-switch-secondary', {
        'product-card__image-switch-secondary--is-sold-out': (product.stock === 0),
    }, pictureClassname);

    return (
        <div className={`product-card ${className}`}>
            <div className="product-card__image-wrapper">
                <div className="product-card__labels">
                    {product.stock === 0 && (
                        <p className="product-card__label product-card__label--is-sold-out">
                            {trans('containers.productCard.soldOut')}
                        </p>
                    )}

                    {/* TODO: Check if product is only online available (isOnlineOnly) (L5W-389) */}
                    <p className="product-card__label product-card__label--is-online-only">
                        {trans('containers.productCard.onlyOnline')}
                    </p>
                </div>

                <Link to={`/product/${product.slug}`} className="product-card__image-wrapper">
                    <Picture
                        {...productImages[0]?.image}
                        className={pictureClassNames}
                        imageClassName={imageClassname}
                    />

                    {productImages.length > 1 && (
                        <Picture
                            {...productImages[1].image}
                            className={secondaryPictureClassNames}
                            imageClassName={imageClassname}
                        />
                    )}
                </Link>

                <div className="product-card__buttons">
                    <Button
                        disabled={product.stock === 0 || isLoading}
                        text={product.stock > 0 ? trans('containers.productCard.add') : trans('containers.productCard.soldOut')}
                        onClick={onAddToCart}
                        className="product-card__add-to-cart-button"
                    />

                    <WishlistButton
                        isActive={isInWishlist}
                        text={trans('common.addToWishlist')}
                        onClick={onClickWishlistButton}
                        className="product-card__add-to-wishlist-button"
                    />
                </div>
            </div>

            <div className="product-card__info">
                {product.brand && (
                    <Link to={`/merk/${product.brand.slug}`} className="product-card__brand-link">
                        <h3 className="product-card__brand">
                            {product.brand.name}
                        </h3>
                    </Link>
                )}

                <Link to={`/product/${product.slug}`} className="product-card__product-link">
                    <h2 className="product-card__name">
                        {product.name}
                    </h2>
                </Link>

                <Price amount={product.price} className="product-card__price" />

                {product.variants === 1 && visibleColors.length > 0 && (
                    <div className="product-card__color-swatch">
                        <ul className="product-card__colors-list">
                            {visibleColors.map((color, index) => (
                                <li
                                    key={color[index]}
                                    className="product-card__colors-item"
                                    style={{ backgroundColor: color }}
                                />
                            ))}
                        </ul>

                        {product.colors.length > 5 && (
                            <p className="product-card__colors-amount">
                                +
                                {product.colors.length - 5}
                            </p>
                        )}
                    </div>
                )}

                {product.variants > 1 && (
                    <p className="product-card__variants">
                        <span>
                            {trans('containers.productCard.availableIn')}
                        </span>
                        {' '}
                        <span className="product-card__variants-amount">
                            {trans('containers.productCard.variants', { amount: product.variants })}
                        </span>
                    </p>
                )}
            </div>
        </div>
    );
};
