import { FC, ReactElement } from 'react';

import { DepartmentHelmet } from '../../../containers';
import { useTypedSelector } from '../../../redux/store';

export const ConnectedDepartmentDetailHelmet: FC = (): ReactElement | null => {
    const departmentDetail = useTypedSelector(state => state.departmentDetailSlice.departmentDetail);

    if (!departmentDetail) {
        return null;
    }

    return (
        <DepartmentHelmet department={departmentDetail} />
    );
};
