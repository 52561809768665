import { instantMeiliSearch, InstantMeiliSearchObject } from '@meilisearch/instant-meilisearch';

export enum MeilisearchIndex {
    pages = 'pages',
    variantsNl = 'variants_nl',
    variantsEn = 'variants_en',
}

const clientCredentials = {
    hostUrl: process.env.REACT_APP_MEILISEARCH_HOST_URL || 'missing-host-url',
    apiKey: process.env.REACT_APP_MEILISEARCH_API_KEY,
};

export const initializeMeilisearchClient = (): InstantMeiliSearchObject => instantMeiliSearch(clientCredentials.hostUrl, clientCredentials.apiKey);
