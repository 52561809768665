import { FC, ReactElement } from 'react';

import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { Icon } from '../../components';
import { FooterNavigation } from '../../entities/Footer/Footer';
import { FooterBottom } from './subcomponents';

import './Footer.scss';

interface FooterProps {
    footerNavigation: FooterNavigation;
    className?: string;
}

export const Footer: FC<FooterProps> = ({
    footerNavigation,
    className = '',
}): ReactElement => (
    <footer className={`footer ${className}`}>
        <div className="footer__top-wrapper">
            {footerNavigation.list.map(navList => (
                <nav
                    key={navList.id}
                    aria-label={navList.label}
                    className="footer__nav"
                >
                    <h2 className="footer__nav-title">
                        {navList.label}
                    </h2>

                    <ul className="footer__nav-list">
                        {navList.subNavigation.map(navItem => {
                            const navItemClassName = classNames('footer__nav-item', {
                                'footer__nav-item--with-icon': navItem.icon,
                            });

                            return (
                                <li key={navItem.id} className={navItemClassName}>
                                    {navItem.icon ? (
                                        <Link
                                            to={navItem.href}
                                            target={navItem.target}
                                            className="footer__nav-link"
                                        >
                                            <Icon name={navItem.icon} className="footer__nav-link-icon" />
                                        </Link>
                                    ) : (
                                        <Link
                                            to={navItem.href}
                                            target={navItem.target}
                                            className="footer__nav-link"
                                        >
                                            {navItem.label}
                                        </Link>
                                    )}
                                </li>
                            );
                        })}
                    </ul>
                </nav>
            ))}
        </div>

        <FooterBottom />
    </footer>
);
