import { FC, ReactElement } from 'react';

import { Cart } from '../../containers';
import {
    clearCart,
    getCart,
    removeProductVariantFromCart,
    updateProductVariantInCart,
} from '../../redux/cart/cartActions';
import { useTypedDispatch, useTypedSelector } from '../../redux/store';

export const ConnectedCart: FC = (): ReactElement => {
    const dispatch = useTypedDispatch();

    const cart = useTypedSelector(state => state.cartSlice.cart);
    const isLoading = useTypedSelector(state => state.cartSlice.isLoading);

    const handleUpdateCartItemQuantity = (id: string, quantity: number) => {
        dispatch(updateProductVariantInCart(id, quantity, cart.id));
    };

    const handleRemoveItemFromCart = (id: string) => {
        dispatch(removeProductVariantFromCart(id, cart));
    };

    const handleCartItemIsInWishlist = (productId: string) => {
        console.log('handleCartItemIsInWishlist', productId);

        // TODO: Add query to add to wishlist
    };

    const handleClearCart = () => {
        dispatch(clearCart(cart.id));

        setTimeout(() => {
            dispatch(getCart());
        }, 500);
    };

    return (
        <Cart
            {...cart}
            isLoading={isLoading}
            onRemoveItemFromCart={handleRemoveItemFromCart}
            onUpdateCartItem={handleUpdateCartItemQuantity}
            onUpdateCartItemIsInWishlist={handleCartItemIsInWishlist}
            onClearCart={handleClearCart}
        />
    );
};
