import { generateUuid } from '../../helpers/string';
import { MOCK_BRANDS } from '../../mock/mock-data';
import { InstantSearchBrand } from '../Api/Webshop/Resource/ProductHit';
import { transformToBlock } from '../Block/BlockTransformers';
import { transformToImage } from '../Media/MediaTransformers';
import { Brand, BrandItemResource } from './Brand';

export const transformBrandNameToBrand = (resource: InstantSearchBrand): Brand => ({
    id: generateUuid(),
    name: resource.name,
    slug: resource.slug,
    image: MOCK_BRANDS[0].image, // TODO: Add link to get the brand logo from CDN
    blocks: [],
});

export const transformBrandItemToBrand = (resource: BrandItemResource): Brand => {
    const image = transformToImage(resource.logo);

    const blocks = resource.elements
        ? resource.elements.map(transformToBlock)
        : [];

    const publishedBlocks = blocks.filter(block => block.isPublished);

    return {
        id: resource.documentId,
        name: resource.name,
        description: resource.description || '',
        slug: resource.slug,
        image,
        blocks: publishedBlocks,
    };
};
