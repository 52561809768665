import { transformToBlock } from '../../Block/BlockTransformers';
import { transformToDateIsoString } from '../../Date/DateTransformers';
import { transformToImage } from '../../Media/MediaTransformers';
import { transformToSeo } from '../../Seo/SeoTransformers';
import { transformToTag } from '../../Tag/TagTransformers';
import { defaultBlogCategory } from '../BlogCategory/BlogCategory';
import { transformToBlogCategory } from '../BlogCategory/BlogCategoryTransformers';
import { BlogPost, BlogPostResource } from './BlogPost';

export const transformToBlogPost = (resource: BlogPostResource): BlogPost => {
    const image = resource.image
        ? transformToImage(resource.image)
        : undefined;

    const category = resource.blogCategory
        ? transformToBlogCategory(resource.blogCategory)
        : defaultBlogCategory();

    const tags = resource.tags
        ? resource.tags.map(transformToTag)
        : [];

    const blocks = resource.elements
        ? resource.elements.map(transformToBlock)
        : [];

    const publishedBlocks = blocks.filter(block => block.isPublished);

    const seo = resource.seo
        ? transformToSeo(resource.seo)
        : undefined;

    const publishDate = resource.date
        ? transformToDateIsoString(resource.date)
        : transformToDateIsoString(resource.publishedAt);

    const modifyDate = transformToDateIsoString(resource.updatedAt);

    return {
        id: resource.documentId,
        title: resource.title,
        slug: resource.slug,
        intro: resource.intro,
        image,
        category,
        tags,
        blocks: publishedBlocks,
        seo,
        publishDate,
        modifyDate,
    };
};
