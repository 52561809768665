import {
    forwardRef,
    ReactElement,
    useEffect,
    useState,
} from 'react';

import { useHits, useSearchBox } from 'react-instantsearch';

import { InputProps } from '../../../components';
import { InstantSearchGlobalSearch } from '../../../containers';
import { ProductHitResource } from '../../../entities/Api/Webshop/Resource/ProductHit';
import { transformProductHitToSearchPopupProductItem } from '../../../entities/Product/ProductTransformers';
import { SearchPopupProductItem } from '../../../entities/SearchPopup/SearchPopup';

interface ConnectedInstantSearchGlobalSearchProps extends InputProps {
    onIsTyping: (isTyping: boolean) => void;
    onNewSearchTerm: (query: string) => void;
    onNewProducts: (products: SearchPopupProductItem[]) => void;
    onSubmit: () => void;
}

export const ConnectedInstantSearchGlobalSearch = forwardRef<HTMLInputElement, ConnectedInstantSearchGlobalSearchProps>(({
    onIsTyping,
    onNewSearchTerm,
    onNewProducts,
    onSubmit,
}, ref): ReactElement => {
    const { refine } = useSearchBox();
    const { results } = useHits();

    const [query, setQuery] = useState<string>('');

    useEffect((): void => {
        if (query.length < 1) {
            return;
        }

        onIsTyping(true);

        const value = query.trim();

        refine(value);

        const productHits = results?.hits as unknown as ProductHitResource[];
        const transformedProducts = productHits.map(transformProductHitToSearchPopupProductItem).slice(0, 5);
        onNewProducts(transformedProducts);
        onNewSearchTerm(value);
    }, [query]);

    return (
        <InstantSearchGlobalSearch
            ref={ref}
            onIsTyping={onIsTyping}
            newValue={setQuery}
            onSubmit={onSubmit}
        />
    );
});
