interface DateRange {
    start?: Date;
    end?: Date;
}

export const dateIsInRange = (range: DateRange, date = new Date()): boolean => {
    const { start, end } = range;

    if (!start && !end) {
        return true;
    }

    const isPastStart = !start || date > start;
    const isPastEnd = end ? date > end : false;

    return isPastStart && !isPastEnd;
};
