import { FC, ReactElement } from 'react';

import { Picture } from '../../../components';
import { InteractiveProductHeaderInterface } from '../../../entities/@blocks/InteractiveProductHeader/InteractiveProductHeader';
import { ProductPopupItem } from './subcomponents';

import './InteractiveProductHeader.scss';

interface InteractiveProductHeaderProps extends InteractiveProductHeaderInterface {
    className?: string;
}

// TODO: Connect with data from CMS (L5W-414)
export const InteractiveProductHeader: FC<InteractiveProductHeaderProps> = ({
    image,
    products,
    className = '',
}): ReactElement => (
    <header className={`interactive-product-header ${className}`}>
        <div className="interactive-product-header__product-items">
            {products.map(item => (
                <ProductPopupItem
                    {...item}
                    key={item.id}
                />
            ))}
        </div>

        <div className="interactive-product-header__background">
            <Picture
                {...image}
                className="interactive-product-header__picture"
            />
        </div>
    </header>
);
