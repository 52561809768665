import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Brand } from '../../entities/Brand/Brand';
import { AsyncReduxState, initialAsyncReduxState } from '../defaults';

export type BrandState = AsyncReduxState<{
    brandItem?: Brand;
}>;

const initialState: BrandState = {
    ...initialAsyncReduxState,
};

const brandSlice = createSlice({
    name: 'brandSlice',
    initialState,
    reducers: {
        setHasFetched(state, action: PayloadAction<boolean>): BrandState {
            return {
                ...state,
                hasFetched: action.payload,
            };
        },
        setIsLoading(state, action: PayloadAction<boolean>): BrandState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setIsSuccessful(state, action: PayloadAction<boolean>): BrandState {
            return {
                ...state,
                isSuccessful: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): BrandState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setBrandItem(state, action: PayloadAction<Brand | undefined>): BrandState {
            return {
                ...state,
                brandItem: action.payload,
            };
        },
    },
});

export const {
    setHasFetched,
    setIsLoading,
    setIsSuccessful,
    setError,
    setBrandItem,
} = brandSlice.actions;

export default brandSlice;
