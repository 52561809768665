import { FC, ReactElement } from 'react';

import { Wrapper } from '../../../components';
import { Button } from '../../../compositions';
import { ConnectedProductCard } from '../../../connectors';
import { Product } from '../../../entities/Product/Product';
import { useTrans } from '../../../hooks';
import { ProductCardListSkeletons } from '../../ProductCard/skeletons';

import './InstantSearchProductResultsList.scss';

interface InstantSearchProductResultsListProps {
    products: Product[];
    isLoading: boolean;
    onResetFilters: () => void;
    className?: string;
}

export const InstantSearchProductResultsList: FC<InstantSearchProductResultsListProps> = ({
    products,
    isLoading,
    onResetFilters,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    return (
        <div className={`instant-search-product-results-list ${className}`}>
            <Wrapper>
                <div className="instant-search-product-results-list__wrapper">
                    {isLoading ? (
                        <ProductCardListSkeletons
                            numberOfSkeletons={20}
                            className={className}
                        />
                    ) : (
                        products.map(product => (
                            <ConnectedProductCard
                                key={product.id}
                                product={product}
                            />
                        ))
                    )}
                </div>

                {products.length === 0 && !isLoading && (
                    <div className="instant-search-product-results-list__no-results-wrapper">
                        <p className="instant-search-product-results-list__no-results">
                            {trans('containers.instantSearchProductResultsList.noProductsFound')}
                        </p>

                        <Button
                            text={trans('containers.instantSearchProductResultsList.refreshFilters')}
                            onClick={onResetFilters}
                        />
                    </div>
                )}
            </Wrapper>
        </div>
    );
};
