import { FC, ReactElement } from 'react';

import { BlockRenderer } from '../../../containers';
import { AppRoute } from '../../../entities/Routing/Routing';
import { useClientEffect } from '../../../hooks';
import { fetchPage } from '../../../redux/pages/pagesActions';
import { useTypedDispatch, useTypedSelector } from '../../../redux/store';

interface ConnectedBlockRendererProps {
    appRoute: AppRoute;
    className?: string;
}

export const ConnectedBlockRenderer: FC<ConnectedBlockRendererProps> = ({
    appRoute,
    className = '',
}): ReactElement => {
    const dispatch = useTypedDispatch();

    const isLoading = useTypedSelector(state => state.pagesSlice.isLoading);
    const pages = useTypedSelector(state => state.pagesSlice.pages);

    useClientEffect((): void => {
        if (!pages[appRoute]) {
            dispatch(fetchPage(appRoute));
        }
    }, []);

    return (
        <BlockRenderer
            isLoading={isLoading}
            blocks={pages[appRoute]?.blocks || []}
            className={className}
        />
    );
};
