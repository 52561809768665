export type DateResource = string;
export type DateIsoString = string;

export interface DateFormatOptions extends Intl.DateTimeFormatOptions {
    dateStyle?: 'full' | 'long' | 'medium' | 'short';
    timeStyle?: 'full' | 'long' | 'medium' | 'short';
}

export const defaultDateIsoString = new Date().toISOString();

export const defaultDateFormatOptions: DateFormatOptions = {
    dateStyle: 'medium',
};

export enum DayResource {
    monday = 'monday',
    tuesday = 'tuesday',
    wednesday = 'wednesday',
    thursday = 'thursday',
    friday = 'friday',
    saturday = 'saturday',
    sunday = 'sunday',
}

export enum Day {
    monday = 1,
    tuesday = 2,
    wednesday = 3,
    thursday = 4,
    friday = 5,
    saturday = 6,
    sunday = 7,
}
