import { FC, ReactElement } from 'react';

import { TabList } from '../../compositions';
import { BlogCategoriesInterface } from '../../entities/@blog/BlogCategories/BlogCategories';
import { useTrans } from '../../hooks';
import { BlogCategoriesSkeletons } from './skeletons';

import './BlogCategories.scss';

interface BlogCategoriesProps extends BlogCategoriesInterface {
    isLoading: boolean;
    activeCategory: string;
    onCategorySelect: (value: string) => void;
    className?: string;
}

export const BlogCategories: FC<BlogCategoriesProps> = ({
    isLoading,
    categories,
    activeCategory,
    onCategorySelect,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    const allNews = {
        label: trans('entities.blogCategory.all.label'),
        value: trans('entities.blogCategory.all.value'),
    };

    return (
        <section className={`blog-categories ${className}`}>
            {isLoading && (
                <BlogCategoriesSkeletons
                    numberOfSkeletons={6}
                    className="blog-categories__skeletons"
                />
            )}

            {!isLoading && (
                <TabList
                    tabs={[allNews, ...categories]}
                    activeTab={activeCategory}
                    onTabSelect={onCategorySelect}
                    className="blog-categories__tabs"
                />
            )}
        </section>
    );
};
