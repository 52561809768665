import { IconName } from '../../components';
import { Link } from '../Link/Link';

export interface Navigation {
    id: string;
    parent?: Link;
    groups: NavigationGroup[];
}

export interface NavigationGroup {
    id: string;
    items: NavigationItem[];
}

export interface NavigationItem extends Link {
    id: string;
    order: number;
    icon?: IconName;
    subNavigation?: Navigation;
}

export enum NavigationTransitionState {
    idle = 'idle',
    goingBackwards = 'going-backwards',
    goingForwards = 'going-forwards',
}
