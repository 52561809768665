import {
    CSSProperties,
    FC,
    ReactElement,
    useEffect,
    useRef,
    useState,
} from 'react';

import classNames from 'classnames';
import { useWindowSize } from 'react-use';

import { useLocale } from '../../../context';
import { Direction } from '../../../entities/Locale/Locale';
import { getClosestNumber } from '../../../helpers/number';
import { MinMaxValue } from '../../../types';
import { InputLabel } from '../InputLabel/InputLabel';
import { RangeInputBar, RangeInputHandle, RangeInputMarks } from './subcomponents';

import './RangeInput.scss';

interface RangeInputProps {
    id: string;
    label: string;
    additionalLabel?: string;
    hideLabel?: boolean;
    minHandleLabel: string;
    maxHandleLabel: string;
    initialValue: MinMaxValue;
    min?: number;
    max?: number;
    step?: number;
    tabIndex?: number;
    disabled?: boolean;
    marks?: number;
    hiddenMarginPercentage?: number;
    valueFormat?: (value: number) => string;
    onChange: (rangeValue: MinMaxValue) => void;
    className?: string;
}

export const RangeInput: FC<RangeInputProps> = ({
    id,
    label,
    additionalLabel,
    hideLabel = false,
    minHandleLabel,
    maxHandleLabel,
    initialValue,
    min = 0,
    max = 100,
    step = 1,
    tabIndex = 0,
    disabled,
    marks,
    hiddenMarginPercentage = 10,
    valueFormat,
    onChange,
    className = '',
}): ReactElement => {
    const { direction } = useLocale();
    const { width } = useWindowSize();
    const [barWidth, setBarWidth] = useState<number>(0);

    const minMaxDiff = max - min;
    const midPoint = min + (minMaxDiff / 2);

    const barRef = useRef<HTMLDivElement>(null);

    useEffect((): void => {
        if (barRef.current) {
            setBarWidth(barRef.current.offsetWidth);
        }
    }, [barRef, width]);

    const [value, setValue] = useState<MinMaxValue>(initialValue);

    useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    const handleMinChange = (minValue: number): void => {
        setValue({
            min: minValue,
            max: initialValue.max,
        });
    };

    const handleMaxChange = (maxValue: number): void => {
        setValue({
            min: initialValue.min,
            max: maxValue,
        });
    };

    const handleBarClick = (clickedValue: number): void => {
        const closestValue = getClosestNumber(clickedValue, Object.values(initialValue));

        setValue({
            min: closestValue === initialValue.min ? clickedValue : initialValue.min,
            max: closestValue === initialValue.max ? clickedValue : initialValue.max,
        });
    };

    const inputWrapperClassNames = classNames('range-input__input-wrapper', {
        'range-input__input-wrapper--is-disabled': !!disabled,
    });

    const cssVariables = {
        '--range-input-width': `${barWidth}px`,
        '--range-input-min': (value.min - min) / minMaxDiff,
        '--range-input-max': (value.max - min) / minMaxDiff,
        '--range-input-track-size': (value.max - value.min) / minMaxDiff,
    } as CSSProperties;

    const rangeInputProps = {
        isRtl: direction === Direction.rtl,
        min,
        max,
        step,
        disabled,
    };

    const rangeInputHandleProps = {
        ...rangeInputProps,
        parentRef: barRef,
        hiddenMarginPercentage,
        valueFormat,
        rangeSize: initialValue.max - initialValue.min,
        tabIndex: disabled ? -1 : tabIndex,
    };

    const handleDragEnd = (dragEnds: boolean): void => {
        if (dragEnds) {
            onChange(value);
        }
    };

    return (
        <label
            id={id}
            aria-label={label}
            style={cssVariables}
            className={`range-input ${className}`}
        >
            {!hideLabel && (
                <InputLabel
                    label={label}
                    tooltip={additionalLabel}
                    className="range-input__label"
                />
            )}

            <div ref={barRef} className={inputWrapperClassNames}>
                <RangeInputHandle
                    {...rangeInputHandleProps}
                    crossedMidPoint={value.min > midPoint}
                    label={minHandleLabel}
                    value={value.min}
                    clampMin={min}
                    clampMax={value.max}
                    onChange={handleMinChange}
                    onDragEnd={handleDragEnd}
                    className="range-input__handle--min"
                />

                <RangeInputHandle
                    {...rangeInputHandleProps}
                    crossedMidPoint={value.max <= midPoint}
                    label={maxHandleLabel}
                    value={value.max}
                    clampMin={value.min}
                    clampMax={max}
                    onChange={handleMaxChange}
                    onDragEnd={handleDragEnd}
                    className="range-input__handle--max"
                />

                <RangeInputBar
                    {...rangeInputProps}
                    labelledby={id}
                    value={initialValue}
                    onClick={handleBarClick}
                    className="range-input__bar"
                />
            </div>

            {marks && (
                <RangeInputMarks
                    amount={marks}
                    min={min}
                    max={max}
                    className="range-input__marks"
                />
            )}
        </label>
    );
};
