import { FC, ReactElement } from 'react';

import { useParams } from 'react-router-dom';

import { BlogPostRenderer } from '../../../containers';
import { useClientEffect } from '../../../hooks';
import { NotFoundPage } from '../../../pages';
import { fetchBlogPost } from '../../../redux/@blog/blogPost/blogPostActions';
import { useTypedDispatch, useTypedSelector } from '../../../redux/store';

interface ConnectedBlogPostRendererProps {
    className?: string;
}

export const ConnectedBlogPostRenderer: FC<ConnectedBlogPostRendererProps> = ({
    className = '',
}): ReactElement => {
    const { slug = '' } = useParams();

    const dispatch = useTypedDispatch();

    const hasFetched = useTypedSelector(state => state.blogPostSlice.hasFetched);
    const isLoading = useTypedSelector(state => state.blogPostSlice.isLoading);
    const blogPost = useTypedSelector(state => state.blogPostSlice.blogPost);

    useClientEffect((): void => {
        dispatch(fetchBlogPost(slug));
    }, [slug]);

    if (hasFetched && !blogPost) {
        return (
            <NotFoundPage />
        );
    }

    return (
        <BlogPostRenderer
            isLoading={isLoading}
            blogPost={blogPost}
            className={className}
        />
    );
};
