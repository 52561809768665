import { PageHitResource } from '../Api/Webshop/Resource/PageHit';
import { Link } from '../Link/Link';
import { transformInstantSearchPageLinkToLink } from '../Link/LinkTransformers';
import { PageResult } from './PageResult';

export const transformPageHitToPageResult = (resource: PageHitResource): PageResult => ({
    id: resource.id,
    title: resource.title,
    link: transformInstantSearchPageLinkToLink(resource),
    type: resource.type, // TODO: Create the right type for this (faqs, blogs, content pages)
    slug: resource.slug, // TODO: Create the right slug for the page (based on the type of page)
    shortDescription: resource.short_description ?? undefined,
});

export const transformPageHitToSearchPopupPageItem = (resource: PageHitResource): Link => transformInstantSearchPageLinkToLink(resource);
