import { FC, ReactElement } from 'react';

import { DepartmentOverview } from '../../containers';
import { useClientEffect } from '../../hooks';
import { fetchDepartmentOverview } from '../../redux/@departments/departmentOverview/departmentOverviewActions';
import { useTypedDispatch, useTypedSelector } from '../../redux/store';

interface ConnectedDepartmentOverviewProps {
    className?: string;
}

export const ConnectedDepartmentOverview: FC<ConnectedDepartmentOverviewProps> = ({
    className = '',
}): ReactElement => {
    const dispatch = useTypedDispatch();

    const isLoading = useTypedSelector(state => state.departmentOverviewSlice.isLoading);
    const departments = useTypedSelector(state => state.departmentOverviewSlice.departments);
    const pagination = useTypedSelector(state => state.departmentOverviewSlice.pagination);

    useClientEffect((): void => {
        if (departments.length === 0) {
            dispatch(fetchDepartmentOverview({
                pagination: {
                    page: 1,
                    pageSize: 12,
                },
            }));
        }
    }, []);

    return (
        <DepartmentOverview
            isLoading={isLoading}
            departments={departments}
            pagination={pagination}
            className={className}
        />
    );
};
